import React, { useState } from 'react'

// Components
import Button from '../../Elements/Button'


function UploadAttachmentsForm(props) {
    const [filesLoaded, setFilesLoaded] = useState([])
    if(props.data.docs_attached && filesLoaded.length === 0){
        setFilesLoaded(props.data.docs_attached)
    }

    function handleFileLoader() {
        var element = document.getElementById("file-loader-creation")
        element.click()
    }
    
    function handleSubmit(e) {
        e.preventDefault()
        
        var form_element = document.getElementById('uploaded-docs-form')
        var form_data = new FormData(form_element)
        let files = []
        form_data.forEach((value, key) => {
            if(value) {
                files.push(value)
            }
        })
        setFilesLoaded([...filesLoaded, ...files])
    }

    function handleClickNext(){
        if(filesLoaded.length > 0){
            props.onUpdate({docs_attached:filesLoaded})
        }
        props.onNext()
    }

    function handleClickPrevious(){
        if(filesLoaded.length > 0){
            props.onUpdate({docs_attached:filesLoaded})
        }
        props.onPrevious()
    }
    
    function handleDelete(e) {
        let toDelete = e.currentTarget.getAttribute('name')
        let filtered = filesLoaded.filter(files => files.name !== toDelete)
        setFilesLoaded(filtered)
    }

    var docs = []
    if(filesLoaded) { 
        for(const doc of filesLoaded) {
            docs.push(
                <div className="project-links flexer flexer-spaced flexer-vcenter">
                    <div className="flexer flexer-vcenter left">
                        <p className="far fa-file is-light line-margin-0"></p>
                        <div className="mx-15">
                            <a href={doc.doc} className="text is-dark line-margin-0 cursor breakable" target="_blank" rel="noopener noreferrer">{doc.name}</a>
                            <p className="text is-light line-margin-0">{parseInt(doc.size/1000)} KB</p>
                        </div>
                    </div>
                    <i className="fa fa-trash-o is-light cursor" name={doc.name} onClick={handleDelete}></i>
                </div>
            )
        }
    }
    
    return (
        <div className="half-width center">
            <form id="uploaded-docs-form" className="left mt-60">
                <input id="file-loader-creation" name="docs_attached" type="file" className="file-input" multiple onChange={handleSubmit}/>
                <div className="right my-30 ">
                    <Button text={'Attach Files'} class={"black-btn mx-10 "} icon={"fas fa-plus"} onClick={handleFileLoader} type="button" />
                </div>
                {filesLoaded.length > 0 ? <p className="subtitle is-dark is-bold">Attached Docs:</p> : <p className="subtitle is-light my-30">You have no Attached Docs</p>  }
            </form>
            {docs.length > 0 && docs}
            <div className="flexer flexer-spaced my-30 center-mobile">
                <Button text="&larr; Previous step" class="mt-15 white-btn is-orange subheading is-light" type="button" onClick={handleClickPrevious}/>
                <Button text="Next step &rarr;" class="mt-15 orange-btn subheading is-light" onClick={handleClickNext}/>
            </div>
        </div>
    )
}

export default UploadAttachmentsForm