import React, { useEffect, useState, useContext } from 'react'

// API
import { invitationService } from '../../api'

// Components
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'
import InvitationForm from './InvitationForm'
import GetOrgId from '../Elements/GetOrgId'

// Hooks
import { UserContext } from '../../hooks/UserContext'

function PendingInvitation(props) {
  const [invitationList, setInvitationList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [toDelete, setToDelete] = useState({})
  const [displayedInvitations, setDisplayedInvitations] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)

  const { currentOrganizations } = useContext(UserContext)  // global context

  const orgId = GetOrgId()
  const orgMemberInfo = currentOrganizations
  const currentRole = orgMemberInfo[orgId].role

  useEffect(() => {
    invitationService.listByOrg(orgId)
      .then(response => {
        let invit = {}
        response.results.map(inv => invit[inv.email] = inv)
        setInvitationList(invit)
        setDisplayedInvitations(invit)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch((error) => {
        console.error("Unable to get invitation list", error)
      })
  }, [orgId, refresh])

  function handlePagination(url) {
    invitationService.listByUrl(url)
      .then((response) => {
        setInvitationList(response['results'])
        setDisplayedInvitations(response.results)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch(error => {
        console.log("Error loading users", error)
      })
  }

  function handleClick(e) {
    setIsOpen(true)
    setToDelete({ email: e.currentTarget.getAttribute('email'), id: e.currentTarget.getAttribute('id') })
  }

  function openAddModal() {
    setIsAddOpen(true)
  }

  function closeModal() {
    setIsAddOpen(false)
    setIsOpen(false)
    setRefresh(refresh + 1)
  }

  function handleSearch(e) {
    if (e.target.value.length > 0) {
      invitationService.search(e.target.value, orgId)
        .then(response => {
          let invit = {}
          response.map(inv => invit[inv.email] = inv)
          setDisplayedInvitations(invit)
          console.log("Fomr search", invit)
        })
        .catch(error => {
          console.error(error)
          setDisplayedInvitations(invitationList)
        })
    } else {
      setDisplayedInvitations(invitationList)
    }
  }

  function handleDelete() {
    invitationService.remove(orgId, toDelete.id)
      .then(response => {
        let users = invitationList
        delete users[toDelete.id]
        setInvitationList(users)
      })
      .then(() => {
        setIsOpen(false)
        setRefresh(refresh + 1)
      })
  }


  let invitations
  invitations = Object.values(displayedInvitations)
    .map(inv => (
      <tr className="table-rows flexer">
        <td className="text is-light full-width left side-margin-0">{inv.email}</td>
        <td className="text is-light full-width left side-margin-0">{inv.role === "ADMIN" ? 'Admin' : inv.role === "CASE_MANAGER" ? 'Project Manager' : 'Analyst'}</td>
        {(currentRole === "ADMIN" || (currentRole === "CASE_MANAGER" && inv.role !== "ADMIN")) ? <td className="text flexer-1 right side-margin-0 cursor"><i className="fa fa-trash mr-10 is-light" email={inv.email} id={inv.id} onClick={handleClick}></i></td> : null}
      </tr>))

  return (
    <div className="dashboard-section">
      <div className="col-9 relative left side-margin-0">
        <SearchBar placeholder={"Find an invitation"} onChange={(e) => handleSearch(e)} />
      </div>
      <div className="flexer flexer-spaced flexer-mobile">
        <h1 className="title is-dark">{''}</h1>
        {(currentRole === "CASE_MANAGER" || currentRole === "ADMIN") ? <Button text={'Add Invitation'} class={"black-btn"} icon={"fas fa-plus"} onClick={openAddModal} /> : null}
      </div>
      {props.children}
      <Modal isOpen={isAddOpen} handleClose={() => { setIsAddOpen(false) }}>
        <InvitationForm onClose={closeModal} />
      </Modal>
      <table className="table" cellSpacing="0">
        <thead>
          <tr className="table-header flexer mt-15">
            <th className="text is-light full-width left side-margin-0">Email</th>
            <th className="text is-light full-width left side-margin-0">Role</th>
            {(currentRole === "CASE_MANAGER" || currentRole === "ADMIN") ? <th className="text is-light flexer-1 left side-margin-0"></th> : null}
          </tr>
        </thead>
        <tbody>
          {invitations}
        </tbody>
      </table>
      {Object.values(invitationList).length === Object.values(displayedInvitations).length && (<div className="flexer right">
        {prev ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(prev)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
        <p className="subheading is-light mx-10"></p>
        {next ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(next)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
      </div>)}
      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Delete Invitation</h1>
          <p className="text is-light mx-30">Are you sure that you want to delete the invitation to <br></br> <span className="is-dark is-bold">{toDelete.email}</span>?</p>
          <Button text="Delete" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleDelete} />
        </div>
      </Modal>
    </div>
  )
}

export default PendingInvitation
