import React from 'react'

function Button(props) {
    if (props.icon) {
        var icon = <i className={`${props.icon} ${props.class} mx-5`} />
    }
    var className = props.class ? props.class : ''
    
    if (props.type === "submit") {
        return (
            <input type="submit" form={props.form} className={`button ${className}`} onClick={props.onClick} value={props.text} disabled={props.disabled}/>
        )
    }
    else{
        return (            
            <button type={props.type} className={`button ${className}`} onClick={props.onClick} disabled={props.disabled}>{icon} {props.text}</button>
        )
    }
}

export default Button