import React, {useState} from 'react'

// Components
import MenuTabItem from '../../components/Header/MenuTabItem'
import InvitationsPending from '../../components/UserOrganizations/InvitationsPending'
import Organizations from '../../components/UserOrganizations/Organizations'

function UserOrganizationsContent () {

    const [section, setSection] = useState("organizations")

	let userSection
    section === 'organizations'
        ? 
        (userSection = <React.Fragment><Organizations /></React.Fragment>)
        :
        (userSection = <React.Fragment><InvitationsPending /></React.Fragment>)

        return (
		<React.Fragment>
			<div className="flexer flexer-mobile">
                <div className="flexer-11 center">
                    <div className="advanced-profile flexer center-mobile">
                        <MenuTabItem text="Organizations" active={section === "organizations"} onClick={()=>{setSection("organizations")}}/>
                        <MenuTabItem text="Pending Invitations"  active={section === "invitationsPending"} onClick={()=>{setSection("invitationsPending")}}/>
                    </div>
                    {userSection}
                </div>
            </div>
		</React.Fragment>
	)
}

export default UserOrganizationsContent
