import React, {useState} from 'react'
import { Link } from 'react-router-dom'

// Components
import ImageContent from 'components/Elements/ImageContent'
import Modal from '../Elements/Modal'
import AddLinkForm from './Forms/AddLinkForm'
import EditLinkForm from './Forms/EditLinkForm'
import ConfidenceIcon from 'components/Elements/ConfidenceIcon'

function LinksSection(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [modalForm, setModalForm] = useState(null)

    let profilePic = 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

    var links = []

    if(props.project.subject) {
        for(const link of props.project.subject.links){

            let first_name
            let middle_name
            let last_name

            first_name = {
                content: link.linked_subject.first_name ? link.linked_subject.first_name.content : null,
                confidence: link.linked_subject.first_name  ? link.linked_subject.first_name.confidence : null,
                id: link.linked_subject.first_name  ? link.linked_subject.first_name.id : null
            }

            middle_name = {
                content: link.linked_subject.middle_name ? link.linked_subject.middle_name.content : null,
                confidence: link.linked_subject.middle_name ? link.linked_subject.middle_name.confidence : null,
                id: link.linked_subject.middle_name ? link.linked_subject.middle_name.id : null
            }

            last_name = {
                content: link.linked_subject.last_name ? link.linked_subject.last_name.content : null,
                confidence: link.linked_subject.last_name ? link.linked_subject.last_name.confidence : null,
                id: link.linked_subject.last_name ? link.linked_subject.last_name.id : null
            }

            profilePic = {
                content: link.linked_subject.profile_pic.content? link.linked_subject.profile_pic.content : profilePic,
                confidence: link.linked_subject.profile_pic ? link.linked_subject.profile_pic .confidence : null,
                id: link.linked_subject.profile_pic  ? link.linked_subject.profile_pic.id : null
            }

            var project_id = link.linked_subject.cases[0]

            links.push(
                <div className="project-links flexer flexer-spaced flexer-vcenter" key={link.linked_subject.id}>
                    <div className="flexer left">
                        <div className='flexer flexer-vcenter center wcc-hasconfidence'>
                        <Link to={`/organization/${props.orgId}/project/${project_id}`} >
                            <ImageContent className="profile-menu__image" src={profilePic.content} alt="profile"  />
                        </Link>
                            { profilePic.content && (
                                <ConfidenceIcon confidence={profilePic.confidence}  orgId={props.orgId} dataId={profilePic.id} onClose={props.onClose}  />
                            )}
                        </div>
                        <div className="mx-15">
                                <div className='flexer'>
                                    { first_name?.content && (
                                        <div className='flexer flexer-vcenter wcc-hasconfidence mr-5'>
                                            <p className="subtitle is-dark line-margin-s">{first_name.content}</p>
                                            <ConfidenceIcon confidence={first_name.confidence}  orgId={props.orgId} dataId={first_name.id} onClose={props.onClose} />
                                        </div>
                                    )}
                                    { middle_name?.content && (
                                        <div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
                                            <p className="subtitle is-dark line-margin-s">{middle_name.content}</p>
                                            <ConfidenceIcon confidence={middle_name.confidence}  orgId={props.orgId} dataId={middle_name.id} onClose={props.onClose} />
                                        </div>
                                    )}
                                    { last_name?.content && (
                                        <div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
                                            <p className="subtitle is-dark line-margin-s">{last_name.content}</p>
                                            <ConfidenceIcon confidence={last_name.confidence}  orgId={props.orgId} dataId={last_name.id} onClose={props.onClose} />
                                        </div>
                                    )}
                                </div>
                            <p className="subheading is-dark line-margin-0">{link.proper_relationship}</p>
                        </div>
                    </div>
                    <i className="fa fa-pencil is-light cursor" onClick={() => {handleEditLink(link.id)}}></i>
                </div>
            )
        }
    }

    var mainSubject = props.project.subject ? props.project.subject.id : ''

    function handleEditLink(linkId) {
        setIsOpen(true)
        setModalForm(<EditLinkForm onComplete={props.onClose} onClose={() => {setIsOpen(false)}} linkId={linkId}/>)
    }

    function handleNewLink() {
        setIsOpen(true)
        setModalForm(<AddLinkForm onComplete={props.onClose} onClose={() => {setIsOpen(false)}} mainSubject={mainSubject}/>)
    }

    return (
        <React.Fragment>
            <div className="simple-profile__sections center">
                <div className="flexer flexer-spaced flexer-vcenter">
                        <p className="subtitle is-dark is-bold">Project Links:</p>
                    {props.closed ?
                        null :
                            <p className="cursor link" onClick={handleNewLink}>+ Add Link</p>
                            }
                </div>
                {links}                
            </div>
            <Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
                {modalForm}
            </Modal>
        </React.Fragment>
    )
}

export default LinksSection
