import React from 'react'

function SearchBar(props) {
    if (props.noBg) {
        return (
            <div className="input-wrapper">
                <input className="search-input-2 text is-dark" type="text" placeholder={props.placeholder} onChange={props.onChange}/>
            </div>
        )
    }

    if (props.whiteBg) {
        return (
            <div className="input-wrapper">
                <i className="fa fa-search icon input-wrapper__icon-left search-icon"></i>
                <input className="search-input-3 subheading is-light" type="text" placeholder={props.placeholder} onChange={props.onChange}/>
            </div>
        )
    }

    return (
        <div className="input-wrapper">
            <i className="fa fa-search icon input-wrapper__icon-left search-icon"></i>
            <input className="search-input subheading is-light" type="text" placeholder={props.placeholder} onChange={props.onChange}/>
        </div>
    )
}

export default SearchBar