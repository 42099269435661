import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//API
import { projectService } from '../../api'

//Components
import ProfileSection from '../../components/Project/ProfileSection'
import LinksSection from '../../components/Project/LinksSection'
import AdditionalInfoSection from '../../components/Project/AdditionalInfoSection'
import AttachedDocsSection from '../../components/Project/AttachedDocsSection'
import ProjectInfoSection from '../../components/Project/ProjectInfoSection'
import MenuTabItem from '../../components/Header/MenuTabItem'
import Button from '../../components/Elements/Button'
import AccountCard from '../../components/Project/AccountCard'
import Modal from '../../components/Elements/Modal'
import AddAccountForm from '../../components/Project/Forms/AddAccountForm'
import RecentActivitySection from '../../components/Project/RecentActivitySection'
import AssociatedMessagesSection from '../../components/Project/AssociatedMessagesSection'
import GetOrgId from '../../components/Elements/GetOrgId'

function ProjectContent(props) {
  let { project_id } = useParams()

  const [Project, setProject] = useState({})
  const [subjectId, setSubjectId] = useState(null)
  const [accountsList, setAccounts] = useState([])
  const [isOpenAddAccount, setOpenAddAccount] = useState(false)
  const [isOpenUpdateAccount, setOpenUpdateAccount] = useState(false)
  // const [isOpenAddActivity, setOpenAddActivity] = useState(false)
  const [isOpenUnpublished, setOpenUnpublished] = useState(false)
  const [modalAccount, setModalAccount] = useState()
  const [refresh, setRefresh] = useState(0)
  const [social, setSocial] = useState(true)
  const [activity, setActivity] = useState(false)
  const [messages, setMessages] = useState(false)
  const [closed, setClosed] = useState(false)

  const orgId = GetOrgId()

  useEffect(() => {
    if (props.first) {
      setOpenUnpublished(true)
    }
  }, [props.first])

  useEffect(() => {
    projectService.retrieve(project_id, orgId)
      .then(response => {
        setProject(response)
        setAccounts(response.subject.accounts)
        setSubjectId(response.subject.id)
      })
      .catch(error => {
        console.log("Error loading project", error)
      })
  }, [orgId, project_id, refresh])

  function addDoc(doc) {
    var project_ = Project
    project_.docs.push(doc)
    setProject({ ...project_ })
  }

  function closeAndRefresh() {
    setOpenUpdateAccount(false)
    setOpenAddAccount(false)
    setOpenUnpublished(false)
    setRefresh(refresh + 1)
    // setOpenAddActivity(false)
  }

  function openAccountModal(account = null) {
    setModalAccount(<AddAccountForm account={account} onClose={closeAndRefresh} />)
    setOpenUpdateAccount(true)
  }

  var accounts = []
  for (const account of accountsList) {
    accounts.push(<AccountCard account={account} onEdit={openAccountModal} onDelete={closeAndRefresh} key={account.id} closed={closed} onClose={closeAndRefresh} />)
  }

  var content
  if (social) {
    content = accounts
  } else if (activity) {
    content = <RecentActivitySection project={Project} />
  } else {
    content = <AssociatedMessagesSection project={Project} />
  }

  function handleAssociatedMessages() {
    setActivity(false)
    setSocial(false)
    setMessages(true)
  }

  function handleRecentActivity() {
    setActivity(true)
    setSocial(false)
    setMessages(false)
  }

  function handleSocialAccounts() {
    setActivity(false)
    setSocial(true)
    setMessages(false)
  }

  if (!closed && Project.proper_status === "Closed") {
    setClosed(true)
  }

  return (
    <React.Fragment>
      <div className="flexer flexer-spaced max-screen__bottom flexer-mobile">
        <div className="flexer-3 project-sides">
          <ProfileSection project={Project} onClose={closeAndRefresh} closed={closed} orgId={orgId} />
          <LinksSection project={Project} onClose={closeAndRefresh} closed={closed} orgId={orgId} />
        </div>
        <div className="flexer-6">
          <ProjectInfoSection project={Project} closed={closed} onClose={closeAndRefresh} onChange={setOpenUnpublished} />
          <div className="advanced-profile flexer flexer-spaced">
            <div className="flexer center-mobile">
              <MenuTabItem text="Social accounts" onClick={handleSocialAccounts} active={social} project={true} />
              <MenuTabItem text="Recent activity" onClick={handleRecentActivity} active={activity} project={true} />
              <MenuTabItem text="Associated messages" onClick={handleAssociatedMessages} active={messages} project={true} />
            </div>
            {social && !closed ?
              <div>
                <Button type="button" class="black-btn mx-30" text="+ Add Account" onClick={() => { setOpenAddAccount(true) }} />
              </div>
              : ""}
          </div>
          {content}
        </div>
        <div className="flexer-3 project-sides">
          <AdditionalInfoSection project={Project} onClose={closeAndRefresh} closed={closed} />
          <AttachedDocsSection project={Project} onNew={addDoc} closed={closed} />
        </div>
      </div>
      <Modal isOpen={isOpenAddAccount} handleClose={() => { setOpenAddAccount(false) }}>
        <AddAccountForm subject={subjectId} account={{}} onClose={closeAndRefresh} />
      </Modal>
      <Modal isOpen={isOpenUpdateAccount} handleClose={() => { setOpenUpdateAccount(false) }}>
        {modalAccount}
      </Modal>
      <Modal isOpen={isOpenUnpublished} handleClose={() => { setOpenUnpublished(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Project Created</h1>
          <p className="text is-dark mx-30">The project with ID <strong className="is-dark">{Project.case_id}</strong> has been created successfully and its current status is "Unpublished".</p>
          <p className="text is-dark mx-30">Please review the information and change its status to "Available" to make it visible to the analysts.</p>
          <Button text="OK" class="mt-30 one-third-width orange-btn subheading is-light" type="button" onClick={closeAndRefresh} />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ProjectContent