import React, { useState, useEffect } from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'
import GetOrgId from '../Elements/GetOrgId'
import Modal from '../Elements/Modal'
import Tooltip from '../Elements/Tooltip'

// API
import { projectTypesService, organizationService } from '../../api'

function EditOrganizationProfileForm(props) {
  const [errors, setErrors] = useState({})
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [projectTypes, setProjectTypes] = useState(null)
  const [toDelete, setToDelete] = useState({})
  const [refresh, setRefresh] = useState(1)
  const orgId = GetOrgId()


  useEffect(() => {
    projectTypesService.list(orgId)
      .then((response) => {
        setProjectTypes(response)
      })
      .catch((error) => {
        console.error("Unable to get project types", error)
      })
  }, [orgId, refresh])

  function handleSubmit(e) {
    e.preventDefault()

    var form_types_element = document.getElementById('types-form')
    var form_types_data = new FormData(form_types_element)

    form_types_data.forEach((value, key) => {
      if (!value) {
        form_types_data.delete(key)
      }
    })

    projectTypesService.create(orgId, form_types_data)
      .then((response) => {
        props.onClose(true)
      })
      .catch(error => {
        console.log("Error creating new type", error)
        if (Array.isArray(error)) {
          error = { non_field_errors: error[0] }
        }
        setErrors({ ...error })
      })

    var form_element = document.getElementById('form')
    var form_data = new FormData(form_element)

    form_data.forEach((value, key) => {
      if (!value) {
        form_data.delete(key)
      }
    })

    organizationService.update(form_data, orgId)
      .then((response) => {
        props.onClose(true)
      })
      .catch(error => {
        console.log("Error updating profile", error)
        if (Array.isArray(error)) {
          error = { non_field_errors: error[0] }
        }
        setErrors({ ...error })
      })
  }

  function handleFileLoader() {
    var element = document.getElementById("file-loader")
    element.click()
  }

  let project_types = projectTypes && projectTypes.map(type =>
    <tr className='table-rows flexer'>
      <td className="text is-light flexer-10 left side-margin-0">{type.name}</td>
      <td className="text is-light flexer-2 left side-margin-0">{type.code}</td>
      {props.allow_edit ? <td className="text flexer-1 left side-margin-0 cursor"><i className="fa fa-trash mr-10 is-light" name={type.name} code={type.code} typeId={type.id} onClick={handleClick}></i></td> : <td className="text flexer-1 right side-margin-0"><i className="fa fa-trash mr-10 is-light hidden"></i></td>}
    </tr>
  )

  function handleClick(e) {
    setIsOpenDelete(true)
    setToDelete({ name: e.currentTarget.getAttribute('name'), code: e.currentTarget.getAttribute('code'), id: e.currentTarget.getAttribute('typeId') })
  }

  function handleDelete() {
    projectTypesService.remove(orgId, toDelete.id)
      .then(() => {
        setIsOpenDelete(false)
        setRefresh(refresh + 1)
      })
  }

  return (
    <React.Fragment>
      <form id="form" onSubmit={handleSubmit}>
        <h1 className="title is-dark mb-30">Edit Profile</h1>
        <div className="flexer flexer-vcenter">
          <img className="edit-org-profile__picture" src={props.profilePic} alt="profile" />
          <input id="file-loader" name="profile_pic" type="file" className="file-input" />
          <Button type="button" text="Upload photo" class="mx-15 orange-btn subheading is-white" onClick={handleFileLoader} />
        </div>
        <InputField name="name" label="Name" type="text" placeholder={props.org.name ? props.org.name : ''} error={errors.description} />
        <InputField name="description" label="Description" placeholder={props.org.description ? props.org.description : ''} type="text" error={errors.description} />
      </form>
      <div className="center">
        <div className='mt-30'>
          <div className='left'>
            <label className="input-field__label  left ">Project Types</label>
          </div>
          <table className="table" cellSpacing="0">
            <tbody>
              {project_types}
            </tbody>
          </table>
        </div>
      </div>
      <form id='types-form'>
        <div className="flexer flexer-spaced">
          <Tooltip class='flexer-5' content='For example: Research'>
            <InputField class="line-margin-xs" name="name" placeholder="New project type name" />
          </Tooltip>
          <Tooltip class='flexer-5' content='Code type must contain three capital letters: RES'>
            <InputField class="line-margin-xs" placeholder="New project type code" name="code" />
          </Tooltip>
        </div>
      </form>
      <div className="right center-mobile">
        <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose} />
        <Button text="Save changes" class="orange-btn mt-30 subheading is-white" onClick={handleSubmit} />
      </div>
      <div>
        <Modal isOpen={isOpenDelete} handleClose={() => { setIsOpenDelete(false) }}>
          <div className="center">
            <h1 className="title is-dark mb-30">Delete Project Type</h1>
            <p className="text is-light mx-30">Are you sure that you want to delete the type <span className="is-dark is-bold">{toDelete.name} - {toDelete.code}</span>?</p>
            <Button text="Delete" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleDelete} />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default EditOrganizationProfileForm
