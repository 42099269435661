import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import ProfileContent from '../containers/Content/ProfileContent'

// Components
// import GetOrgId from '../components/Elements/GetOrgId'

// API
// import {authenticationService} from '../api'

function Profile() {
    // const basicInfo = authenticationService.basicInfoValue

    // const orgId = GetOrgId()
    // const orgMemberInfo = authenticationService.organizationsMemberValue
    // const currentRole = orgMemberInfo[orgId].role
    // var warning
    // if (currentRole === "UNASSIGNED") {
    //     warning = <div className="warning-message center">
    //                 <p className="text is-light line-margin-0">Your account status is unverified. Please complete your profile and request verification to access all the features of the application.</p>
    //             </div>
    // }

    return (
        <React.Fragment>
            <Header />
            {/* {warning} */}
            <Content>
                <ProfileContent />
            </Content>
        </React.Fragment>
    )
}

export default Profile