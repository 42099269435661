import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// Components
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'
import AddOrganizationForm from './AddOrganizationForm'
import CreateOrganizationForm from './CreateOrganizationForm'
import CreateSuperadmin from './CreateSuperadmin'

// Assets
import OrganizationLogo from '../../assets/images/globe_international_icon.png'

// Hooks
import { UserContext } from '../../hooks/UserContext'

function Organizations(props) {
  const { currentUser, currentOrganizations } = useContext(UserContext) // global context

  const [orgList, setOrgList] = useState([])
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isSuperadminOpen, setIsSuperadminOpen] = useState(false)
  const [userRole, setUserRole] = useState("")
  const profilePic = OrganizationLogo

  // const currentUser =  authenticationService.currentUserValue.user_id

  useEffect(() => {
    setOrgList(currentOrganizations)
    // organizationService.listByUser()
    // .then(response => {
    // 	setOrgList(response)
    // })
    // .catch((error) => {
    //     console.error("Unable to get organizations list", error)
    // })
    // profileService.retrieve(currentUser.cognito_id)
    // .then( response =>
    // 	setUserRole(response.role))}
    setUserRole(currentUser.role)
  }, [currentOrganizations, currentUser.role])


  function openAddModal() {
    setIsAddOpen(true)
  }

  function openCreateModal() {
    setIsCreateOpen(true)
  }

  function openSuperadminModal() {
    setIsSuperadminOpen(true)
  }

  function closeModal(refresh = false) {
    setIsAddOpen(false)
    setIsCreateOpen(false)
  }

  let organizations
  organizations = Object.values(orgList).map(org =>
    (<tr className="table-rows flexer full-width" key={org.id}>
      <td className="text is-light flexer-1 left side-margin-0">
        <img className="org-icon__picture" src={org.profile_pic ? org.profile_pic : profilePic} alt="Logo" />
      </td>

      <td className="text is-light flexer-4 left side-margin-0 mt-5"><Link to={`/organization/${org.id}`} className="text is-dark">{org.name}</Link></td>
      <td className="text is-light remaining-width left side-margin-0 mt-5">{org.description}</td>
    </tr>))

  return (
    <div className="dashboard-user-section">
      <div className="right my-20 ">
        <Button text={'Request Access to Organization'} class={"black-btn mx-10 "} icon={"fas fa-plus"} onClick={openAddModal} />
        {userRole === 'SUPER_ADMIN' && <Button text={'Create Organization'} class={"black-btn mx-10"} icon={"fas fa-plus"} onClick={openCreateModal} />}
        {userRole === 'SUPER_ADMIN' && <Button text={'Create Superadmin'} class={"black-btn mx-10"} icon={"fas fa-plus"} onClick={openSuperadminModal} />}
      </div>
      <Modal isOpen={isAddOpen} handleClose={() => { setIsAddOpen(false) }}>
        <AddOrganizationForm onClose={closeModal} />
      </Modal>
      <Modal isOpen={isCreateOpen} handleClose={() => { setIsCreateOpen(false) }}>
        <CreateOrganizationForm onClose={closeModal} />
      </Modal>
      <Modal isOpen={isSuperadminOpen} handleClose={() => { setIsSuperadminOpen(false) }}>
        <CreateSuperadmin onClose={closeModal} />
      </Modal>
      <table className="table " cellSpacing="0">
        <thead>
          <tr className="table-header  flexer full-width mt-20">
            <th className="text is-light flexer-5 left side-margin-0">Organization</th>
            <th className="text is-light remaining-width left side-margin-0">Description</th>
          </tr>
        </thead>
        <tbody>
          {organizations}
        </tbody>
      </table>
    </div>
  )
}
export default Organizations
