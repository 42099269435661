import React, { useState, useEffect } from 'react'

// Components
import Thumbnail from '../Elements/Thumbnail'
import Mentions from '../Elements/Mentions'
import GetOrgId from '../Elements/GetOrgId'

// Assets
import GroupIcon from '../../assets/images/group-icon.png'

// API
import { chatService } from '../../api'
import config from '../../api/config'

// Utilities
import parse from 'html-react-parser'


function ViewChat(props) {
  const [messagesObj, setMessagesObj] = useState([])
  const [braodcastsObj, setBroadcastsObj] = useState([])
  const [showBroadcast, setShowBroadcast] = useState(true)
  const [attachedFile, setAttachedFile] = useState(null)
  const [refresh, setRefresh] = useState(0)
  const [channelId, setChannelId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState(null)

  // Mentions
  const [mentions, setMentions] = useState([])
  const [textValue, setTextValue] = useState('')
  const [channelUsers, setChannelUsers] = useState([])
  const [channelProjects, setChannelProjects] = useState([])

  // Organization
  const orgId = GetOrgId()

  // Thread

  if (channelId !== props.selectedChannel.channel.id) {
    setLoading(true)
    setChannelId(props.selectedChannel.channel.id)
  }

  function updateScroll() {
    var element = document.getElementById("chat-section");
    element.scrollTop = element.scrollHeight;
  }

  useEffect(() => {

    chatService.list_messages(props.selectedChannel.channel.id, orgId)
      .then(response => {
        setMessagesObj(response.results)
        setPagination(response.next)
      })
      .then(() => {
        setLoading(false)
      })
      .then(() => {
        updateScroll()
      })
      .catch(error => {
        console.error("It was not possible to retrieve messages", error)
      })

  }, [channelId, orgId, props.selectedChannel.channel.id])

  useEffect(() => {

    chatService.get_channel(props.selectedChannel.channel.id, orgId)
      .then(response => {

        setShowBroadcast(response.unread_broadcast_messages)
        setBroadcastsObj(response.broadcast_messages)

        var users = []
        for (const u of response.channel_users) {
          users.push({ display: u.public_name, id: u.cognito_id })
        }
        setChannelUsers(users)

        var projects = []
        for (const c of response.cases) {
          projects.push({ display: c.reference_name, id: c.id })
        }
        setChannelProjects(projects)
      })
      .catch(error => {
        console.error("It was not possible to retrieve messages", error)
      })

  }, [channelId, orgId, props.selectedChannel.channel.id])

  // Submit new message
  function handleFileLoader() {
    var element = document.getElementById("file-loader-attachment")
    element.click()
  }

  function handleAttachment(e) {
    var filename = e.target.value.split('\\')
    filename = filename[filename.length - 1]
    setAttachedFile(<React.Fragment><i className="fa fa-file mr-20 is-light"></i> {filename}</React.Fragment>)
  }

  function replaceMentions(mentions, input_text_element) {
    mentions.sort((a, b) => b.startPos - a.startPos)

    for (const mention of mentions) {
      var subText = input_text_element.slice(mention.startPos, mention.endPos)

      if (subText !== mention.display) {
        continue
      }

      console.log(mention.type, mention.id)
      if (mention.type === "case" && mention.id.length === 36) {
        subText = `<a href="/organization/${orgId}/project/${mention.id}"><span class="text is-dark is-bold cursor">${subText}</span></a>`
      }
      else {
        subText = `<span class="text is-dark is-bold">${subText}</span>`
      }

      var iniText = input_text_element.slice(0, mention.startPos)
      var finalText = input_text_element.slice(mention.endPos, input_text_element.length)

      input_text_element = iniText + subText + finalText
    }

    return input_text_element
  }

  function handleSubmit(e) {
    e.preventDefault()
    var input_text_element = document.getElementById('chat-input-text').value
    input_text_element = replaceMentions(mentions, input_text_element)

    var form_element = document.getElementById('chat-form')
    var form_data = new FormData(form_element)

    form_data.delete('message')
    form_data.append('message', input_text_element)

    // Add project mentions to form data
    for (const mention of mentions) {
      if (mention.type === "case" && mention.id.length === 36) {
        form_data.append("case_mentions", mention.id)
      }
    }

    chatService.send_message(form_data, orgId)
      .then(response => {
        //var messagesList = messagesObj
        //messagesList.unshift(response)
        //setMessagesObj(messagesList)
        setAttachedFile(null)
        setRefresh(refresh + 1)
        setMentions([])
      })
      .then(() => {
        updateScroll()
      })
      .catch(error => {
        console.error("There was an error sending the message", error)
      })

    form_element.reset()
    setTextValue('')
  }

  function handlePagination() {
    chatService.message_pagination(pagination)
      .then(response => {
        setMessagesObj([...messagesObj, ...response.results])
        setPagination(response.next)
      })
  }


  // Broadcast Messages

  function handleToggleBroadcasts() {

    setShowBroadcast(!showBroadcast)
    props.onMark(props.selectedChannel.channel.id)

  }

  // Chat messages
  var messages = []
  var date = null

  for (const [index, message] of [...props.newMessage, ...messagesObj].entries()) {

    if (message.date !== date) {
      if (index !== 0) {
        messages.unshift(
          <p className="center text is-light chats__date-bubble">{date}</p>
        )
      }

      date = message.date
    }

    var name = message.created_by ? message.created_by.public_name : 'Unknown'
    var attachment = null
    if (message.attachment){
        var attachment_name = message.attachment.split('/')
        attachment_name = attachment_name[attachment_name.length - 1]

        let attachment_link = message.attachment
        if (!attachment_link.includes('//')){
            const slash = attachment_link.startsWith('/') ? '' : '/'
            attachment_link = `${config.mediaUri}${slash}${attachment_link}`
        }
        attachment = <a className="link" href={attachment_link} target="_blank" rel="noopener noreferrer">{attachment_name}</a>
    }

    messages.unshift(
      <div className="flexer left mt-20 thread-wrapper">
        <Thumbnail user={message.created_by} />
        <div className="ml-20 remaining-width">
          <div className="flexer flexer-spaced">
            <p className="heading is-dark line-margin-0">{name}</p>
            <p className="text is-light line-margin-0">{message.time}</p>
          </div>
          <p className="text is-light line-margin-xs">{message.message ? parse(message.message) : null}</p>
          {attachment}
          {message.thread ? <p className="text is-orange cursor line-margin-0" onClick={() => { props.onSelectThread(message.id) }}>View thread ({message.number_messages})</p> : <p className="text is-orange cursor line-margin-0 start-thread" onClick={() => { props.onSelectThread(message.id) }}>Start thread</p>}
        </div>
      </div>
    )
  }

  if (date) {
    messages.unshift(
      <p className="center text is-light chats__date-bubble">{date}</p>
    )
  }

  if (pagination) {
    messages.unshift(
      <p className="center text is-light chats__date-bubble cursor" onClick={handlePagination}>Load More</p>
    )
  }

  // Broadcast messages
  var broadcastMessages = []
  var broadcastOptions = []
  for (const [, message] of [...braodcastsObj, ...props.newBroadcastMessage].entries()) {
    broadcastMessages.push(
      <React.Fragment>
        <p className="heading is-dark line-margin-0">{message.title}</p>
        <p className="text is-light line-margin-0">{message.message}</p>
        <br />
      </React.Fragment>
    )

    if (message.case) {
      broadcastOptions.push(
        { display: message.title, id: message.case }
      )
    }
    else {
      broadcastOptions.push(
        { display: message.title, id: message.id }
      )
    }
  }

  var broadcast
  if (broadcastMessages.length > 0) {
    if (showBroadcast) {
      broadcast = <div className="left chats__broadcast flexer scroll">
        <div className="full-width">
          {broadcastMessages}
        </div>
        <i className="fas fa-angle-down is-light ml-20 cursor" onClick={handleToggleBroadcasts}></i>
      </div>
    }
    else {
      broadcast = <div className="left chats__broadcast flexer flexer-vcenter scroll">
        <div className="full-width" onClick={handleToggleBroadcasts}>
          <p className="text is-orange cursor line-margin-0">View broadcast messages</p>
        </div>
        <i className="fas fa-angle-right is-light ml-20 cursor" onClick={handleToggleBroadcasts}></i>
      </div>
    }
  }

  // Mentions

  function handleChange(e, newValue, newPlainTextValue, mentions) {
    var text = newPlainTextValue
    setTextValue(text)
  }
  function handleAddUser(id, display, startPos, endPos) {
    endPos = startPos + display.length + 1
    display = "@" + display
    setMentions([...mentions, ...[{ type: 'user', startPos, endPos, display, id }]])
  }
  function handleAddBroadcast(id, display, startPos, endPos) {
    endPos = startPos + display.length + 1
    display = "#" + display
    setMentions([...mentions, ...[{ type: 'case', startPos, endPos, display, id }]])
  }
  function handleAddProject(id, display, startPos, endPos) {
    endPos = startPos + display.length + 1
    display = "$" + display
    setMentions([...mentions, ...[{ type: 'case', startPos, endPos, display, id }]])
  }


  function onKeyDownHandler(e) {
    if (e.keyCode === 13) {
      handleSubmit(e)
    }
  }

  return (
    <React.Fragment>
      <div className="simple-profile__sections flexer flexer-vcenter">
        {props.selectedChannel.channel.type === "direct" ? <Thumbnail user={props.selectedChannel.channel_profile} /> : <img style={{ 'width': '40px' }} alt="profile" src={GroupIcon} />}
        <p className="heading is-dark line-margin-0 ml-10">{props.selectedChannel.channel_name}</p>
      </div>
      {broadcast}
      <div id="chat-section" className="simple-profile__sections max-screen__bottom scroll">
        {loading ? <p className="text line-margin-0 is-light">Loading Messages</p> : messages}
      </div>
      <form id="chat-form" className="simple-profile__sections flexer flexer-vcenter flexer-spaced" onSubmit={handleSubmit} onKeyDown={onKeyDownHandler} >
        <div className="flexer-12">
          <div className="flexer flexer-vcenter">
            <input id="file-loader-attachment" name="attachment" type="file" className="file-input" onChange={handleAttachment} />
            <i className="fa fa-paperclip mr-20 cursor is-dark" onClick={handleFileLoader}></i>
            <div className="remaining-width">
              <Mentions
                textValue={textValue}
                handleChange={handleChange}
                channelUsers={channelUsers}
                handleAddUser={handleAddUser}
                channelProjects={channelProjects}
                handleAddProject={handleAddProject}
                broadcastOptions={broadcastOptions}
                handleAddBroadcast={handleAddBroadcast} />
              <p className="text is-light line-margin-0 left" id="attached-file">{attachedFile}</p>
            </div>
            <input type="hidden" name="channel" value={props.selectedChannel.channel.id} />
          </div>
        </div>
        <i className="fa fa-paper-plane is-orange cursor" onClick={handleSubmit}></i>
      </form>
    </React.Fragment>
  )
}

export default ViewChat