import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import UserOrganizationsContent from '../containers/Content/UserOrganizationsContent'

function UserOrganizations () {
	return (
		<React.Fragment>
			<Header />
			<Content>
				<UserOrganizationsContent />
			</Content>
	</React.Fragment>
	)
}

export default UserOrganizations
