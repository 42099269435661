/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const memberService = {
  list,
  listByUrl,
  create,
  update,
  delete_member,
  search,
}

async function list(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/members/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function listByUrl(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function create(body, org_id) { // chequear endpoint 01/09/21
  const bodyFixed = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyFixed) }
  return fetch(`${config.apiUrl}/organization/${org_id}/members/`, requestOptions)
    .then(handleResponse)
    .then(org => {
      return org
    })
}

async function update(body, member_id, org_id) {
  const bodyFixed = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bodyFixed) }
  return fetch(`${config.apiUrl}/organization/${org_id}/members/${member_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}


async function delete_member(org_id, member_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }
  return fetch(`${config.apiUrl}/organization/${org_id}/members/${member_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function search(keyword, org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(`${config.apiUrl}/organization/${org_id}/members/search/?keyword=${keyword}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
