import React from 'react'

const ImageContent = (props) => {
  const { className, src, alt } = props

  let image_src = window.location.href.includes('localhost') ? `/media/${src}` : src

  return (
    <img className={className} src={image_src} alt={alt} />
  )
}

export default ImageContent
