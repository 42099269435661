import React, { useState } from 'react'

//Components
import StarRatings from 'react-star-ratings'
import Modal from '../Elements/Modal'
import EditProfileForm from '../../components/Profile/Forms/EditProfileForm'

// Hooks
import { UserContext } from '../../hooks/UserContext'
import VerifyAttribute from '../../components/Profile/Forms/VerifyAttribute'
import { authenticationService } from '../../api'


function SimpleProfile(props) {
  const { public_name, profile, email, phone_number } = props.user
  const [isOpen, setIsOpen] = useState(false)
  const [toVerify, setToVerify] = useState({ phone_number: false, email: false })

  function openModal() {
    setIsOpen(true)
  }
  function closeModal(refresh = false) {
    if (refresh) {
      props.onUpdate()
    }
    setIsOpen(false)
  }


  let publicName = public_name ?? null
  let rating = profile ? profile.rating : 0
  rating = rating ? rating : 0
  let ratingString = rating ? rating.toString() : null
  let numberReviews = profile ? profile.reviews.length : null
  let userEmail = email ?? null
  let phoneNumber = phone_number ??  null
  let occupation = profile ? profile.occupation : null
  let profilePic = profile ? profile.profile_pic : null
  profilePic = profilePic ? profilePic : 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

  if (profile) {
    var facebook = profile?.facebook_link ?? null
    var instagram = profile?.instagram_link ?? null
    var linkedin = profile?.linkedin_link ?? null
    var youtube = profile?.youtube_link ?? null
  }

  // Attributes verification
  function handleNewVerification(phoneNumber, email) {
    if (!props.as_admin) {
      let toVerifyCurrent = toVerify
      if (phoneNumber) {
        toVerifyCurrent['phone_number'] = true
      }

      if (email) {
        toVerifyCurrent['email'] = true
      }

      setToVerify({ ...toVerifyCurrent })
      authenticationService.updateVerificationInfo(toVerifyCurrent)
    }
  }

  function handleVerification(attribute) {
    setToVerify({ ...toVerify, [attribute]: false })
  }

  let verifications = []
  if (!props.as_admin && toVerify.phone_number ){
      verifications.push(<VerifyAttribute name="phone_number" displayName="Phone number" onVerification={handleVerification} userId={props.user.cognito_id}/>)
  }
  
  if (!props.as_admin && toVerify.email){
      verifications.push(<VerifyAttribute name="email" displayName="Email" onVerification={handleVerification} userId={props.user.cognito_id}/>)
  }

  return (
    <div className="simple-profile__sections center">
      <img className="simple-profile__picture" src={profilePic} alt="profile" />
      <div className="simple_profile__upper-sections">
        <p className="subtitle is-dark line-margin-s">{publicName} {props.allow_edit ? <span className="fa fa-pencil cursor is-dark" onClick={openModal} /> : null}</p>
        <div className="flexer flexer-vcenter center">
          <StarRatings rating={rating} starDimension="15px" starSpacing="0px" starRatedColor="#44C568" />
          <span className="rating-text mx-10">{ratingString} <span className="text is-light">({numberReviews} reviews)</span></span>
        </div>
      </div>

      <div className="simple_profile__upper-sections">
        <p className="text is-light line-margin-s icon-text"><i className="fa fa-envelope mr-10 text-icon" />{userEmail}</p>
        <p className="text is-light line-margin-s"><i className="fa fa-phone mr-10 text-icon" />{phoneNumber}</p>
        <p className="text is-light line-margin-s">{occupation}</p>
      </div>

      <div className="simple_profile__upper-sections">
        <a href={instagram} target="_blank" rel="noreferrer" disabled={facebook ? facebook : "disabled"}><i className="fab fa-instagram social-icons" /></a>
        <a href={facebook} target="_blank" rel="noreferrer" disabled={facebook ? facebook : "disabled"}><i className="fab fa-facebook-f social-icons" /></a>
        <a href={youtube} target="_blank" rel="noreferrer" disabled={facebook ? facebook : "disabled"}><i className="fab fa-youtube social-icons" /></a>
        <a href={linkedin} target="_blank" rel="noreferrer" disabled={facebook ? facebook : "disabled"}><i className="fab fa-linkedin-in social-icons" /></a>
      </div>

      {verifications}

      <Modal isOpen={isOpen} handleClose={closeModal} >
        <EditProfileForm onClose={closeModal} profilePic={profilePic} user={props.user} onNewVerification={handleNewVerification} />
      </Modal>
    </div>
  )
}

export default SimpleProfile
