import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import { UserContext } from './hooks/UserContext'
import PrivateRoute from './components/PrivateRoute'
import ThemeMode from './components/ThemeMode'
import useFindUser from './hooks/useFindUser'
import Profile from './pages/Profile'
import UserResources from './pages/UserResources'
import Dashboard from './pages/Dashboard'
import Project from './pages/Project'
import Projects from './pages/Projects'
import Chats from './pages/Chats'
import Analytics from './pages/Analytics'
import OrganizationSettings from './pages/OrganizationSettings'
import UserOrganizations from './pages/UserOrganizations'
import Home from './pages/Home'
import './assets/styles/grid/simple-grid.min.css'
import './assets/styles/variables.scss'
import './assets/styles/main.scss'


function App() {
  const { currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading } = useFindUser()

  return (
    <ThemeMode>
      <BrowserRouter>
        <UserContext.Provider value={{ currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading }}>
          <Switch>
            {/* <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/verify" component={VerifyEmail} />
          <Route exact path="/recover-password" component={RecoverPassword} /> */}
            <PrivateRoute exact path="/profile/:user_id" component={Profile} />
            <PrivateRoute exact path="/profile/:user_id/:org_id" component={Profile} />
            <PrivateRoute exact path="/organization/:org_id/user-resources" component={UserResources} />
            <PrivateRoute exact path="/organization/:org_id" component={Dashboard} />
            <PrivateRoute exact path="/organization/:org_id/project/:project_id" component={Project} />
            <PrivateRoute exact path="/organization/:org_id/projects/:project_type" component={Projects} />
            <PrivateRoute exact path="/organization/:org_id/chats" component={Chats} />
            <PrivateRoute exact path="/organization/:org_id/chats/thread/:thread_id" component={Chats} />
            <PrivateRoute exact path="/organization/:org_id/analytics" component={Analytics} />
            <PrivateRoute exact path="/organization/:org_id/organization-settings" component={OrganizationSettings} />
            <PrivateRoute exact path="/organizations" component={UserOrganizations} />
            <PrivateRoute exact path="" component={Home} />
          </Switch>
        </UserContext.Provider>
      </BrowserRouter>
    </ThemeMode>
  )
}

export default App;
