import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  link: string
  text: string
  iconClass?: string
  className?: string
  linkClass?: string
}

const Hyperlink: React.FC<Props> = (props: Props) => {
  const { iconClass, text, className, linkClass, link } = props

  return (
    <div className={className}>
      <Link className={`${linkClass} link`} to={link}>
        {iconClass && <i className={`${iconClass}`} />}
        {text}
      </Link>
    </div>
  )
}

Hyperlink.defaultProps = {
  className: '',
  linkClass: '',
}

export default Hyperlink;
