import React, { useState } from 'react'

// Components
import Button from '../Elements/Button'
import ImageContent from 'components/Elements/ImageContent'
import Modal from '../Elements/Modal'
import EditProfileForm from './Forms/EditProfileForm'
import ConfidenceIcon from 'components/Elements/ConfidenceIcon'

function ProfileSection(props) {

    const [isOpen, setIsOpen] = useState(false)
    const [subject, setSubject] = useState(null)

    
    let profilePic = 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'
    var phones = []
    var aliases = []
    let dob
    let email
    let first_name 
    let middle_name 
    let last_name

    if (props.project.subject) {
        // ID
        if(!subject) {
            setSubject(props.project.subject)
        }
        
        // Name
        first_name = {
            content: props.project.subject?.first_name ? props.project.subject.first_name.content : null,
            confidence: props.project.subject?.first_name ? props.project.subject.first_name.confidence : null,
            id: props.project.subject?.first_name ? props.project.subject.first_name.id : null
        }
    
        middle_name = {
            content: props.project.subject?.middle_name ? props.project.subject.middle_name.content : null,
            confidence: props.project.subject?.middle_name ? props.project.subject.middle_name.confidence : null,
            id: props.project.subject?.middle_name ? props.project.subject.middle_name.id : null
        }
    
        last_name = {
            content: props.project.subject?.last_name ? props.project.subject.last_name.content : null,
            confidence: props.project.subject?.last_name ? props.project.subject.last_name.confidence : null,
            id: props.project.subject?.last_name ? props.project.subject.last_name.id : null
        }
    
        // Get aliases
        for(const [index, alias] of props.project.subject.alias.entries()){
					let alias_first_name 
					let alias_middle_name 
					let alias_last_name

					alias_first_name = {
							content: alias?.first_name ? alias.first_name.content : null,
							confidence: alias?.first_name ? alias.first_name.confidence : null,
							id: alias?.first_name ? alias.first_name.id : null
					}
			
					alias_middle_name = {
							content: alias?.middle_name ? alias.middle_name.content : null,
							confidence: alias?.middle_name ? alias.middle_name.confidence : null,
							id: alias?.middle_name ? alias.middle_name.id : null
					}
			
					alias_last_name = {
							content: alias?.last_name ? alias.last_name.content : null,
							confidence: alias?.last_name ? alias.last_name.confidence : null,
							id: alias?.last_name ? alias.last_name.id : null
					}

            aliases.push(
                <div className="flexer flexer-spaced my-10" key={`alias_${index}`}>
                    <p className="text is-dark is-bold line-margin-s">Alias {index + 1}</p>

					<div className='flexer'>
                        { alias_first_name?.content && (
                            <div className='flexer flexer-vcenter wcc-hasconfidence'>
                                <p className="text is-dark line-margin-s">{alias_first_name.content}</p>
                                <div className='center'>
                                    <ConfidenceIcon confidence={alias_first_name.confidence}  orgId={props.orgId} dataId={alias_first_name.id} onClose={props.onClose} />
                                </div>
                            </div>
                        )}
                        { alias_middle_name?.content && (
                            <div className='flexer flexer-vcenter wcc-hasconfidence'>
                                <p className="text is-dark line-margin-s">{alias_middle_name.content}</p>
                                <div className='center'>
                                    <ConfidenceIcon confidence={alias_middle_name.confidence}  orgId={props.orgId} dataId={alias_middle_name.id} onClose={props.onClose} />
                                </div>
                            </div>
                        )}
                        { alias_last_name?.content && (
                            <div className='flexer flexer-vcenter wcc-hasconfidence'>
                                <p className="text is-dark line-margin-s">{alias_last_name.content}</p>
                                <div className='center'>
                                    <ConfidenceIcon confidence={alias_last_name.confidence}  orgId={props.orgId} dataId={alias_last_name.id} onClose={props.onClose} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }

        // Get phones
        
        for(const [index, phone] of props.project.subject.phones.entries()){

            // aliases.push(
            phones.push(
                <div className="flexer flexer-spaced my-10" key={`phone_${index}`}>
                    <p className="text is-dark is-bold line-margin-s">Phone number {index + 1}</p>
					<div className='flexer flexer-vcenter wcc-hasconfidence mv-profpic'>
                        <p className="text is-dark line-margin-s">{phone.phone_number.content.trim()}</p>
                            { phone.phone_number.content && (
                            <ConfidenceIcon confidence={phone.phone_number.confidence}  orgId={props.orgId} dataId={phone.phone_number.id} onClose={props.onClose}  />
                            )}
					</div>
                </div>
            )
        }

        // DOB
        dob = {
            content: props.project.subject?.proper_dob ? props.project.subject.proper_dob : null,
            confidence: props.project.subject?.dob ? props.project.subject.dob.confidence : null,
            id: props.project.subject?.dob ? props.project.subject.dob.id : null
        }

        // Email
        email = {
            content: props.project.subject?.email ? props.project.subject.email.content : null,
            confidence: props.project.subject?.email ? props.project.subject.email.confidence : null,
            id: props.project.subject?.email ? props.project.subject.email.id : null
        }

        // Profile pic
        profilePic = {
            content: props.project.subject.profile_pic.content ? props.project.subject.profile_pic.content : profilePic,
            confidence: props.project.subject?.profile_pic ? props.project.subject.profile_pic.confidence : null,
            id: props.project.subject?.profile_pic ? props.project.subject.profile_pic.id : null
        }


    }

    var profileElement = <EditProfileForm onClose={()=>{setIsOpen(false)}} onComplete={props.onClose} subject={subject}/>


    
    return (
        <React.Fragment>
            <div className="simple-profile__sections center">
                <div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic'>
                    <ImageContent className="simple-profile__picture one-fourth-width" src={profilePic.content} alt="profile" />
                    { profilePic.content && (
                        <ConfidenceIcon confidence={profilePic.confidence}  orgId={props.orgId} dataId={profilePic.id} onClose={props.onClose}  />
                    )}
                </div>
                <div className='flexer center my-20'>
                    { first_name?.content && (
                        <div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
                            <p className="subtitle is-dark line-margin-s">{first_name.content}</p>
							<div className='center'>
								<ConfidenceIcon confidence={first_name.confidence}  orgId={props.orgId} dataId={first_name.id} onClose={props.onClose} />
							</div>
                        </div>
                    )}
                    { middle_name?.content && (
                        <div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
                            <p className="subtitle is-dark line-margin-s">{middle_name.content}</p>
							<div className='center'>
								<ConfidenceIcon confidence={middle_name.confidence}  orgId={props.orgId} dataId={middle_name.id} onClose={props.onClose} />
							</div>
                        </div>
                    )}
                    { last_name?.content && (
                        <div className='flexer flexer-vcenter wcc-hasconfidence mx-5'>
                            <p className="subtitle is-dark line-margin-s">{last_name.content}</p>
							<div className='center'>
								<ConfidenceIcon confidence={last_name.confidence}  orgId={props.orgId} dataId={last_name.id} onClose={props.onClose} />
							</div>
                        </div>
                    )}
                </div>
                { dob?.content && (
                        <div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
                            <p className="text is-dark line-margin-s">{dob.content}</p>
                            <ConfidenceIcon confidence={dob.confidence}  orgId={props.orgId} dataId={dob.id} onClose={props.onClose} />
                        </div>
                    )}

                { email?.content && (
                        <div className='flexer flexer-vcenter center wcc-hasconfidence mv-profpic my-10'>
                            <p className="text is-light line-margin-s icon-text"><i className="fa fa-envelope mr-10 text-icon" />{email.content}</p>
                            <ConfidenceIcon confidence={email.confidence}  orgId={props.orgId} dataId={email.id} onClose={props.onClose} />
                        </div>
                    )}                
                <div className="mx-15">
                    {aliases}
                    {phones}
                </div>
                    {props.closed ? null :
                            <div className="mt-15">
                                <Button type="button" text="Edit Profile" class="orange-btn" icon="fa fa-pencil cursor" onClick={()=>setIsOpen(true)}/>
                            </div>
                    }
            </div>
            <Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
                {profileElement}
            </Modal>
        </React.Fragment>
    )
}

export default ProfileSection
