import React from 'react'
import './loading.scss'

interface Props {
  text: String
}

const Loading: React.FC<Props> = (props: Props) => {
  const { text } = props
  return (
    <div className="wcc-loading">
      <div className="wcc-circle">
        <div className="wcc-ltext">{text}</div>
      </div>
    </div>);
};

export default Loading
