import React, { useState, useEffect } from 'react'
import { Dropdown, Button, GetOrgId } from 'components/Elements'
import { projectService, docsService, subjectService, projectTypesService } from 'api'

function PriorityOfProjectForm(props) {
  const [projectTypes, setProjectTypes] = useState(null)
  const orgId = GetOrgId()

  useEffect(() => {
    projectTypesService.list(orgId)
      .then((response) => {
        setProjectTypes(response)
      })
  }, [orgId])

  function handleSubmit(e) {
    e.preventDefault()

    // Create Subject
    var subject_data = props.data.subject_data
    var body = { "alias": [], "phones": [] }

    for (var [key, value] of Object.entries(subject_data)) {
      if (value) {
        if (key.includes('phones')) {
          value = subject_data['calling_code'] + value
        } else if (key === 'calling_code') {
          continue
        }

        if (key.includes('__')) {
          key = key.split('__')
          var subelement = {}
          subelement[key[1]] = {content:value}
          body[key[0]].push(subelement)          
        }
        else {
          body[key] = {content:value}
        }
      }
    }

    subjectService.create(body, orgId)
      .then(response => {
        //Create project
        var form_element = document.getElementById('create-project-form')
        var form_data = new FormData(form_element)

        var body = props.data
        delete body.subject_data
        form_data.forEach((value, key) => {
          if (value) {
            body[key] = value
          }
        })
        body['subject'] = response.id

        projectService.create(body, orgId)
          .then(async case_ => {
            if (props.data.docs_attached) {
              let promises = []
              for (const file of props.data.docs_attached) {
                let formData = new FormData()
                formData.append("doc", file)
                formData.append("case", case_.id)
                promises.push(docsService.create(formData, orgId))
              }
              await Promise.allSettled(promises)
            }
            return case_
          })
          .then(case_ => {
            props.onUpdate({ case: case_.id })
          })
          .then(() => {
            props.onNext()
          })
      })
      .catch(error => {
        console.error("Error creating project", error)
      })

    return
  }

  function handleClickPrevious() {
    var form_element = document.getElementById('create-project-form')
    var form_data = new FormData(form_element)

    var body = props.data
    form_data.forEach((value, key) => {
      if (value) {
        body[key] = value
      }
    })

    props.onUpdate({ ...body })
    props.onPrevious()
  }

  const options = !projectTypes ? ['No aoptions available'] :
    projectTypes.map((type, idx) =>
      <option id={idx} value={type.id} selected={type.code ? true : false}>{type.name}</option>
    )

  return (
    <div className="half-width center">
      <form id="create-project-form" className="left mt-60" onSubmit={handleSubmit}>
        <div className="flexer">
          <i className="form-icon fas fa-user-alt"></i>
          <Dropdown name="case_type" class="full-width line-margin-xs" label="Project type">
            {options}
          </Dropdown>
        </div>

        <div className="flexer">
          <i className="form-icon fa fa-level-up"></i>
          <Dropdown name="priority" class="full-width line-margin-xs" label="Priority of the project">
            <option value="high" selected={props.data.priority === "high" ? true : false}>High Priority</option>
            <option value="standard" selected={props.data.priority === "standard" ? true : false}>Standard Priority</option>
          </Dropdown>
        </div>

        <div className="flexer flexer-spaced center-mobile">
          <Button text="&larr; Previous step" class="mt-15 white-btn is-orange subheading is-light" type="button" onClick={handleClickPrevious} />
          <Button text="Create Project &#43;" class="mt-15 orange-btn subheading is-light" />
        </div>
      </form>
    </div>
  )
}

export default PriorityOfProjectForm
