import React, { useState, useEffect } from 'react'

// Components
import Button from '../../Elements/Button'
import Dropdown from '../../Elements/Dropdown'
import GetOrgId from '../../Elements/GetOrgId'
import ImageContent from '../../Elements/ImageContent'
import InputField from '../../Elements/InputField'
import Textarea from '../../Elements/Textarea'
import Tooltip from '../../Elements/Tooltip'

// API
import { accountService, accountImageService } from '../../../api'

function AddAccountForm(props) {
  const [errors, setErrors] = useState({})
  const [extra_fields, setExtraFields] = useState([])
  const [newImages, setNewImages] = useState([])
  const [accountType, setAccountType] = useState(props.account.type)

  const [imagesUploaded, setImagesUploaded] = useState([])

  const orgId = GetOrgId()

  // TODO: The 'addExtraFields' function makes the dependencies of useEffect Hook (at line 119) change on every render. To fix this, wrap the definition of 'addExtraFields' in its own useCallback() Hook  react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function addExtraFields(type_) {
    if (type_ === "twitter") {
      setExtraFields(
        [<InputField name="handle" label="Handle" type="text" class="line-margin-xs" placeholder={props.account.handle?.content && props.account.handle.content} />]
      )
    }
    else if (type_ === "linkedin") {
      setExtraFields(
        [<InputField name="job_title" label="Job Title" type="text" class="line-margin-xs" placeholder={props.account.job_title?.content && props.account.job_title.content} />,
        <InputField name="employer" label="Employer" type="text" class="line-margin-xs" placeholder={props.account.employer?.content && props.account.employer.content} />]
      )
    }
    else {
      setExtraFields([])
    }
  }

  function handleChange(e) {
    const type_ = e.target.value
    setAccountType(type_)
    addExtraFields(type_)
  }

  function handleSubmit(e) {
    e.preventDefault()

    var form_element = document.getElementById('account-form')
    var form_data = new FormData(form_element)

    var body = {}
    var last_active = {}
    form_data.forEach((value, key) => {
      if (value) {
        if (key.includes('last_active')) {
          last_active[key] = value
        }
        // else if (key === 'type' || key === 'note') {
        else if (key === 'type' || key === 'user_id' || key === 'note') {
          body[key] = value
        }
        else if (key === 'subject') {
          body[key] = parseInt(value)
        }
        else {
          body[key] = { content:value }
        }
      }
    })

    if (last_active) {
      if (last_active.last_active__date) {
        if (last_active.last_active__time) {
          body['last_active'] = {content: `${last_active.last_active__date} ${last_active.last_active__time}`}
        }
        else {
          body['last_active'] = {content: `${last_active.last_active__date} 00:00`}
        }
      }
    }


    if (props.account.id) {
      accountService.update(body, orgId, props.account.id)
        .then(response => {
          props.onClose()
        })
        .catch(error => {
          console.log(error)
          if (Array.isArray(error)) {
            error = { non_field_errors: error[0] }
          }
          setErrors({ ...error })
        })
    }
    else {
      accountService.create(body, orgId)
        .then(async response => {
          if (imagesUploaded.length) {
            for (const img of imagesUploaded) {
              let formData = new FormData()
              formData.append("image", img)
              formData.append("account", response.id)
              await accountImageService.create(formData, orgId)
            }
          }
          return response
        })
        .then(() => {
          props.onClose()
        })
        .catch(error => {
          console.log(error)
          if (Array.isArray(error)) {
            error = { non_field_errors: error[0] }
          }
          setErrors({ ...error })
        })
    }
  }

  // useEffect(() => {
  //   addExtraFields(props.account.type)
  // }, [addExtraFields, props.account.type])

  useEffect(() => {
    props.account.type && addExtraFields(props.account.type)
  }, [ props.account.type])

  function handleFileLoader() {
    var element = document.getElementById("file-loader-image-account")
    element.click()
  }

  async function handleSubmitImage(e) {
    e.preventDefault()

    let newImagesUploaded = []

    for (const img of e.target.files) {
      let formData = new FormData()
      formData.append("image", img)
      formData.append("account", props.account.id)
      await accountImageService.create(formData, orgId)
        .then(response => {
          newImagesUploaded.push(response.image)
        })
    }

    setNewImages([...imagesUploaded, ...newImagesUploaded])
  }

  function handleChangeImg(e) {
    let newInitialImages = []
    for (let img of e.target.files) {
      newInitialImages.push(img)
    }

    setImagesUploaded([...imagesUploaded, ...newInitialImages])
  }

  function handleDelete(e) {
    let toDelete = e.currentTarget.getAttribute('name')
    let filtered = imagesUploaded.filter(files => files.name !== toDelete)
    setImagesUploaded(filtered)
  }

  var docs = []
  if (imagesUploaded) {
    for (const doc of imagesUploaded) {
      docs.push(
        <div className="project-links flexer flexer-spaced flexer-vcenter">
          <div className="flexer flexer-vcenter left">
            <p className="far fa-file is-light line-margin-0"></p>
            <div className="mx-15">
              <a href={doc.doc} className="text is-dark line-margin-0 cursor breakable" target="_blank" rel="noopener noreferrer">{doc.name}</a>
              <p className="text is-light line-margin-0">{parseInt(doc.size / 1000)} KB</p>
            </div>
          </div>
          <i className="fa fa-trash-o is-light cursor" name={doc.name} onClick={handleDelete}></i>
        </div>
      )
    }
  }

  var images_uploaded_form =
    <React.Fragment>
      <input id="file-loader-image-account" name="image" type="file" className="file-input" multiple onChange={handleChangeImg} />
      <h1 className="text is-dark is-bold mt-30">Upload images</h1>
      <div className="flexer flexer-vcenter" onClick={handleFileLoader}>
        <div className="upload-wrapper cursor my-15">
          <i className="fa fa-plus upload-wrapper__icon"></i>
        </div>
      </div>
      {docs.length > 0 && docs}
    </React.Fragment>


  var id_input = null
  var images_form = null
  if (props.account.id) {
    id_input = <input type="hidden" name="account" value={props.account.id} />

    var images = []

    // for (const image of props.account.images) {
    //   images.push(<ImageContent className="small-size-image one-fifth-width mr-10" src={image.image.content} alt="photo" />)
    // }

    // for (const image of newImages) {
    //   images.push(<ImageContent className="small-size-image one-fifth-width mr-10" src={image.image.content } alt="photo" />)
    // }

    for (const image of props.account.images) {
      images.push(<img className="small-size-image one-fifth-width mr-10" src={image.image} alt="photo" />)
    }
    for (const image of newImages) {
      images.push(<img className="small-size-image one-fifth-width mr-10" src={image} alt="photo" />)
    }

    images_form =
      <form id="images-form">
        <input id="file-loader-image-account" name="image" type="file" className="file-input" multiple onChange={handleSubmitImage} />
        {id_input}
        <h1 className="text is-dark is-bold mt-30">Upload images</h1>
        <div className="flexer flexer-vcenter" onClick={handleFileLoader}>
          <div className="upload-wrapper cursor my-15">
            <i className="fa fa-plus upload-wrapper__icon"></i>
          </div>
        </div>
        <p className="text is-light line-margin-0">Uploaded</p>
        <div className="flexer x-scroll line-margin-xs">
          {images}
        </div>
      </form>
  }

  var inputFields
  if (accountType !== "streetview") {
    inputFields =
      <React.Fragment>
        {/* <InputField name="user_id" label="User ID" type="text" class="line-margin-xs" placeholder={props.account.user_id?.content && props.account.user_id.content} /> */}
        <InputField name="user_id" label="User ID" type="text" class="line-margin-xs" placeholder={props.account.user_id} />
        <InputField name="display_name" label="Display Name" type="text" class="line-margin-xs" placeholder={props.account.display_name?.content && props.account.display_name.content} />
        <Tooltip content='www.example.com/example' direction='bottom' class='full-width'>
          <InputField name="profile_page" label="Profile page URL" type="text" class="line-margin-xs" placeholder={props.account.profile_page?.content && props.account.profile_page.content} error={errors.profile_page} />
        </Tooltip>
        <div className="flexer flexer-spaced">
          <InputField name="last_active__date" label="Last active" type="date" class="flexer-6 line-margin-xs" />
          <InputField name="last_active__time" label="Time" type="time" class="flexer-6 line-margin-xs" />
        </div>
        {extra_fields}
        <Textarea name="note" label="Notes" />
      </React.Fragment>
  }
  else {
    inputFields =
      <React.Fragment>
        <InputField name="display_name" label="Location" type="text" class="line-margin-xs" placeholder={props.account.display_name?.content ? props.account.display_name.content : "Residence, Last Known, Place of Birth..."} />
        <Tooltip content='www.example.com/example' direction='bottom' class='full-width'>
          <InputField name="profile_page" label="Street View URL" type="text" class="line-margin-xs" placeholder={props.account.profile_page?.content} error={errors.profile_page} />
        </Tooltip>
        {extra_fields}
        <Textarea name="note" label="Notes" />
      </React.Fragment>
  }

  return (
    <React.Fragment>
      <form id="account-form" onSubmit={handleSubmit}>
        {props.account.id ? <h1 className="title is-dark mb-30"> Edit Account</h1> : <h1 className="title is-dark mb-30"> Add Account</h1>}
        <Dropdown name="type" label="Social Media" type="text" class="line-margin-xs" onChange={handleChange}>
          <option value="facebook" selected={props.account.type === "facebook" ? true : false}>Facebook</option>
          <option value="linkedin" selected={props.account.type === "linkedin" ? true : false}>LinkedIn</option>
          <option value="twitter" selected={props.account.type === "twitter" ? true : false}>Twitter</option>
          <option value="streetview" selected={props.account.type === "streetview" ? true : false}>Street View</option>
          <option value="other" selected={props.account.type === "other" ? true : false}>Other</option>
        </Dropdown>
        {inputFields}
        {id_input}
        <input type="hidden" name="subject" value={props.subject} />
        {!props.account.id && images_uploaded_form}
      </form>

      {images_form}

      <div className="right center-mobile">
        <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose} />
        <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" form="account-form" />
      </div>
    </React.Fragment>
  )
}

export default AddAccountForm
