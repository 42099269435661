import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// API
import { memberService } from '../../api'

// Components
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'
import GetOrgId from '../Elements/GetOrgId'

// Hooks
import { UserContext } from '../../hooks/UserContext'


function OrganizationMembers(props) {
  const [memberList, setMemberList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [toDelete, setToDelete] = useState({})
  const [refresh, setRefresh] = useState(0)
  const [errors, setErrors] = useState({})

  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)

  const [displayedMembers, setDisplayedMembers] = useState([])

  const { currentOrganizations } = useContext(UserContext)  // global context

  const orgId = GetOrgId()
  const orgMemberInfo = currentOrganizations
  const currentRole = orgMemberInfo[orgId].role

  useEffect(() => {
    memberService.list(orgId)
      .then((response) => {
        setMemberList(response.results)
        setDisplayedMembers(response.results)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch((error) => {
        console.error("Unable to get members list", error)
      })
  }, [orgId, refresh])

  function handlePagination(url) {
    memberService.listByUrl(url)
      .then((response) => {
        setMemberList(response['results'])
        setDisplayedMembers(response.results)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch(error => {
        console.log("Error loading users", error)
      })
  }

  function handleChange(e) {
    var member_id = e.target.getAttribute("data-user")
    var role = e.target.value
    var body = { role }
    memberService.update(body, member_id, orgId)
      .then(response => {
      })
      .catch(error => {
        console.error("Unable to update role", error)
        if ('detail' in error) {
          setErrors({ ...errors, [member_id]: error.detail })
        }
      })
  }

  function handleClick(e) {
    setIsOpen(true)
    setToDelete({ name: e.currentTarget.getAttribute('name'), email: e.currentTarget.getAttribute('email'), id: e.currentTarget.getAttribute('memberId') })
  }

  function handleDelete() {
    memberService.delete_member(orgId, toDelete.id)
      .then(response => {
        let users = memberList
        delete users[toDelete.id]
        setMemberList(users)
      })
      .then(() => {
        setIsOpen(false)
        setRefresh(refresh + 1)
        props.onUpdate()
      })
      .catch(error => {
        console.error("Unable to delete user", error)
      })
  }


  function handleSearch(e) {
    if (e.target.value.length > 0) {
      memberService.search(e.target.value, orgId)
        .then(response => {
          setDisplayedMembers(response)
        })
        .catch(error => {
          console.error(error)
          setDisplayedMembers(memberList)
        })
    } else {
      setDisplayedMembers(memberList)
    }
  }


  let members = displayedMembers.map(member => (member.role !== "UNASSIGNED") && (<tr className="table-rows flexer">
    <td className="text is-light flexer-4 left side-margin-0"><Link to={`/profile/${member.user.cognito_id}/${orgId}`} className="text is-light">{member.user.public_name}</Link></td>
    <td className="text is-light flexer-5 left side-margin-0">{member.user.email}</td>
    <td className="text is-light remaining-width left side-margin-0">
      <select data-user={member.id} className="full-width text is-light no-border" onChange={handleChange}>
        {currentRole === 'ADMIN' ?
          <React.Fragment>
            <option selected={member.role === "ADMIN"} value="ADMIN">Admin</option>
            <option selected={member.role === "CASE_MANAGER"} value="CASE_MANAGER">Project manager</option>
            <option selected={member.role === "ANALYST"} value="ANALYST">Analyst</option>
          </React.Fragment> :
          currentRole === 'CASE_MANAGER' && member.role === 'ADMIN' ?
            <React.Fragment>
              <option selected={member.role === "ADMIN"} value="ADMIN">Admin</option>
            </React.Fragment> :
            <React.Fragment>
              <option selected={member.role === "CASE_MANAGER"} value="CASE_MANAGER">Project manager</option>
              <option selected={member.role === "ANALYST"} value="ANALYST">Analyst</option>
            </React.Fragment>}
      </select>
      {member.id in errors ? <p className='text error-message'>{errors[member.id]}</p> : null}
    </td>
    {(currentRole === 'ADMIN' || (currentRole === 'CASE_MANAGER' && member.role !== 'ADMIN')) ? <td className="text flexer-1 right side-margin-0 cursor"><i className="fa fa-trash mr-10 is-light" name={member.user.public_name} email={member.user.email} memberId={member.id} onClick={handleClick}></i></td> : <td className="text flexer-1 right side-margin-0"><i className="fa fa-trash mr-10 is-light hidden"></i></td>}
  </tr>))


  return (
    <div className="dashboard-section">
      <div className="full-width">
        <SearchBar placeholder={"Find a member..."} onChange={handleSearch} />
      </div>
      <table className="table" cellSpacing="0">
        <thead>
          <tr className="table-header flexer mt-15">
            <th className="text is-light flexer-4 left side-margin-0">User</th>
            <th className="text is-light flexer-5 left side-margin-0">Email</th>
            <th className="text is-light remaining-width left side-margin-0">Role</th>
            {(currentRole === 'ADMIN' || currentRole === 'CASE_MANAGER') ? <th className="text is-light flexer-1 left side-margin-0"></th> : null}
          </tr>
        </thead>
        <tbody>
          {members}
        </tbody>
      </table>
      {memberList.length === displayedMembers.length && (<div className="flexer right">
        {prev ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(prev)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
        <p className="subheading is-light mx-10"></p>
        {next ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(next)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
      </div>)}
      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Delete Member</h1>
          <p className="text is-light mx-30">Are you sure that you want to delete the member <br></br><span className="is-dark is-bold">{toDelete.name} - {toDelete.email}</span>?</p>
          <Button text="Delete" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleDelete} />
        </div>
      </Modal>
    </div>
  )
}

export default OrganizationMembers