import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { authenticationService } from '../../api'
import config from '../../api/config'
import { UserContext } from '../../hooks/UserContext'
import Modal from '../Elements/Modal'
import FeedbackForm from '../Footer/FeedbackForm'
// import { attributesToProps } from 'html-react-parser'

function ProfileMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useContext(UserContext)

  if (currentUser.cognito_id) {
    var user_id = '/profile/' + currentUser.cognito_id
  }

  function ToggleProfileMenu(e) {
    var element = document.getElementById('profile-list')
    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden')
      element.classList.add('shown')
    } else {
      element.classList.add('hidden')
      element.classList.remove('shown')
    }
  }

  function handleLogout() {
    authenticationService.logout()
    .then(response => {
        window.location.assign(`${config.home}/login`)
    })
    .catch(error => {
        console.error('Logout failed', error)
    })
  }

  const basicInfo = currentUser.profile
  let profilePic = basicInfo ? basicInfo.profile_pic : null
  profilePic = profilePic ? profilePic : 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

  let name = currentUser ? currentUser.public_name : null

  return (
    <>
      <div className="right cursor center-mobile my-10" onClick={ToggleProfileMenu} >
        <input className="profile-menu__checkbox" type="checkbox" id="profile2" />
        <img className="profile-menu__image" src={profilePic} alt="profile" />
        <span className="profile-menu__name subheading is-dark" >{name}</span>
        <i className="fas fa-angle-down text-icon"></i>

        <ul id="profile-list" className="profile-menu__list hidden subheading is-dark">
          <Link to={user_id}><li className="profile-menu__item cursor">Profile</li></Link>
          <Link to="/organizations"><li className="profile-menu__item cursor">Organizations</li></Link>
          <li className="profile-menu__item cursor" onClick={() => { setIsOpen(true) }}>Leave Feedback</li>
          <li className="profile-menu__item cursor" onClick={handleLogout}>Logout</li>
        </ul>
      </div>

      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <FeedbackForm onClose={() => { setIsOpen(false) }} />
      </Modal>
    </>
  )
}

export default ProfileMenu
