import React from 'react'
import { MultiSteps, PriorityOfProjectForm } from 'components'

function PriorityOfProject(props) {
  const { onNext, onPrevious, onUpdate, data } = props

  return (
    <>
      <h1 className="title-2 is-dark line-margin-0">Project Generation</h1>
      <MultiSteps current={5} />
      <PriorityOfProjectForm
        onNext={onNext}
        onPrevious={onPrevious}
        onUpdate={onUpdate}
        data={data} />
    </>
  )
}

export default PriorityOfProject
