import React, { useState, useEffect, useRef } from 'react'
import { Pagination } from '@mui/material'
import { MenuTabItem, ProjectTable, ColumnsSelector, FiltersSelector } from 'components'
import { GetOrgId } from 'components/Elements'
import { memberService, projectService, projectTypesService, customTable } from 'api'
import 'assets/styles/dashboard.scss'

// Columns that going to display by default in the project table view
const defaultColumns = ['Project ID', 'Project Name', 'Date', 'Analyst']

const CustomDashboard = () => {
  const orgId = GetOrgId()
  const [config, setConfig] = useState({ tableView: true, showConfig: false, columnsId: null, columnList: [], })

  // Handle submit config table view
  const handleSubmit = (e) => {
    e.preventDefault()
    const { columnsId, columnList } = config
    const dataPost = { columns: columnList }

    if (config.columnsId) {
      customTable.update(orgId, columnsId, dataPost)
        .then(res => {
          console.log(res)
        })
    } else {
      customTable.create(orgId, dataPost)
        .then(res => {
          console.log(res)
        })
    }

    setConfig({ ...config, showConfig: false })
  }

  useEffect(() => {
    customTable.get(orgId).then(res => {
      // Set columns by defaults
      let columnList = defaultColumns

      if (res.length && Array.isArray(res[0].columns)) {
        const { id, columns } = res[0]
        const validatedColumns = columns.filter(column => columnList.includes(column))

        if (validatedColumns.length) {
          columnList = validatedColumns
        }

        setConfig({ ...config, columnsId: id, columnList })
      } else {
        setConfig({ ...config, columnList })
      }
    })
  }, [])

  const [paginator, setPaginator] = useState({ showing: 0, limit: 10, offset: 0 })
  const [projects, setProjects] = useState([]);
  const [filtersSelectorClass, setFiltersSelectorClass] = useState('hidden')
  const [loading, setLoading] = useState(false)
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [showResultAnalyst, setShowResultAnalyst] = useState(false)
  const [showResultProject, setShowResultProject] = useState(false)
  const [refresh, setRefresh] = useState(1)
  const [projectTypes, setProjectTypes] = useState(null)
  const [membersSearch, setMembersSearch] = useState([])
  const [projectSearch, setProjectSearch] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedProjects, setSelectedProjects] = useState({})
  const [selectedAnalysts, setSelectedAnalysts] = useState({})
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [resetMultiselect, setResetMultiselect] = useState(false)
  const [activeStatus, setActiveStatus] = useState({ status: 'available', title: 'Available Projects' });

  const multiselectRef = useRef(null)
  if (multiselectRef.current && !isFilterActive) {
    multiselectRef.current.resetSelectedValues()
  }


  // Load projects
  useEffect(() => {
    const analysts = Object.keys(selectedAnalysts)
    const id_project = Object.keys(selectedProjects)
    const selected_types = selectedTypes.map(e => e.id)

    let filters = {
      id: id_project.length ? id_project : null,
      analyst: analysts.length ? analysts : null,
      case_type: selected_types,
      from_date: startDate,
      to_date: endDate
    }

    if (filters.case_type.length === 0) {
      delete filters['case_type']
    }

    if (filters.from_date === null || filters.from_date === '') {
      delete filters['from_date']
    }

    if (filters.to_date === null || filters.to_date === '') {
      delete filters['to_date']
    }

    if (filters.id === null) {
      delete filters['id']
    }

    if (filters.analyst === null) {
      delete filters['analyst']
    }

    projectService.list({ ...filters, type: [activeStatus.status] }, orgId, paginator.offset, paginator.limit)
      .then((res) => {
        setProjects(res)

        let fromValue = paginator.offset + 1
        let toValue = fromValue + res.results.length - 1
        let showing = fromValue === toValue ? `${fromValue}` : `${fromValue} - ${toValue}`

        setPaginator({...paginator, showing: showing})
      })
      .catch(error => {
        console.error('Error loading projects', error)
      })

  }, [activeStatus.status, endDate, orgId, selectedAnalysts, selectedProjects, selectedTypes, startDate, setFiltersSelectorClass, paginator.offset])


  // Load project types
  useEffect(() => {
    projectTypesService.list(orgId)
      .then((response) => {
        setProjectTypes(response)
      })
      .catch((error) => {
        console.error("Unable to get project types", error)
      })
  }, [orgId])


  // Config handles
  const handleView = (value) => {
    setConfig({
      ...config,
      tableView: value
    })
  }

  const handleShowConfig = () => {
    setConfig({
      ...config,
      showConfig: !config.showConfig,
    })
  }

  /* Filters
  ********************** */
  function handleDeleteTypes() {
    setSelectedTypes([])
    setResetMultiselect(true)
  }

  // By project
  function handleProjectSearch(e) {
    if (e.target.value.length >= 2) {
      projectService.search(e.target.value, orgId)
        .then(response => {
          setProjectSearch(response)
        })
        .then(() => {
          setLoadingProjects(false)
          setShowResultProject(true)
          // setIsFilterActive(true)
        })
        .catch(error => {
          console.error(error)
        })
    }
    else {
      setProjectSearch([])
      setLoadingProjects(false)
      setShowResultProject(false)
    }
  }

  function handleClickProject(project_id, project_caseId, subject_name) {
    setSelectedProjects({
      ...selectedProjects,
      [project_id]: [project_caseId, subject_name]
    });
    // setRefresh(refresh + 1)
    setShowResultProject(false)
  }

  function handleDeleteProject(project) {
    let new_project = selectedProjects
    delete new_project[project]
    setSelectedProjects({ ...new_project })
    // setRefresh(refresh + 1)
  }

  // Search by Analyst
  function handleAnalystSearch(e) {
    if (e.target.value.length >= 2) {
      setLoading(true)
      memberService.search(e.target.value, orgId)
        .then(response => {
          setMembersSearch(response)
        })
        .then(() => {
          setLoading(false)
          setShowResultAnalyst(true)
          // setIsFilterActive(true)
        })
        .catch(error => {
          console.error("Analyst not found", error)
        })
    }
    else {
      setMembersSearch([])
      setLoading(false)
      setShowResultAnalyst(false)
    }
  }

  function handleClickAnalyst(analystEmail, analystName) {
    setSelectedAnalysts({
      ...selectedAnalysts,
      [analystEmail]: analystName,
    });
    setRefresh(refresh + 1)
    setShowResultAnalyst(false)
  }

  function handleDeleteAnalyst(analyst) {
    let new_analysts = selectedAnalysts
    delete new_analysts[analyst]
    setSelectedAnalysts({ ...new_analysts })
    setRefresh(refresh + 1)
  }

  // By date
  function handleChangeStart(e) {
    setStartDate(e.target.value)
    // setRefresh(refresh + 1)
    // setIsFilterActive(true)

  }

  function handleChangeEnd(e) {
    setEndDate(e.target.value)
    // setRefresh(refresh + 1)
    // setIsFilterActive(true)
  }

  function handleDeleteDates() {
    setStartDate('')
    setEndDate('')
  }

  // reset filters
  function handleReset() {
    setSelectedProjects({})
    setSelectedAnalysts({})
    setSelectedTypes([])
    setStartDate('')
    setEndDate('')
    setIsFilterActive(false)
    setRefresh(refresh + 1)
  }

  function handleFilters() {
    setIsFilterActive(true)
    setFiltersSelectorClass('hidden')
    setRefresh(refresh + 1)
  }

  function handleCloseFilters() {
    setFiltersSelectorClass('hidden')
    setRefresh(refresh + 1)
  }

  const handlePaginator = (e, page) => {
    e.preventDefault()

    if (page > 1) {
      setPaginator({ ...paginator, offset: paginator.limit * (page - 1)})
    } else {
      setPaginator({ ...paginator, offset: 0, showing: paginator.limit })
    }
  }


  let selected_types = selectedTypes && selectedTypes.map(e => <span className='flexer flexer-vcenter mx-10'>{e.name}</span>)


  let selected_analyst = selectedAnalysts && Object.entries(selectedAnalysts).map((e) => (
    <div className='flexer flexer-vcenter selected-filters mx-20'>
      <p className="text is-light is-bold line-margin-0 mr-10" key={e[0]}> {e[1]} </p>
      <p className='text is-light mr-10 line-margin-0'>{e[0]} <i className='is-light fa fa-close cursor ml-20' onClick={() => handleDeleteAnalyst(e[0])} /> </p>
    </div>
  ))

  let selected_project = selectedProjects && Object.entries(selectedProjects).map((e) => (
    <div className='flexer flexer-vcenter selected-filters mx-20'>
      <p className="text is-light is-bold line-margin-0 mr-10" key={e[0]}> {e[1][0]}</p>
      <p className='text is-light mr-10 line-margin-0'>{e[1][1]} <i className='is-light fa fa-close cursor ml-20' onClick={() => handleDeleteProject(e[0])} /></p>
    </div>
  ))

  let selected_dates = (startDate && endDate) ?
    <div className='flexer flexer-vcenter selected-filters mx-20'>
      <p className='text is-light mx-10 line-margin-0'>{`${startDate.replace('-', '.')} - ${endDate.replace('-', '.')}`} <i className='is-light fa fa-close cursor ml-10' onClick={() => handleDeleteDates()} /></p>
    </div>
    : startDate ?
      <div className='flexer flexer-vcenter selected-filters mx-20'>
        <p className='text is-light mx-10 line-margin-0'>{`from: ${startDate.replace('-', '.')}`} <i className='is-light fa fa-close cursor ml-10' onClick={() => handleDeleteDates()} /></p>
      </div>
      : endDate ?
        <div className='flexer flexer-vcenter selected-filters mx-20'>
          <p className='text is-light mx-10 line-margin-0'>{`to: ${endDate.replace('-', '.')}`} <i className='is-light fa fa-close cursor ml-10' onClick={() => handleDeleteDates()} /></p>
        </div>
        : null


  let show_reset = false
  if (Object.keys(selectedAnalysts).length || Object.keys(selectedProjects).length || selectedTypes.length !== 0 || startDate || endDate) {
    show_reset = true
  }

  return (
    <div className='wcc-dashboard'>
      <div className='wcc-card'>
        <div className='wcc-header'>
          <div className='wcc-menu'>
            <MenuTabItem
              text='Available'
              icon='fa fa-check-square-o'
              active={activeStatus.status === 'available'}
              onClick={() => { setActiveStatus({ status: 'available', title: 'Available Projects' }) }} />

            <MenuTabItem text='Unpublished'
              icon='fa fa-lock'
              active={activeStatus.status === 'unpublished'}
              onClick={() => { setActiveStatus({ status: 'unpublished', title: 'Unpublished Projects' }) }} />

            <MenuTabItem text='Discover'
              icon='fa fa-compass'
              active={activeStatus.status === 'discover'}
              onClick={() => { setActiveStatus({ status: 'discover', title: 'Projects in Discover phase' }) }} />

            <MenuTabItem text='Develop'
              icon='fa fa-refresh'
              active={activeStatus.status === 'develop'}
              onClick={() => { setActiveStatus({ status: 'develop', title: 'Projects in Develop phase' }) }} />

            <MenuTabItem text='In Review'
              icon='fa fa-pencil-square-o'
              active={activeStatus.status === 'in_review'}
              onClick={() => { setActiveStatus({ status: 'in_review', title: 'Projects awaiting review' }) }} />

            <MenuTabItem text='Monitor'
              icon='fa fa-spinner'
              active={activeStatus.status === 'monitoring'}
              onClick={() => { setActiveStatus({ status: 'monitoring', title: 'Projects in Monitoring phase' }) }} />

            <MenuTabItem text='Closed (Reports)'
              icon='fa fa-window-close-o'
              active={activeStatus.status === 'close'}
              onClick={() => { setActiveStatus({ status: 'close', title: 'Reports' }); }} />
          </div>

          <div className='wcc-icons'>
            <span className='wcc-icon'>
              <i className='fa fa-cog is-light' onClick={handleShowConfig} />

              {config.showConfig && (
                <ColumnsSelector
                  handleSubmit={handleSubmit}
                  data={config.columnList}
                  setData={setConfig}
                  columns={defaultColumns}
                />
              )}
            </span>

            <div className='wcc-icon'>
              <i className='fa fa-filter is-light' onClick={() => setFiltersSelectorClass('shown')} />

              <FiltersSelector
                class={filtersSelectorClass}
                handleClose={handleCloseFilters}
                loading={loading}
                loadingProjects={loadingProjects}
                showResultAnalyst={showResultAnalyst}
                showResultProject={showResultProject}
                // handleSelect={handleSelect}
                handleProjectSearch={handleProjectSearch}
                handleClickProject={handleClickProject}
                handleDeleteProject={handleDeleteProject}
                handleAnalystSearch={handleAnalystSearch}
                handleClickAnalyst={handleClickAnalyst}
                handleDeleteAnalyst={handleDeleteAnalyst}
                handleChangeStart={handleChangeStart}
                handleChangeEnd={handleChangeEnd}
                membersSearch={membersSearch}
                projectSearch={projectSearch}
                projectTypes={projectTypes}
                selectedAnalysts={selectedAnalysts}
                selectedProjects={selectedProjects}
                startDate={startDate}
                endDate={endDate}
                isFilterActive={isFilterActive}
                resetMultiselect={resetMultiselect}
                setSelectedTypes={setSelectedTypes}
                setRefresh={setRefresh}
                setIsFilterActive={setIsFilterActive}
                handleFilters={handleFilters}
              />
            </div>
          </div>
        </div>

        <div className='wcc-content'>
          <div className='flexer flexer-vcenter flexer-wrapper mb-20'>
            {isFilterActive && <p className='text is-dark is-bold line-margin-0 mr-20'>Selected filters:</p>}

            {isFilterActive && selectedTypes.length && (
              <div className=' selected-filters mx-20'>
                <p className='flexer flexer-vcenter text is-light is-bold line-margin-0 mx-10'>
                  {selected_types}
                  <i className='is-light fa fa-close cursor ml-20' onClick={() => handleDeleteTypes()}></i></p>
              </div>
            )}

            {isFilterActive && selected_analyst}
            {isFilterActive && selected_project}
            {isFilterActive && selected_dates}
            {isFilterActive && <div className='reset-selected-filters mx-20 cursor' onClick={(e) => handleReset(e)}><p className="text is-light is-orange line-margin-0 mr-20"><i className='is-orange fa fa-trash cursor mx-10' />Clear all filters</p></div>}
          </div>

          <div className='wcc-title flexer flexer-spaced'>
            <h2 className='title-3 line-margin-0 is-bold is-light'>{activeStatus.title}</h2>

            <div className="wcc-icons">
              <div className={!config.tableView ? 'wcc-icon wcc-active' : 'wcc-icon'}
                onClick={() => handleView(false)}>
                <i className='fa fa-th-large is-light' />
              </div>

              <div className={config.tableView ? 'wcc-icon wcc-active' : 'wcc-icon'}
                onClick={() => handleView(true)}>
                <i className='fa fa-bars is-light' />
              </div>
            </div>
          </div>

          {projects.results?.length ?
            <>
              <ProjectTable
                tableView={config.tableView}
                projects={projects?.results}
                to={activeStatus.status}
                columnsList={config.columnList}
                defaultColums={defaultColumns}
                custom={true} />

              <div className="wcc-pagination">
                <div className="wcc-info">
                  Showing {paginator.showing} of {projects.count}
                </div>

                <Pagination
                  count={Math.ceil(projects.count / paginator.limit)}
                  onChange={handlePaginator}
                  shape="rounded"
                  size="small"
                />
              </div>
            </>

            : <div className="wcc-nodata"> No projects found </div>
          }
        </div>
      </div>
    </div>
  )
}

export default CustomDashboard;
