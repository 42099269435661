import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import UserResourcesContent from '../containers/Content/UserResourcesContent'

function UserResources() {
    return (
        <React.Fragment>
            <Header />
            <Content>
                <UserResourcesContent />
            </Content>
        </React.Fragment>
    )
}

export default UserResources