import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from 'hooks/UserContext'
import { Button, GetOrgId, Modal } from 'components/Elements'
import { resourceService } from 'api'


function UserResourcesContent() {
  const { currentOrganizations } = useContext(UserContext) // global context

  const orgId = GetOrgId()
  const orgMemberInfo = currentOrganizations
  const currentRole = orgMemberInfo[orgId].role

  const [files, setFiles] = useState([])
  const [toDelete, setToDelete] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [refresh, setRefresh] = useState(0)

  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)

  useEffect(() => {
    resourceService.list(orgId)
      .then(response => {
        setFiles(response.results)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch(error => {
        console.log(error)
      })
  }, [refresh, orgId])


  let table_rows = files && files.map(file => {
    let filename = file.file.split('/').pop()
    return (
      <tr className="table-rows">
        <td className="text is-dark left">{filename}</td>
        {
        (currentRole === "ADMIN" || currentRole === "CASE_MANAGER") &&
          <td className="text right side-margin-0 cursor">
            <i className="fa fa-trash mr-10 is-light" onClick={() => handleClick(file.id, filename)}></i>
          </td>
        }
        <td className="center"><a href={file.file} target="_blank" rel="noreferrer"><i className="is-light fas fa-download" /></a></td>
      </tr>
    )
  })

  const handleClose = () => {
    setIsOpen(false)
    setToDelete({})
  }

  function handleClick(resource_id, resource_name) {
    setIsOpen(true)
    setToDelete({ id: resource_id, name: resource_name })
  }

  function handleDelete() {
    resourceService.remove(orgId, toDelete.id)
      .then(response => {
        let items = files
        setFiles(items)
        setIsOpen(false)
        setToDelete({})
        setRefresh(refresh +1)
      })
  }

  function handleFileLoader() {
    var element = document.getElementById("file-loader")
    element.click()
  }

  function handleSubmit(e) {
    e.preventDefault()

    var form_element = document.getElementById('form')
    var form_data = new FormData(form_element)

    resourceService.create(form_data, orgId)
      .then(response => {
        setFiles([...files, response])
      })
      .catch(error => {
        console.log("Error uploading file", error)
      })
  }

  function handlePagination(url) {
    resourceService.listByUrl(url)
      .then(response => {
        setFiles(response.results)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch(error => {
        console.log("Error loading wizards", error)
      })
  }

  return (
    <div className="dashboard-section">
      <div className="flexer flexer-spaced">
        <h1 className="title is-dark">User Resources</h1>
        <form id="form">
          <input id="file-loader" name="file" type="file" className="file-input" onChange={handleSubmit} />
          <Button type="button" text="Add Resource" class="black-btn" icon="fas fa-plus" onClick={handleFileLoader} />
        </form>
      </div>
      <table className="table" cellSpacing="0">
        <thead>
          <tr className="table-header">
            <th className="text is-light left">File Name</th>
            {(currentRole === "CASE_MANAGER" || currentRole === "ADMIN") ? <th className="text is-light flexer-1 left side-margin-0"></th> : null}
            <th ></th>
          </tr>
        </thead>
        <tbody>
          {table_rows}
        </tbody>
      </table>
      <div className="flexer right mx-10">
        {prev ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(prev)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
        <p className="subheading is-light mx-10"></p>
        {next ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(next)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
      </div>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        <div className="center">
          <h1 className="title is-dark mb-30">Delete File</h1>
          <p className="text is-light mx-30">Are you sure that you want to delete the file  <br></br> <span className="is-dark is-bold">{toDelete.name}</span>?</p>
          <Button text="Delete" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleDelete} />
        </div>
      </Modal>
    </div>

  )
}

export default UserResourcesContent
