import React from 'react'

// Components
import SingleStep from './SingleStep'

function MultiStep(props) {
  return (
    <div className="flexer wcc-steps">
      <SingleStep step="1" text="Start point" current={props.current} last={false} />
      <SingleStep step="2" text="Additional information" current={props.current} last={false} />
      <SingleStep step="3" text="Customer" current={props.current} last={false} />
      <SingleStep step="4" text="Upload attachments" current={props.current} last={false} />
      <SingleStep step="5" text="Priority of project" current={props.current} last={true} />
    </div>
  )
}

export default MultiStep
