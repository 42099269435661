const config = {
  apiUrl: process.env.REACT_APP_API_URI ?? 'https://api.torch.eag.echoanalyticsgroup.com/api/v3',
  WebSocketURL: process.env.REACT_APP_WEBSOCKET_URL ?? 'wss://api.torch.eag.echoanalyticsgroup.com/ws/chat/',
  mediaUri: process.env.REACT_APP_MEDIA_URI ?? 'https://321482356601-echo-files.s3.amazonaws.com/media',
  home: process.env.REACT_APP_HOME_URI ?? 'https://home.eag.echoanalyticsgroup.com',
  cid: process.env.REACT_APP_CID_URI ?? 'https://cid.eag.echoanalyticsgroup.com',
  torch: process.env.REACT_APP_TORCH_URI ?? 'https://torch.eag.echoanalyticsgroup.com',
}

export default config
