import React from 'react'

// Containers
import Header from '../containers/Header'
import ProjectContent from '../containers/Content/ProjectContent'

function Project(props) {
    return (
        <React.Fragment>
            <div className="max-screen">
                <Header />
                <ProjectContent first={props.location.state ? props.location.state.first : null} />
            </div>
        </React.Fragment>
    )
}

export default Project