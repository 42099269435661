/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const linkService = {
  create,
  update,
}

async function create(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/links/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function update(body, org_id, link_id) {
  body = fixBody(body)
  // const requestOptions = { method: 'PATCH', body }
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/links/${link_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
