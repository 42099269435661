import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import OrganizationProfileContent from '../containers/Content/OrganizationProfileContent'

function OrganizationSettings() {
    return (
        <React.Fragment>
            <Header />
            <Content>
                <OrganizationProfileContent />
            </Content>
        </React.Fragment>
    )
}

export default OrganizationSettings