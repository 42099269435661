import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { applicationService } from '../../api'
import Modal from '../Elements/Modal'
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'
import GetOrgId from '../Elements/GetOrgId'
import ApplicationForm from './ApplicationForm'


function PendingVerification(props) {
  // chequear
  const [applicationList, setApplicationList] = useState([])
  const [toReject, setToReject] = useState({})
  const [toAccept, setToAccept] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenReject, setIsOpenReject] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [prev, setPrev] = useState(null)
  const [next, setNext] = useState(null)
  const orgId = GetOrgId()


  useEffect(() => {
    applicationService.list(orgId)
      .then((response) => {
        setApplicationList(response.results)
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch((error) => {
        console.error("Unable to get pending verification list", error)
      })
  }, [orgId, refresh])


  function handlePagination(url) {
    applicationService.listByUrl(url)
      .then((response) => {
        setApplicationList(response['results'])
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch(error => {
        console.log("Error loading users", error)
      })
  }

  function handleClickReject(orgId, appId, name) {
    setIsOpenReject(true)
    setToReject({ org: orgId, id: appId, name: name })
  }

  function handleReject() {
    applicationService.reject(toReject.org, toReject.id)
      .then(() => {
        let user = applicationList
        delete user[toReject.id]
        setApplicationList(user)
      })
      .then(() => {
        setIsOpenReject(false)
        setRefresh(refresh + 1)
      })
      .catch(error => {
        console.error("Unable to delete application", error)
      })
  }

  // Accept
  function handleClickAccept(orgId, appId, name) {
    setIsOpen(true)
    setToAccept({ org: orgId, id: appId, name: name })
  }

  function closeModal() {
    setIsOpenReject(false)
    setIsOpen(false)
    setRefresh(refresh + 1)
  }

  function handleSearch(e) {
    let results = applicationList.filter(user =>
      user.issued_by.public_name.toLowerCase().includes(e.target.value.toLowerCase()))
    setSearchResults(results)
  }


  let applicants
  searchResults.length !== 0
    ?
    applicants = searchResults.map(applicant => (<tr className="table-rows flexer">
      <td className="text is-light flexer-4 left side-margin-0 mt-10"><Link to={`/profile/${applicant.issued_by.cognito_id}/${orgId}`} className="text is-light">{applicant.issued_by.public_name}</Link></td>
      <td className="text is-light flexer-4 left side-margin-0 mt-10">{applicant.email}</td>
      <td className="text flexer-2 center side-margin-0 cursor">
        <Button type="button" text={'Accept'} class={"orange-btn"} onClick={() => handleClickAccept(applicant.organization.id, applicant.id, applicant.issued_by.public_name)} /></td>
      <td className="text remaining-width right side-margin-0 cursor">
        <Button type="button" text={'Reject'} class={"white-btn "} onClick={() => handleClickReject(applicant.organization.id, applicant.id, applicant.issued_by.public_name)} /></td>
    </tr>))
    :
    applicants = applicationList
      .map(applicant => (<tr className="table-rows flexer">
        <td className="text is-light flexer-4 left side-margin-0 mt-10"><Link to={`/profile/${applicant.issued_by.cognito_id}/${orgId}`} className="text is-light">{applicant.issued_by.public_name}</Link></td>
        <td className="text is-light flexer-4 left side-margin-0 mt-10">{applicant.email}</td>
        <td className="text flexer-2 center side-margin-0 cursor">
          <Button type="button" text={'Accept'} class={"orange-btn"} onClick={() => handleClickAccept(applicant.organization.id, applicant.id, applicant.issued_by.public_name)} /></td>
        <td className="text remaining-width right side-margin-0 cursor">
          <Button type="button" text={'Reject'} class={"white-btn "} onClick={() => handleClickReject(applicant.organization.id, applicant.id, applicant.issued_by.public_name)} /></td>
      </tr>))

  return (
    <div className="dashboard-section">
      <div className="full-width">
        <SearchBar placeholder={"Find a user..."} onChange={(e) => handleSearch(e)} />
      </div>

      <table className="table" cellSpacing="0">
        <thead>
          <tr className="table-header flexer mt-15">
            <th className="text is-light flexer-4 left side-margin-0">Name</th>
            <th className="text is-light flexer-4 left side-margin-0">Email</th>
            <th className="text is-light remaining-width left side-margin-0"></th>
          </tr>
        </thead>
        <tbody>
          {applicants}
        </tbody>
      </table>

      <div className="flexer center">
        {prev ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(prev)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
        <p className="subheading is-light mx-10"></p>
        {next ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(next)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
      </div>

      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <ApplicationForm org={toAccept.org} id={toAccept.id} name={toAccept.name} onClose={closeModal} />
      </Modal>

      <Modal isOpen={isOpenReject} handleClose={() => { setIsOpenReject(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Reject Membership Request</h1>
          <p className="text is-light mx-30">Are you sure that you want to reject the membership request from <br></br> <span className="is-dark is-bold">{toReject.name}</span>?</p>
          <Button text="Confirm" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={handleReject} />
        </div>
      </Modal>
    </div>
  )
}

export default PendingVerification
