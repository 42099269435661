import React, { useState, useEffect, useRef } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'


// Components
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'

const FiltersSelector = (props) => {

  const [selectedFilters, setSelectedFilters] = useState({
    types: [],
    analysts: [],
    projects: [],
    start: '',
    end: '',
  })
  const [filterClass, setFilterClass] = useState('hidden')
  const multiselectRef = useRef(null)

  // if (multiselectRef.current && (props.resetMultiselect || !props.isFilterActive)) {
  //   multiselectRef.current.resetSelectedValues()
  // }


  useEffect(() => {
    if (multiselectRef.current && (props.resetMultiselect || !props.isFilterActive)) {
      multiselectRef.current.resetSelectedValues()
    }

    if (props.projectSearch) {
      setSelectedFilters({ ...selectedFilters, projects: props.projectSearch })
    }

    if (props.membersSearch) {
      setSelectedFilters({ ...selectedFilters, analysts: props.membersSearch })
    }

    if (props.class) {
      setFilterClass(props.class)
    }

  }, [props.selectedFilters, props.class, props.membersSearch, props.projectSearch, props.isFilterActive, props.resetMultiselect])

  function handleSelect(selected) {
    let obj = {}
    let type = selected.map(e => obj[e.id] = e)
    props.setSelectedTypes(type)
    // props.setRefresh(props.refresh + 1)
    // props.setIsFilterActive(true)
  }


  let analysts = selectedFilters && selectedFilters.analysts.map(e =>
    <div className="flexer flexer-vcenter simple-profile__sections" onClick={() => props.handleClickAnalyst(e.user.email, e.user.public_name)} key={`analyst_${e.id}`}>
      <p className="heading is-light line-margin-0 ml-20 cursor">{e.user.public_name} <span className='text is-light line-margin-0'>{e.user.email} </span> </p>
    </div>
  )

  // let projects_  = selectedFilters && selectedFilters.projects.map(e => 
  let projects_ = props.projectSearch && props.projectSearch.map(e =>
    <div className="flexer flexer-vcenter simple-profile__sections" onClick={() => props.handleClickProject(e.id, e.case_id, e.subject.display_name)} key={`project_${e.id}`}>
      <p className="heading is-light line-margin-0 ml-20 cursor">{`${e.case_id}`} <span className='text is-light line-margin-0'>{e.subject.display_name} </span></p>
    </div>
  )

  return (
    <>
      <div className={`columns-selector ${filterClass}`}>
        <div className='my-20 mx-10'>
          <div className='flexer flexer-spaced mt-10'>
            <p className='title-4 left'>Filters</p>
            {props.handleClose ? <i className="fa fa-close cursor is-light mt-10" onClick={props.handleClose}></i> : null}
          </div>
          <div className='my-10'>
            <p className='text is-light left'>Project Types</p>
            <div className='filters-multi'>
              <Multiselect
                options={props.projectTypes ? props.projectTypes : []}
                displayValue="name"
                showCheckbox={true}
                avoidHighlightFirstOption={true}
                placeholder="Select projects types"
                hidePlaceholder={true}
                name="selected_types"
                onSelect={handleSelect}
                onRemove={handleSelect}
                ref={props.multiselectRef}
                isFilterActive={props.isFilterActive}
              />
            </div>
          </div>

          <div className='my-20'>
            <p className='text is-light left'>By Analyst</p>
            <SearchBar onChange={props.handleAnalystSearch} placeholder='Enter a name...' />
            {props.showResultAnalyst && (
              <div className="max-screen__bottom shown" id='analyst'>
                {props.loading ? "Loading analysts..." : analysts}
              </div>
            )}

            <p className='text  is-light left'>By Project</p>
            <SearchBar onChange={props.handleProjectSearch} placeholder='Enter a Project ID...' />
            {props.showResultProject && (
              <div className="max-screen__bottom" id='project'>
                {projects_}
              </div>
            )}
          </div>

          <div className='my-20'>
            <p className='text  is-light left'>By Date</p>
            <div className='input-field__filters flexer flexer-vcenter'>
              <label className="input-field__label flexer-4 left" >From:</label>
              <input className="input-field__input" value={props.startDate} type='date' name='start' label='From' onChange={props.handleChangeStart} />
            </div>
            <div className='input-field__filters flexer flexer-vcenter'>
              <label className="input-field__label left flexer-4 mt-10" >To:</label>
              <input className="input-field__input" value={props.endDate} type='date' name='end' label='To' onChange={props.handleChangeEnd} />
            </div>
          </div>
          <Button text='Apply filters' class="orange-btn my-10 subheading is-white full-width" onClick={() => props.handleFilters()} />
        </div>
      </div>
    </>
  )
}

FiltersSelector.defaultProps = {
  startDate: '',
  endDate: ''
}

export default FiltersSelector
