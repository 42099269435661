import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'

// Components
import Button from '../Elements/Button'
import Modal from '../Elements/Modal'
import ConvertProject from '../../containers/ConvertProject'
import CloseProjectForm from './Forms/CloseProjectForm'
import GetOrgId from '../Elements/GetOrgId'
import config from '../../api/config'
import { projectService } from '../../api'


// Hooks
import { UserContext } from '../../hooks/UserContext'

function ProjectInfoSection(props) {
  const [status, setStatus] = useState(null)
  const [statusState, setStatusState] = useState(null)
  const [priority, setPriority] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenConvert, setIsOpenConvert] = useState(false)
  const [isOpenCloseProject, setIsOpenCloseProject] = useState(false)
  const [closedReason, setClosedReason] = useState(null)
  const { currentOrganizations } = useContext(UserContext)  // global context


  const orgId = GetOrgId()
  const orgMemberInfo = currentOrganizations
  const currentRole = orgMemberInfo[orgId].role

  if (props.project.status === "CLOSE") {
    if (!closedReason) {
      // setClosedReason(props.project.close_other_reason ? props.project.close_other_reason : props.project.proper_close_options)
      setClosedReason(props.project.close_options !== 'OTHER' ?  props.project.proper_close_options : props.project.close_other_reason )
    }
  }

  function TogglePriorityMenu(e) {
    var element = document.getElementById('priority-list')
    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden')
      element.classList.add('shown')
    } else {
      element.classList.add('hidden')
      element.classList.remove('shown')
    }
  }

  function ToggleStatusMenu(e) {
    var element = document.getElementById('status-list')
    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden')
      element.classList.add('shown')
    } else {
      element.classList.add('hidden')
      element.classList.remove('shown')
    }
  }

  function updateProject(field, e) {
    var form_data = new FormData()
    form_data.append(field, e)
    projectService.update(form_data, orgId, props.project.id)
      .catch(error => {
        console.log("It was not possible to update status", error)
      })
  }

  function handleConvert() {
    setIsOpenConvert(true)
    setStatus("Unpublished")
  }

  function changeStatus(e) {
    setStatusState(e.target.textContent)
    updateProject('status', e.target.getAttribute('value'))
    setIsOpen(true)
    setStatus(e.target.textContent)
  }

  function handleReview() {
    setStatusState("In review")
    updateProject('status', "IN_REVIEW")
    setStatus("In review")
  }

  function handleCloseProject() {
    setIsOpenCloseProject(true)
  }

  function handleUpdateClose(reason) {
    setStatusState("Closed")
    setIsOpen(true)
    setStatus("Closed")
    setIsOpenCloseProject(false)
    setClosedReason(reason)
  }

  if (redirect) {
    return redirect
  }

  function changePriority(e) {
    setPriority(e.target.getAttribute('value'))
    updateProject('priority', e)
  }

  function handleDownloadReport(e) {
    projectService.generate_report(orgId, props.project.id)
      .then(response => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = `report_${props.project_id}.docx`
        link.href = `${config.mediaUri}/${response.report}`
        link.click();
      })
      .catch(error => {
        console.error("It was not possible to generate report", error)
      })
  }

  function closeAndRefresh() {
    setIsOpen(false)
    setRedirect(<Redirect to={{ pathname: `/organization/${orgId}/chats`, state: { status: statusState, project_id: props.project.case_id, id: props.project.id } }}></Redirect>)
  }

  var finalStatus = status ? status : props.project.proper_status
  var finalPriority = priority ? priority : props.project.priority

  var priorityOptions
  if (props.closed || currentRole === "ANALYST") {
    priorityOptions = <React.Fragment />
  } else {
    priorityOptions = <React.Fragment>
      <i className="fas fa-angle-down text-icon mx-5"></i>
      <ul id="priority-list" className="profile-menu__list hidden subheading is-dark right">
        <li className="profile-menu__item cursor" value="high" onClick={changePriority}>High Priority</li>
        <li className="profile-menu__item cursor" value="standard" onClick={changePriority}>Standard Priority</li>
      </ul>
    </React.Fragment>
  }

  var priorityDrop = null
  if (finalStatus !== "Temporary" && finalStatus !== "Closed") {
    priorityDrop = <div className={`line-margin-0 mr-20 relative ${props.closed || currentRole === "ANALYST" ? null : "cursor"} select-special select-red`} onClick={props.closed || currentRole === "ANALYST" ? null : TogglePriorityMenu} >
      <span className="profile-menu__name subheading is-dark">{finalPriority === "high" ? "High Priority" : "Standard Priority"}</span>
      {priorityOptions}
    </div>
  }

  var statusOptions
  var allowToggle
  var allowCursor = null
  var reviewProject
  if (finalStatus === "Unpublished" && (currentRole === "CASE_MANAGER" || currentRole === "ADMIN")) {
    allowToggle = ToggleStatusMenu
    allowCursor = "cursor"
    statusOptions = <React.Fragment><i className="fas fa-angle-down text-icon mx-5"></i>
      <ul id="status-list" className="profile-menu__list hidden subheading is-dark right">
        <li className="profile-menu__item cursor" value="AVAILABLE" onClick={changeStatus}>Available</li>
        <li className="profile-menu__item cursor" value="CLOSE" onClick={handleCloseProject}>Closed</li>
      </ul></React.Fragment>
  } else if (finalStatus === "Available" && (currentRole === "CASE_MANAGER" || currentRole === "ADMIN")) {
    allowToggle = ToggleStatusMenu
    allowCursor = "cursor"
    statusOptions = <React.Fragment><i className="fas fa-angle-down text-icon mx-5"></i>
      <ul id="status-list" className="profile-menu__list hidden subheading is-dark right">
        <li className="profile-menu__item cursor" value="DISCOVER" onClick={changeStatus}>Discover</li>
        <li className="profile-menu__item cursor" value="CLOSE" onClick={handleCloseProject}>Closed</li>
      </ul></React.Fragment>
  } else if (finalStatus === "Develop" || finalStatus === "Discovery" || finalStatus === "Monitoring") {
    reviewProject = <Button class="orange-btn" text="Submit for Review" type="button" icon="fa fa-search" onClick={handleReview} />
    if (currentRole === "CASE_MANAGER" || currentRole === "ADMIN") {
      allowToggle = ToggleStatusMenu
      allowCursor = "cursor"
      statusOptions = <React.Fragment><i className="fas fa-angle-down text-icon mx-5"></i>
        <ul id="status-list" className="profile-menu__list hidden subheading is-dark right">
          <li className="profile-menu__item cursor" value="CLOSE" onClick={handleCloseProject}>Closed</li>
        </ul></React.Fragment>
    }
  } else if (finalStatus === "In review" && (currentRole === "CASE_MANAGER" || currentRole === "ADMIN")) {
    allowToggle = ToggleStatusMenu
    allowCursor = "cursor"
    statusOptions = <React.Fragment><i className="fas fa-angle-down text-icon mx-5"></i>
      <ul id="status-list" className="profile-menu__list hidden subheading is-dark right">
        <li className="profile-menu__item cursor" value="DEVELOP" onClick={changeStatus}>Develop</li>
        <li className="profile-menu__item cursor" value="MONITORING" onClick={changeStatus}>Monitoring</li>
        <li className="profile-menu__item cursor" value="CLOSE" onClick={handleCloseProject}>Closed</li>
      </ul></React.Fragment>
  } else if (finalStatus === "Closed" && (currentRole === "CASE_MANAGER" || currentRole === "ADMIN")) {
    allowToggle = ToggleStatusMenu
    allowCursor = "cursor"
    statusOptions = <React.Fragment><i className="fas fa-angle-down text-icon mx-5"></i>
      <ul id="status-list" className="profile-menu__list hidden subheading is-dark right">
        <li className="profile-menu__item cursor" value="DEVELOP" onClick={changeStatus}>Develop</li>
        <li className="profile-menu__item cursor" value="MONITORING" onClick={changeStatus}>Monitoring</li>
      </ul></React.Fragment>
  }

  var convert
  if (finalStatus === "Temporary" && (currentRole === "CASE_MANAGER" || currentRole === "ADMIN")) {
    convert = <Button class="orange-btn" text="Convert to project" type="button" icon="fas fa-share" onClick={handleConvert} />
  }

  return (
    <React.Fragment>
      <div className="flexer flexer-spaced mx-30 my-30">
        <div className="inline">
          <div className="flexer flexer-vcenter">
            {finalStatus === "Temporary" ? "" : <h1 className="title is-dark line-margin-0 mr-10">{props.project.case_id}</h1>}

            {priorityDrop}

            <div className={`line-margin-0 relative ${allowCursor} select-special select-orange`} onClick={allowToggle} >
              {/* <span className="profile-menu__name subheading is-dark">{finalStatus}</span> */}
              <span className="profile-menu__name subheading is-dark">{finalStatus === 'Discovery' ? 'Discover' : finalStatus}</span>
              {statusOptions}
            </div>
          </div>

          {finalStatus === "Closed" ? <p className="text is-dark line-margin-0">{`Close reason: ${closedReason}`}</p> : null}

          <div className="flexer flexer-vcenter mt-10">
            <p className="text is-dark line-margin-0">Start date: <span className="text is-dark is-bold">{props.project.proper_start_date}</span></p>
            <span className="text is-light mx-15">•</span>
            <p className="text is-dark line-margin-0">Stop date: <span className="text is-dark is-bold">{props.project.proper_stop_date ? props.project.proper_stop_date : "-"}</span></p>
          </div>
        </div>

        {props.closed && (currentRole === "CASE_MANAGER" || currentRole === "ADMIN") ? <Button class="orange-btn" text="Generate customer report" type="button" icon="far fa-file-alt" onClick={handleDownloadReport} /> : null}
        {reviewProject}
        {convert}
      </div>

      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Status Changed</h1>
          <p className="text is-dark mx-30">Send a broadcast message to let everyone know.</p>
          <Button text="OK" class="mt-30 one-third-width orange-btn subheading is-light" type="button" onClick={closeAndRefresh} />
        </div>
      </Modal>

      <Modal isOpen={isOpenCloseProject} handleClose={() => { setIsOpenCloseProject(false) }}>
        <CloseProjectForm onClose={() => { setIsOpenCloseProject(false) }} project={props.project.id} onSubmit={handleUpdateClose} />
      </Modal>

      <ConvertProject isOpen={isOpenConvert} onCancel={() => { setIsOpenConvert(false) }} onClose={() => { setIsOpenConvert(false); props.onClose(); props.onChange(true) }} />
    </React.Fragment>
  )
}

export default ProjectInfoSection
