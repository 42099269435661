import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

// Containers
import StartPoint from './CreateProject/StartPoint'
import AdditionalInformation from './CreateProject/AdditionalInformation'
import Customer from './CreateProject/Customer'
import PriorityOfProject from './CreateProject/PriorityOfProject'
import UploadAttachments from './CreateProject/UploadAttachments'

// Components
import { Modal, GetOrgId } from'components/Elements'
// import Modal from '../components/Elements/Modal'
// import GetOrgId from '../components/Elements/GetOrgId'

function CreateProject(props) {
  const [stage, setStage] = useState("start")
  const [data, setData] = useState({})

  const orgId = GetOrgId()

  function changeData(new_data) {
    setData({ ...data, ...new_data })
  }

  var stageComponent
  if (stage === "start") {
    stageComponent = <StartPoint
      onNext={() => setStage("additionalInfo")}
      onUpdate={changeData}
      data={data} />
  }
  else if (stage === "additionalInfo") {
    stageComponent = <AdditionalInformation
      onNext={() => setStage("customer")}
      onPrevious={() => setStage("start")}
      onUpdate={changeData}
      data={data} />
  }
  else if (stage === "customer") {
    stageComponent = <Customer
      onNext={() => setStage("uploadAttachments")}
      onPrevious={() => setStage("additionalInfo")}
      onUpdate={changeData}
      data={data} />
  }
  else if (stage === "uploadAttachments") {
    stageComponent = <UploadAttachments
      onNext={() => setStage("priorityOfProject")}
      onPrevious={() => setStage("customer")}
      onUpdate={changeData}
      data={data} />
  }
  else if (stage === "priorityOfProject") {
    stageComponent = <PriorityOfProject
      onNext={() => setStage("completed")}
      onPrevious={() => setStage("uploadAttachments")}
      onUpdate={changeData}
      data={data} />
  }

  else if (stage === "completed") {
    return <Redirect to={{ pathname: `/organization/${orgId}/project/${data.case}`, state: { first: true }, key: Math.random() }}></Redirect>
  }

  return (
    <Modal isOpen={props.isOpen} fullScreen={true} handleClose={props.handleClose}>
      {stageComponent}
    </Modal>
  )
}

export default CreateProject