import React from 'react'

// Containers
import Header from '../containers/Header'
import AnalyticsContent from '../containers/Content/AnalyticsContent'

function Analytics() {
    return (
        <React.Fragment>
            <Header />
            <AnalyticsContent />
        </React.Fragment>
    )
}

export default Analytics