import React, { useEffect, useState } from 'react'

// Components
import InputField from '../Elements/InputField'
import Textarea from '../Elements/Textarea'
import { Multiselect } from 'multiselect-react-dropdown'
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'
import GetOrgId from '../Elements/GetOrgId'

// API
import { chatService, projectService } from '../../api'

function CreateBroadcast(props) {
  const [linkedSubject, setLinkedSubject] = useState(null)
  const [title, setTitle] = useState("")
  const [options, setOptions] = useState([])
  const [searchResults, setSearchResults] = useState(null)
  const [Project, setProject] = useState(null)

  const orgId = GetOrgId()

  useEffect(() => {
    if (props.statusChange) {
      // var statusChangeTitle = `Project ${props.statusChange.case_id}: Status updated to "${props.statusChange.status}"`
      var statusChangeTitle = `Project ${props.statusChange.project_id}: Status updated to "${props.statusChange.status}"`
      setTitle(statusChangeTitle)
      setProject(props.statusChange.id)
    }
  }, [props.statusChange])

  useEffect(() => {
    chatService.get_group_channels(orgId)
      .then(response => {
        setOptions(response)
        for (const option of response) {
          if (option.name === "General") {
            setLinkedSubject(option.id)
            break
          }
        }
      })
      .catch(error => {
        console.error("Unable to retrieve group list", error)
      })
  }, [orgId])

  function handleSendMessage(e) {
    e.preventDefault()

    var form_element = document.getElementById('create-broadcast-form')
    var form_data = new FormData(form_element)

    chatService.send_broadcast_message(form_data, orgId)
      .then(response => {
        props.onSubmit(response)
      })
      .catch(error => {
        console.error("It was not possible to create the broadcast message", error)
      })
  }

  function handleDeleteMessage(e) {
    e.preventDefault()

    chatService.delete_broadcast(props.message.id, orgId)
      .then(response => {
        props.onDelete(props.message.id)
      })
      .catch(error => {
        console.error("It was not possile to delete the broadcast message", error)
      })
  }

  function handleSearchProject(e) {
    function selectProject(project_id, project_name) {
      setProject(project_id)
      setSearchResults([])
      e.target.value = project_name
    }

    var results = []
    if (e.target.value.length > 0) {
      projectService.search(e.target.value, orgId)
        .then(response => {
          for (const r of response) {
            results.push(
              <div className="search-results cursor" onClick={() => { selectProject(r.id, `${r.case_id} (${r.subject.display_name})`) }}>
                <div className="flexer">
                  <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
                  <p className="text is-light line-margin-0">{`${r.case_id} (${r.subject.display_name})`}</p>
                </div>
              </div>
            )
          }
          setSearchResults(results)
        })
        .catch(error => {
          console.error(error)
          setSearchResults([])
        })
    } else {
      setSearchResults([])
    }
  }

  function handleSelect(selected) {
    let type = selected.map(e => e.id)
    setLinkedSubject(type)
  }

  var projectDiv = null
  if (!props.statusChange) {
    projectDiv =
      <div className="simple-profile__sections chats-multi">
        <SearchBar placeholder={"Find a project..."} onChange={handleSearchProject} noBg={true} />
        <div className="absolute full-width on-top">
          {searchResults}
        </div>
      </div>
  }

  if (props.message) {
    return (
      <React.Fragment>
        <div className="max-screen__bottom chats-max-screen">
          <div className="simple-profile__sections flexer flexer-spaced flexer-vcenter">
            <div className="flexer flexer-vcenter">
              <p className="heading is-dark line-margin-xs mr-10">#</p>
              <p className="heading is-dark line-margin-0">{props.message.title}</p>
            </div>
            <Button type="button" class="white-btn" text="Delete message" onClick={handleDeleteMessage} />
          </div>
          <div className="simple-profile__sections">
            <p class="text is-dark left line-margin-0 is-dark">Title: <span className="text is-dark">{props.message.title ? props.message.title : ''}</span></p>
          </div>
          <div className="simple-profile__sections chats-multi">
            {/* <Multiselect options={options} displayValue="name" selectionLimit={1} selectedValues={options} onSelect={(selected) => { setLinkedSubject(selected[0]['id']) }} /> */}
            <Multiselect options={options} displayValue="name" selectedValues={options} onSelect={handleSelect} />
          </div>
          {props.message.case ? <div className="simple-profile__sections"><p class="text left line-margin-0 is-dark">Project: <span className="text is-dark"> {props.message.case} </span></p> </div> : null}
          <div className="simple-profile__sections">
            <p class="text left line-margin-0 is-dark">Expiration time: <span className="text is-dark">{props.message.expiration_days ? `${props.message.expiration_days} days` : ''}</span></p>
          </div>
          <div className="simple-profile__sections max-screen__bottom">
            <p class="text left line-margin-0 is-dark">Message: <span className="text is-dark">{props.message.message ? props.message.message : ''}</span></p>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <form id="create-broadcast-form" className="max-screen__bottom chats-max-screen" onSubmit={handleSendMessage}>
          <div className="simple-profile__sections flexer flexer-spaced flexer-vcenter">
            <p className="heading is-dark line-margin-0">Create Broadcast message</p>
            <Button type="submit" class="orange-btn" text="Create broadcast" />
          </div>
          <div className="simple-profile__sections">
            <InputField placeholder="Message title..." class="chats-input" name="title" value={title} />
          </div>
          <div className="simple-profile__sections chats-multi">
            {/* <Multiselect options={options} displayValue="name" selectionLimit={1} selectedValues={options} onSelect={(selected) => { console.log(selected); setLinkedSubject(selected[0]['id']) }} /> */}
            <Multiselect options={options} displayValue="name" selectionLimit={1} onSelect={handleSelect} />
            <input type="hidden" name="channel" value={linkedSubject} />
          </div>
          {projectDiv}
          {Project ? <input id="case-input" name="case" value={Project} type="hidden" /> : null}
          <div className="simple-profile__sections">
            <InputField type="number" placeholder="Enter an expiration time in days..." class="chats-input" name="expiration_days" />
          </div>
          <div className="simple-profile__sections max-screen__bottom">
            <Textarea placeholder="Enter your message..." class="chats-input full-height" name="message" />
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default CreateBroadcast