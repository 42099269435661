import React, { useState } from 'react'
import Modal from './Modal'

const FullSize = (props) => {
  const { key, image } = props
  const [isOpen, setIsOpen] = useState(false)

  function handleImgClick() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="wcc-acimage">
        <img alt="full-size" src={image} onClick={handleImgClick} />
      </div>

      <Modal isOpen={isOpen} handleClose={() => { setIsOpen(false) }}>
        <img className="full-size-image full-width full-height" alt="full-size" src={image} />
      </Modal>
    </>
  )
}

export default FullSize
