import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'

// Assets
import TorchLogo from '../../assets/images/torch-logo.png'
import TorchLogoWhite from '../../assets/images/torch-logo-white.png'

// Containers
import CreateProject from '../../containers/CreateProject'

// Components
import SearchBar from '../Elements/SearchBar'
import ProfileMenu from '../Header/ProfileMenu'
import MenuTabItem from '../Header/MenuTabItem'
import ThemeToggle from '../Elements/ThemeToggle'
import CidTorchMenu from 'components/Elements/CidTorchMenu'
import { toggleService } from '../Elements/ThemeToggle'
import GetOrgId from '../../components/Elements/GetOrgId'

// API
import { authenticationService, projectService} from '../../api'
import config from '../../api/config'

// Hooks
import { UserContext } from '../../hooks/UserContext'

function OrganizationHeader({ orgId }) {
   
   
  const SetdOrgId = GetOrgId()
  const [Logo, setLogo] = useState('')
  const [createProjectIsOpen, setCreateProjectIsOpen] = useState(false)
  const [searchResults, setSearchResults] = useState(null)
  const [theme, setTheme] = useState(toggleService.currentModeValue)
 
  const [unseenMessages, setUnseenMessages] = useState(null)

  const closedCode = useRef(0)
  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser, currentOrganizations } = useContext(UserContext) // global context
  const currentOrganization = currentOrganizations[orgId]
   
  const orgMemberInfo = currentOrganizations
  let currentRole = null
  if (orgId in orgMemberInfo) {
    currentRole = orgMemberInfo[orgId].role
  }

  if (unseenMessages !== orgMemberInfo.unseenMessages) {
    setUnseenMessages(orgMemberInfo[orgId].unseenMessages)
  }

  useEffect(() => {
    if (theme === "light") {
      setLogo(TorchLogo)
    } else {
      setLogo(TorchLogoWhite)
    }
  }, [theme])

  var path = window.location.pathname
 
  /* WEBSOCKET SECTION */
  useEffect(() => {
    if (waitingToReconnect) {
      return
    }

    // Only set up the websocket once
    if (!clientRef.current) {
      if (closedCode.current === 4000) {
        // Refresh token
        //authenticationService.refreshToken()

        closedCode.current = 0
      }

      const client = new WebSocket(`${config.WebSocketURL}`)
      clientRef.current = client

      //window.client = client;

      client.onerror = (e) => console.error(e)

      client.onopen = () => {
        if (orgId) {
          setIsOpen(true)
          // console.log('ws opened')
          client.send(JSON.stringify({
            'token': currentUser.IdToken,
            'type': 'header',
            'organization': orgId
          }))
        }
        else {
          console.warn("Organization id not found")
        }
      }

      client.onclose = (e) => {
        closedCode.current = e.code

        if (!clientRef.current || waitingToReconnect) {
          return
          // Connection failed
          // console.log('ws closed by server', e.code)
        }


        // Parse event code and log
        setIsOpen(false)
        // console.log('ws closed')

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true)

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(null), 2000)
      }

      return () => {
        // console.log('Cleanup');
        // Dereference, so it will set up next time
        clientRef.current = null;
        client.close();
      }
    }
  }, [currentUser.IdToken, orgId, waitingToReconnect])

  if (isOpen) {
    clientRef.current.onmessage = e => {
      const data = JSON.parse(e.data);

      if (data.type === "HEADER_UNREAD") {
        UpdateUnreadMessages(data.payload.unread_messages)
      } else if (data.type === "NEW_ORGANIZATION") {
        let newOrgId = data.payload.id
        orgMemberInfo[newOrgId] = data.payload
        authenticationService.updateOrgMember(orgMemberInfo)
      } else if (data.type === "DELETED_ORGANIZATION") {
        delete orgMemberInfo[data.payload.id]
        authenticationService.updateOrgMember(orgMemberInfo)
      }
    }
  }
  /* END WEBSOCKET SECTION */

  function UpdateUnreadMessages(numberUnreadMessages) {
    let updatedMessages = { ...orgMemberInfo[orgId], unseenMessages: numberUnreadMessages }
    let newOrgInfo = { ...orgMemberInfo, updatedMessages }
    authenticationService.updateOrgMember(newOrgInfo)
    setUnseenMessages(numberUnreadMessages)
  }

  function handleExpandable() {
    let element = document.getElementById('expandable-menu')
    element.classList.toggle('mobile-hidden')
    element.classList.toggle('mobile-shown')
  }

  function handleSearch(e) {
    let results = []
    if (e.target.value.length > 0) {
      projectService.search(e.target.value, orgId)
        .then(response => {
          for (var r of response) {
            results.push(
              <div className="search-results cursor">
                <a href={`/organization/${orgId}/project/${r.id}`} className="flexer">
                  <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
                  <p className="text is-light line-margin-0">{`${r.case_id} (${r.subject.display_name})`}</p>
                </a>
              </div>
            )
          }
          setSearchResults(results)
        })
        .catch(error => {
          // console.error(error)
          setSearchResults([])
        })
    } else {
      setSearchResults([])
    }
  }

  let organizationSettingsTab = null
  if (currentRole !== "ANALYST" && currentRole !== "UNASSIGNED") {
    organizationSettingsTab = <Link to={`/organization/${orgId}/organization-settings`}><MenuTabItem text="Organization Settings" icon="fas fa-cog" active={path === `/organization/${orgId}/organization-settings` ? true : false} /></Link>
  }

  return (
    <>
      <div className="header">
        <div className="row flexer flexer-vcenter">

          {/*Commented out until both have subscriptions 2/18/2022 TD
          <div className='ml-10'>
            <CidTorchMenu />
          </div>*/}
          <div className="col-3 center-mobile">
            <img src={Logo} className="logo" alt="torch-logo" />
          </div>
          <div className="col-6 relative">
            <SearchBar placeholder={"Find a project..."} onChange={handleSearch} />
            <div className="absolute full-width on-top">
              {searchResults}
            </div>
          </div>
          <div className="col-3 flexer flexer-spaced flexer-vcenter">
            <ThemeToggle onChange={setTheme} />
            <div className="hidden mobile-shown center-mobile" onClick={handleExpandable}>
              <p className="mx-10">Menu<i className="fa fa-bars mx-10"></i></p>
            </div>
            <ProfileMenu />
          </div>
        </div>
        <div className="wcc-org"><b>{currentOrganization.name}</b></div>
          <div className="flexer flexer-spaced header-tabs flexer-mobile">
            
              <div id="expandable-menu" className="wcc-menu flexer flexer-mobile mobile-hidden">
                <Link to={`/organization/${orgId}`} className="line-margin-0">
                  <MenuTabItem text="Dashboard" icon="fas fa-grip-horizontal" active={path === `/organization/${orgId}` ? true : false} />
                </Link>
                <Link to={`/organization/${orgId}/chats`} className="line-margin-0">
                  <MenuTabItem text="Chats" alert={unseenMessages} icon="far fa-comment-dots" active={path === `/organization/${orgId}/chats` ? true : false} />
                </Link>

                {(currentRole !== "ANALYST" && currentRole !== "UNASSIGNED") &&
                  <Link to={`/organization/${orgId}/analytics`} className="line-margin-0">
                    <MenuTabItem
                      text="Analytics"
                      icon="fa fa-bar-chart"
                      active={(path === `/organization/${orgId}/analytics`)} />
                  </Link>
                }

                <Link to={`/organization/${orgId}/user-resources`}>
                  <MenuTabItem text="User Resources" icon="fa fa-file-o" active={path === `/organization/${orgId}/user-resources` ? true : false} />
                </Link>
                {organizationSettingsTab}
              </div>
            
              <div className="right center-mobile">
                {((currentRole === "CASE_MANAGER" || currentRole === "ADMIN") && SetdOrgId) &&
                  <div className="project-btn full-width-mobile" onClick={() => { setCreateProjectIsOpen(true) }}>
                    <p className="subheading text-white cursor"><i className="fas fa-plus" />Create Project</p>
                  </div>
                }
              </div>
        </div>
      </div>
      <CreateProject isOpen={createProjectIsOpen} handleClose={() => { setCreateProjectIsOpen(false) }} />
    </>
  )
}

export default OrganizationHeader
