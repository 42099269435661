import React, { useState, useEffect } from 'react'

// Components
import ImageContent from 'components/Elements/ImageContent'
import InputField from '../../Elements/InputField'
import Button from '../../Elements/Button'
import ReactFlagsSelect from 'react-flags-select'
import GetOrgId from '../../Elements/GetOrgId'
import Tooltip from '../../Elements/Tooltip'

// API
import {subjectService} from '../../../api'

function EditProfileForm(props) {

    const [calling_code, setCallingCode] = useState("+1")
    const [aliasesList, setAliases] = useState([])
    const [phonesList, setPhones] = useState([])
    const [subject, setSubject] = useState(null)
    const [refresh, setRefresh] = useState(1)

    const orgId = GetOrgId()

    useEffect(() => {
        if (props.subject.id) {
            subjectService.retrieve(orgId, props.subject.id)
            .then(response => {
                setSubject(response)
            })
            .catch(error => {
                console.log("Error loading subject profile", error)
            })
        }
    }, [props.subject.id, refresh])

    const country_calling_codes = {"US": {"primary": "+1"}}
    function changeCountryCallingCode(value){
        setCallingCode(country_calling_codes[value]['primary'])
    }

    var profilePic = subject?.profile_pic.content ??  'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('subject-form')
        var form_data = new FormData(form_element)

        var body = {"alias": [], "phones": []}
        let body_new = {"alias": [], "phones": []}

        form_data.forEach((value, key) => {
            if(value) {
                if (key.includes('phones')){
                    value = calling_code + value
                }

                if(key.includes('__')) {
                    key = key.split('__')
                    var subelement = {}
                    if ( key.length > 2) {
                        subelement[key[2]] = {[key[1]]:{content:value}} 
                        body[key[0]].push(subelement)
                    } else {
                        subelement[key[1]] = {content:value}
                        body_new[key[0]].push(subelement)
                    }
                }
                else{
                    body[key] = {content:value}
                }
            }
        })

        subjectService.update(body, orgId, props.subject.id)
        .then(response => {
            props.onComplete()
            props.onClose()
        })
        .catch(error => {
            console.log("Error updating subject profile", error)
        })

        body.alias.forEach(e => {
            subjectService.updateAlias(Object.values(e)[0], orgId, Object.keys(e)[0])
            .then(response =>
                console.log('alias updated'))
            .catch(error => {
                console.log("Error updating subject aliase", error)
            })
        })

        body.phones.forEach(e => {
            subjectService.updatePhone(Object.values(e)[0], orgId, Object.keys(e)[0])
            .then(response =>
                console.log('phone updated'))
            .catch(error => {
                console.log("Error updating subject phone", error)
            })
        })

        body_new.alias.forEach(e => {
            subjectService.createAlias({...e, subject: props.subject.id}, orgId)
            .then(response =>
                console.log('alias created'))
            .catch(error => {
                console.log("Error creating subject aliase", error)
            })
        })

        body_new.phones.forEach(e => {
            subjectService.createPhone({...e, subject: props.subject.id}, orgId)
            .then(response =>
                console.log('phone created'))
            .catch(error => {
                console.log("Error creating subject phone", error)
            })
        })
        setRefresh(refresh + 1)
    }
    
    function handleSubmitImage(e){
        e.preventDefault()
        
        var form_element = document.getElementById('subject-image-form')
        var form_data = new FormData(form_element)
        
        subjectService.updateImage(form_data, orgId, props.subject.id)
        .then(response => {
            props.onComplete()
            // props.onClose()
            setRefresh(refresh + 1)

        })
        .catch(error => {
            console.log("Error updating subject profile picture", error)
        })
    }

    function handleFileLoader() {
        var element = document.getElementById("file-loader-profile")
        element.click()
    }

    // Alias
    function addAlias(){
        setAliases([...aliasesList, {first_name: null}])
    }


    let aliases = subject  && subject.alias.map(alias =>
        <div className="flexer mt-30" key={alias.id}>
            <i className="form-icon fas fa-user-alt line-margin-0"></i>
            <InputField class="full-width line-margin-xs" label="Alias" name={`alias__first_name__${alias.id}`} placeholder={alias.first_name?.content ? alias.first_name.content : ''}/>
        </div>)
    
    for(const alias of aliasesList){
        aliases.push(
            <div className="flexer mt-30">
                <i className="form-icon fas fa-user-alt line-margin-0"></i>
                <InputField class="full-width line-margin-xs" label="Alias" name="alias__first_name" placeholder={alias.first_name?.content  ? alias.first_name.content : ''}/>
            </div>
        )
    }

    // Phones
    function addPhone(){
        setPhones([...phonesList, {phone_number: null}])
    }

    let phones = subject && subject.phones.map(phone => 
        <div className="flexer" key={phone.id}>
            <i className="form-icon fa fa-phone"></i>
            <ReactFlagsSelect selected={"US"} countries={["US"]} customLabels={country_calling_codes} onSelect={changeCountryCallingCode} />
            <Tooltip content='55555555' direction='bottom' class='full-width'>
                <InputField type="tel" class="full-width line-margin-xs" name={`phones__phone_number__${phone.id}`} placeholder={phone.phone_number?.content ? phone.phone_number.content : ''}/>
            </Tooltip>
        </div>)

    for(const phone of phonesList){
        phones.push(
            <div className="flexer">
                <i className="form-icon fa fa-phone"></i>
                <ReactFlagsSelect selected={"US"} countries={["US"]} customLabels={country_calling_codes} onSelect={changeCountryCallingCode} />
                <Tooltip content='55555555' direction='bottom' class='full-width'>
                    <InputField type="tel" class="full-width line-margin-xs" name="phones__phone_number" placeholder={phone.phone_number?.content ? phone.phone_number.content : ''}/>
                </Tooltip>
            </div>
        )
    }

    return (
        <React.Fragment>
            <form id="subject-image-form" onSubmit={handleSubmitImage}>
                <h1 className="title is-dark mb-30">Edit Profile</h1>
                <div className="flexer flexer-vcenter">
                    <ImageContent className={'edit-profile__picture'} src={profilePic} alt="profile" />
                    <input id="file-loader-profile" name="profile_pic" type="file" className="file-input" onChange={handleSubmitImage}/>
                    <Button type="button" text="Upload photo" class="mx-15 orange-btn subheading is-white"  onClick={handleFileLoader} />
                </div>
            </form>
            <form id="subject-form" onSubmit={handleSubmit}>
                <div className="flexer mt-30">
                    <i className="form-icon fas fa-user-alt line-margin-0"></i>
                    <InputField class="full-width line-margin-0" label="First name" name="first_name" placeholder={subject && subject.first_name.content}/>
                </div>
                <div className="flexer">
                    <i className="form-icon fas fa-user-alt line-margin-0"></i>
                    <InputField class="full-width line-margin-0" label="Middle name" name="middle_name" placeholder={subject && subject.middle_name.content}/>
                </div>
                <div className="flexer">
                    <i className="form-icon fas fa-user-alt line-margin-0"></i>
                    <InputField class="full-width line-margin-0" label="Last name" name="last_name" placeholder={subject && subject.last_name.content}/>
                </div>
                <div className="flexer">
                    <i className="form-icon fa fa-calendar line-margin-0"></i>
                    <InputField class="full-width line-margin-0" type="date" label="DOB" name="dob" placeholder={subject && subject.dob.content} />
                </div>
                <div className="flexer">
                    <i className="form-icon fa fa-envelope line-margin-0"></i>
                    <Tooltip content='example@example.com' direction='bottom' class='full-width'>
                        <InputField class="full-width line-margin-0" label="Email" name="email" placeholder={subject && subject.email.content}/>
                    </Tooltip>
                </div>
                {phones}
                <div className="flexer flexer-vcenter mt-10 cursor">
                    <i className="form-icon fas fa-plus is-orange line-margin-0"></i>
                    <p className="link" onClick={addPhone} >Add phone</p>
                </div>
                {aliases}
                <div className="flexer flexer-vcenter mt-10 cursor">
                    <i className="form-icon fas fa-plus is-orange line-margin-0"></i>
                    <p className="link" onClick={addAlias} >Add alias</p>
                </div>
                <div className="right center-mobile">
                    <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose}/>
                    <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit"/>
                </div>
            </form>
        </React.Fragment>
    )
}

export default EditProfileForm
