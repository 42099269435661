import React, { useState, useEffect } from 'react'

// API
import { subjectService, projectService, linkService } from '../../../api'

// Components
import Button from '../../Elements/Button'
import { Multiselect } from 'multiselect-react-dropdown'
import Dropdown from '../../Elements/Dropdown'
import InputField from '../../Elements/InputField'
import ReactFlagsSelect from 'react-flags-select'
import GetOrgId from '../../Elements/GetOrgId'
import Tooltip from '../../Elements/Tooltip'

function AddLinkForm(props) {
  const [createNew, setNew] = useState(true)
  const [calling_code, setCallingCode] = useState("+1")
  const [options, setOptions] = useState([])
  const [linkedSubject, setLinkedSubject] = useState(null)
  const orgId = GetOrgId()

  useEffect(() => {
    subjectService.list(orgId)
      .then(response => {
        setOptions(response)
      })
      .catch(error => {
        console.log("Error loading subjects", error)
      })
  }, [orgId])

  const country_calling_codes = { "US": { "primary": "+1" } }
  function changeCountryCallingCode(value) {
    setCallingCode(country_calling_codes[value]['primary'])
  }

  function handleNewLink(e) {
    e.preventDefault()

    var form_element = document.getElementById('new-link')
    var form_data = new FormData(form_element)

    var body = { "alias": [], "phones": [] }

    form_data.forEach((value, key) => {
        if(value) {
            if (key.includes('phones')){
                value = calling_code + value
            }

            if(key.includes('__')) {
                key = key.split('__')
                var subelement = {}
                // subelement[key[1]] = value
                subelement[key[1]] = {content:value}
                body[key[0]].push(subelement)
            }
            else{
                body[key] = {content:value}
            }
        }
    })

    subjectService.create(body, orgId)
      .then(response => {
        var projectBody = { "subject": response.id, "priority": "standard", "status": "TEMPORARY", "case_type": "LNK" }
        projectService.create(projectBody, orgId)
          .then(response => {
            var linkBody = { "relationship": body.relationship, "linked_subject": response.subject, "main_subject": props.mainSubject }
            linkService.create(linkBody, orgId)
              .then(response => {
                props.onComplete()
                props.onClose()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  var link_form
  if (createNew) {
    link_form =
    <form id="new-link" onSubmit={handleNewLink}>
      <div className="flexer">
        <i className="form-icon fas fa-user-alt"></i>
        <InputField class="full-width line-margin-xs" label="First name" name="first_name" />
      </div>
      <div className="flexer">
        <i className="form-icon fas fa-user-alt"></i>
        <InputField class="full-width line-margin-xs" label="Middle name" name="middle_name" />
      </div>
      <div className="flexer">
        <i className="form-icon fas fa-user-alt"></i>
        <InputField class="full-width line-margin-xs" label="Last name" name="last_name" />
      </div>
      <div className="flexer">
        <i className="form-icon fas fa-user-alt"></i>
        <InputField class="full-width line-margin-xs" label="Alias" name="alias__first_name" />
      </div>
      <div className="flexer">
        <i className="form-icon fa fa-envelope cursor"></i>
        <Tooltip content='example@example.com' direction='bottom' class='full-width'>
          <InputField class="full-width line-margin-xs" label="Email" name="email" />
        </Tooltip>
      </div>
      <div className="flexer">
        <i className="form-icon fa fa-phone"></i>
        <ReactFlagsSelect selected={"US"} countries={["US"]} customLabels={country_calling_codes} onSelect={changeCountryCallingCode} />
        <Tooltip content='55555555' direction='bottom' class='full-width'>
          <InputField type="tel" class="full-width line-margin-xs" name="phones__phone_number" />
        </Tooltip>
      </div>
      <Dropdown label="Relationship" class="line-margin-xs" name="relationship">
        <option value="" disabled>Select an option</option>
        <option value="mother">Mother</option>
        <option value="father">Father</option>
        <option value="relative">Relative</option>
        <option value="family-friend">Family Friend</option>
        <option value="associate">Associate</option>
        <option value="person-of-interest">Person of Interest</option>
      </Dropdown>
      <div className="right center-mobile">
        <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose} />
        <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
      </div>
    </form>
  }
  else {

    function handleOldLink(e) {
      e.preventDefault()

      var form_element = document.getElementById('old-subject-form')
      var form_data = new FormData(form_element)

      var body = {}
      form_data.forEach((value, key) => {
        if (value) {
          // body[key] = value
          if (key.includes('relationship')) {
            body[key] = { content: value}
          } else {
            body[key] = value
          }
        }
      })

      linkService.create(body, orgId)
        .then(response => {
          props.onComplete()
          props.onClose()
          form_element.reset()
        })
        .catch(error => {
          console.log("It was not possible to link subjects", error)
        })

    }

    link_form =
      <form id="old-subject-form" onSubmit={handleOldLink}>
        <div className='filters-multi'>
          <Multiselect 
            options={options} 
            displayValue="display_name"
            avoidHighlightFirstOption={true}
            selectionLimit={1} 
            placeholder="Select a subject" 
            name="linked_subject" 
            onSelect={(selected) => { setLinkedSubject(selected[0]['id']) }} 
            onRemove={() => { setLinkedSubject(null) }} 
          />
        </div>
        <Dropdown label="Relationship" name="relationship">
          <option value="" disabled>Select an option</option>
          <option value="mother">Mother</option>
          <option value="father">Father</option>
          <option value="relative">Relative</option>
          <option value="family-friend">Family Friend</option>
          <option value="associate">Associate</option>
          <option value="person-of-interest">Person of Interest</option>
        </Dropdown>
        <input type="hidden" name="main_subject" value={props.mainSubject} />
        <input type="hidden" name="linked_subject" value={linkedSubject} />
        <div className="right center-mobile">
          <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose} />
          <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
        </div>
      </form>
  }

  return (
    <React.Fragment>
      <div className="flexer flexer-spaced">
        <h1 className="title is-dark mb-30">Add Link</h1>
        {createNew ? <p className="link right cursor" onClick={() => { setNew(false) }}><i className="fa fa-plus is-orange mx-10"></i>Add existing link</p> : <p className="link right cursor" onClick={() => { setNew(true) }}><i className="fa fa-plus is-orange mx-10"></i>Add new link</p>}
      </div>
      {link_form}
    </React.Fragment>
  )
}

export default AddLinkForm
