import React from 'react'

function TermsOfServiceContent() {
    return (
        <React.Fragment>
            <p className="text is-light">Posted/Revised: JULY 8th, 2021</p>
            <p className="text is-light">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY CLICKING “ACCEPTED AND AGREED TO,” CUSTOMER AGREES TO THESE TERMS AND CONDITIONS.</p>
            <p className="text is-light">These Terms and Conditions constitute an agreement by and between Echo Analytics Group, a Quiet Professionals, LLC company, (“Vendor”) and Customer. These Terms and Conditions establish the contractual framework for Customer’s use of and Vendor’s provision of access to Vendor’s System (as defined below in Section 1.7). Each purchase order for access to Vendor’s System will be set forth in an Order (as defined in Section 1.4). These Terms and Conditions and the Order(s) are collectively, the “Agreement.” This Agreement is effective as of the date Customer (or “User”)  clicks  “Accepted  and  Agreed  To” (the “Effective Date”).</p>
            <br/><hr></hr><br/>
            <p className="text is-light">EACH PARTY ACKNOWLEDGES THAT IT HAS READ THE AGREEMENT, UNDERSTANDS IT, AND AGREES TO BE BOUND BY ITS TERMS, AND THAT THE PERSON ACCEPTING ON BEHALF HAS BEEN AUTHORIZED TO DO SO. THE PERSON EXECUTING THIS AGREEMENT OR THE PERSON EXECUTING ON CUSTOMER’S BEHALF REPRESENTS THAT HE OR SHE HAS THE AUTHORITY TO BIND CUSTOMER TO THE AGREEMENT.</p>
            <p className="text is-light"><span className="heading is-light">1. DEFINITIONS. </span>The following capitalized terms will have the following meanings whenever used in this Agreement. </p>
            <p className="text is-light"><span className="heading is-light">1.1. </span>“AUP” means Vendor’s Acceptable Use Policy currently posted at: https://torch.echoanalyticsgroup.com/user-resources</p>
            <p className="text is-light"><span className="heading is-light">1.2. </span>“Customer Data” means data in electronic form input or collected through the System by or from Customer, including without limitation by Customer’s Users. </p>
            <p className="text is-light"><span className="heading is-light">1.3. </span>“Documentation” means Vendor's standard manual related to the use of the System. </p>
            <p className="text is-light"><span className="heading is-light">1.4. </span>“Order” means a purchase order for access to the System, executed as follows: Authorized Credit Card Payment or Government Purchase Order executed as a contract. </p>
            <p className="text is-light"><span className="heading is-light">1.5. </span>“Personally Identifiable Information” refers to information than can be used to distinguish, trace, or reasonably could be used to identify a natural person, including name, address, email address, passwords, account numbers, social security numbers, credit card information, personal financial information, personal preferences, demographic data, marketing data, credit data, or any other identification data, including, but not limited to, all “non-public personal information,” as defined under the Gramm-Leach-Bliley Act (15 United States Code (“U.S.C.”) §6801 et seq.), all “protected health information,” as that term is used under the Health Insurance Portability & Accountability Act of 1996 (“HIPPA”) (even if HIPAA is not applicable to Vendor), all “personal data” as that term is defined in the EU Data Protection Directive (Directive 95/46/EEC) on the protection of individuals with regard to processing of personal data and the free movement of such data, and all other information that is protected against disclosure under applicable law or regulation. </p>
            <p className="text is-light"><span className="heading is-light">1.6. </span>“Privacy Policy” means Vendor’s Privacy Policy, currently posted at: https://torch.echoanalyticsgroup.com  </p>
            <p className="text is-light"><span className="heading is-light">1.7. </span>“System” means Vendor’s website https://torch.echoanlyticsgroup.com  </p>
            <p className="text is-light"><span className="heading is-light">1.8. </span>“SLA” means Vendor’s standard Service Level Agreement, currently posted at: https://echoanalyticsgroup.com  </p>
            <p className="text is-light"><span className="heading is-light">1.9. </span>“Term” is defined in Section 13.1 below. </p>
            <p className="text is-light"><span className="heading is-light">1.10. </span>“User” means any individual who uses the System on Customer’s behalf or through Customer’s account or passwords, whether authorized or not. </p>
            <p className="heading is-light">2. THE SYSTEM.</p>
            <p className="text is-light"><span className="heading is-light">2.1. Use of the System. </span>During the Term, Customer may access and use the System pursuant to the terms of this Agreement, including such features and functions as the Order requires. Customer acknowledges and agrees that the right to use the System is limited based upon the amount of the per-User subscription fee paid by Customer. Customer agrees not to increase the number of Users unless Customer pays for such additional users. In the event Customer uses the System in excess of the applicable Users, Vendor’s sole and exclusive remedy and Customer’s sole and exclusive liability shall be payment of the subscription fee attributable to the excess use at the rates set forth under this Agreement. </p>
            <p className="text is-light"><span className="heading is-light">2.2. Service Levels. </span>Vendor shall provide the remedies listed in the SLA for any failure of the System listed in the SLA. Such remedies are Customer’s sole remedy for any failure of the System, and Customer recognizes and agrees that if the SLA does not list a remedy for a given failure, it has no remedy. Credits issued pursuant to the SLA apply to outstanding or future invoices only and are forfeited upon termination of this Agreement. Vendor is not required to issue refunds or to make payments against such credits under any circumstances, including without limitation after termination of this Agreement. </p>
            <p className="text is-light"><span className="heading is-light">2.3. Documentation. </span>Customer may reproduce and use the Documentation solely as necessary to support Customer’s use of the System. </p>
            <p className="text is-light"><span className="heading is-light">2.4. System Revisions. </span>Vendor may revise System features and functions or the SLA at any time, including without limitation by removing such features and functions or reducing service levels. If any such revision to the System materially reduces features or functionality provided pursuant to an Order, Customer may within 30 days of notice of the revision terminate such Order, without cause, or terminate this Agreement without cause if such Order is the only one outstanding. </p>
            <p className="heading is-light">3. PAYMENT.</p>
            <p className="text is-light"><span className="heading is-light">3.1. Subscription Fees. </span>Customer shall pay Vendor the fee set forth in each Order (the “Subscription Fee”) for each Term. Vendor’s invoices are due within 30 days of issuance. For late payment, Customer shall pay interest charges from the time the payment was due at the rate that is the lower of 15.07% per month or the highest rate permissible under applicable law. Vendor will not be required to refund the Subscription Fee under any circumstances. </p>
            <p className="text is-light"><span className="heading is-light">3.2. Taxes. </span>Amounts due under this Agreement are payable to Vendor without deduction and are net of any tax, tariff, duty, or assessment imposed by any government authority (national, state, provincial, or local), including without limitation any sales, use, excise, ad valorem, property, withholding, or value added tax withheld at the source. If applicable law requires withholding or deduction of such taxes or duties, Customer shall separately pay Vendor the withheld or deducted amount. However, the prior two sentences do not apply to taxes based on Vendor’s net income. </p>
            <p className="heading is-light">4. CUSTOMER DATA & PRIVACY.</p>
            <p className="text is-light"><span className="heading is-light">4.1. Use of Customer Data. </span>Unless it receives Customer’s prior written consent, Vendor: (a) shall not access, process, or otherwise use Customer Data other than as necessary to facilitate the System; and (b) shall not intentionally grant any third- party access to Customer Data, including without limitation Vendor’s other customers, except subcontractors that are subject to a reasonable nondisclosure agreement. Notwithstanding the foregoing, Vendor may disclose Customer Data as required by applicable law or by proper legal or governmental authority. Vendor shall give Customer prompt notice of any such legal or governmental demand and reasonably cooperate with Customer in any effort to seek a protective order or otherwise to contest such required disclosure, at Customer’s expense. As between the parties, Customer retains ownership of Customer Data. </p>
            <p className="text is-light"><span className="heading is-light">4.2. Privacy Policy. </span>The Privacy Policy applies only to the System and does not apply to any third-party website or service linked to the System or recommended or referred to through the System or by Vendor’s staff. </p>
            <p className="text is-light"><span className="heading is-light">4.3. Risk of Exposure. </span>Customer recognizes and agrees that hosting data online involves risks of unauthorized disclosure or exposure and that, in accessing and using the System, Customer assumes such risks. Vendor offers no representation, warranty, or guarantee that Customer Data will not be exposed or disclosed through errors or the actions of third parties. </p>
            <p className="text is-light"><span className="heading is-light">4.4. Data Accuracy. </span>Vendor will have no responsibility or liability for the accuracy of data uploaded to the System by Customer, including without limitation Customer Data and any other data uploaded by Users. </p>
            <p className="text is-light"><span className="heading is-light">4.5. Data Deletion. </span>Vendor may permanently erase Customer Data if Customer’s account is delinquent, suspended, or terminated for 30 days or more. </p>
            <p className="text is-light"><span className="heading is-light">4.6. Excluded Data. </span>Customer represents and warrants that Customer Data does not and will not include, and Customer has not and shall not upload or transmit to Vendor's computers or other media, any data (“Excluded Data”) regulated pursuant to HIPAA, the Health Information Technology for Economic and Clinical Health (“HITECH”), a state privacy statute or any United States law or regulation that restricts data transmission as afforded by the System, and any international privacy provision that would be violated by using the System (the "Excluded Data Laws"). CUSTOMER RECOGNIZES AND AGREES THAT: (a) VENDOR HAS NO LIABILITY FOR ANY FAILURE TO PROVIDE PROTECTIONS SET FORTH IN THE EXCLUDED DATA LAWS OR OTHERWISE TO PROTECT EXCLUDED DATA; AND (b) VENDOR’S SYSTEMS ARE NOT INTENDED FOR MANAGEMENT OR PROTECTION OF EXCLUDED DATA AND MAY NOT PROVIDE ADEQUATE OR LEGALLY REQUIRED SECURITY FOR EXCLUDED DATA. </p>
            <p className="text is-light"><span className="heading is-light">4.7. Aggregate & Anonymized Data. </span>Notwithstanding the provisions above of this Article 4, Vendor may use, reproduce, sell, publicize, or otherwise exploit Aggregate Data in any way, in its sole discretion. “Aggregate Data” means Customer Data that is combined with other similar data of other customers. Aggregate Data shall not include (directly or by inference) any information identifying the Customer or any Personally Identifiable Information of Customer, any of Customer’s Users, or other customers. </p>
            <p className="heading is-light">5. CUSTOMER’S RESPONSIBILITIES & RESTRICTIONS.</p>
            <p className="text is-light"><span className="heading is-light">5.1. Acceptable Use. </span>Customer shall comply with the AUP. Customer shall not: (a) use the System for service bureau or time-sharing purposes or in any other way allow third parties to exploit the System; (b) provide System passwords or other log- in information to any third party; (c) share non-public System features or content with any third party; (d) access the System in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics of the System, or to copy any ideas, features, functions or graphics of the System; or (e) engage in web scraping or data scraping on or related to the System, including without limitation collection of information through any software that simulates human activity or any bot or web crawler. In the event that it suspects any breach of the requirements of this Section 5.1, including without limitation by Users, Vendor may suspend Customer’s access to the System without advanced notice, in addition to such other remedies as Vendor may have. Neither this Agreement nor the AUP requires that Vendor take any action against Customer or any User or other third party for violating the AUP, this Section 5.1, or this Agreement, but Vendor is free to take any such action it sees fit. </p>
            <p className="text is-light"><span className="heading is-light">5.2. Unauthorized Access. </span>Customer shall take reasonable steps to prevent unauthorized access to the System, including without limitation by protecting its passwords and other log-in information. Customer shall notify Vendor immediately, but in no event later than seventy-two (72) hours, after the discovery of any known or suspected unauthorized access or use of the System or breach of its security. Customer shall use best efforts to mitigate, to the extent practicable, any harmful effect that is known to Customer of any unauthorized access or use of the System or breach of its security. </p>
            <p className="text is-light"><span className="heading is-light">5.3. Compliance with Laws. </span>In its use of the System, Customer shall comply with all applicable laws, including without limitation laws governing the protection of Personally Identifiable Information and other laws applicable to the protection of Customer Data. </p>
            <p className="text is-light"><span className="heading is-light">5.4. Users & System Access. </span>Customer is responsible and liable for: (a) Users’ use of the System, including without limitation unauthorized User conduct and any User conduct that would violate the AUP or the requirements of this Agreement applicable to Customer; and (b) any use of the System through Customer’s account, whether authorized or unauthorized. Customer is responsible for designating Users who are authorized to access the System. </p>
            <p className="text is-light"><span className="heading is-light">6. </span>User Generated Content, Reviews, Feedback and other Postings to the System. </p>
            <p className="text is-light"><span className="heading is-light">6.1. User Generated Content. </span>If you submit, upload or post any comments, ideas, suggestions, information, files, videos, images or other materials to us or our System (“User Generated Content”), you agree not to provide any User Generated Content that </p>
            <p className="text is-light">(1) is defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent, pornographic, or harmful, or that could encourage criminal or unethical behavior, </p>
            <p className="text is-light">(2) violates or infringes the privacy, copyright, trademark, trade dress, trade secrets or intellectual property rights of any person or entity, or </p>
            <p className="text is-light">(3) contains or transmits a virus or any other harmful component.  </p>
            <p className="text is-light">You agree not to contact other users of the System through unsolicited e-mail, telephone calls, mailings, or any other method of communication. You represent and warrant to Echo Analytics Group that you have the legal right and authorization to provide all User Generated Content to Echo Analytics Group for the purposes and Echo Analytics Group’s use as set forth herein. Echo Analytics Group shall have a royalty-free, irrevocable, transferable right and license to use the User Generated Content in whatever manner Echo Analytics Group desires, including without limitation, to copy, modify, delete in its entirety, adapt, publish, translate, create derivative works from and/or sell and/or distribute such User Generated Content and/or incorporate such User Generated Content into any form, medium or technology throughout the world. Echo Analytics Group is and shall be under no obligation. </p>
            <p className="text is-light">(1) to maintain any User Generated Content in confidence; </p>
            <p className="text is-light">(2) to pay to you any compensation for any User Generated Content; or </p>
            <p className="text is-light">(3) to respond to any User Generated Content. </p>
            <p className="text is-light"><span className="heading is-light">6.2. Review. </span>Echo Analytics Group does not regularly review posted User Generated Content but does reserve the right (but not the obligation) to monitor and edit or remove any User Generated Content submitted to the System. You grant Echo Analytics Group the right to use the name that you submit in connection with any User Generated Content. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any User Generated Content. You are and shall remain solely responsible for the content of any User Generated Content you make. Echo Analytics Group and its affiliates take no responsibility and assume no liability for any User Generated Content submitted by you or any third party. </p>
            <p className="text is-light"><span className="heading is-light">6.3. Third Party Claims. </span>You agree to defend, indemnify and hold Echo Analytics Group harmless from and against all third party claims, damages and expenses (including reasonable attorneys’ fees) against or incurred by Echo Analytics Group arising out of any User Generated Content you post or allow to be posted to the System. </p>
            <p className="text is-light"><span className="heading is-light">7. </span>Claims of Copyright Infringement and Notice of Alleged Infringement.</p>
            <p className="text is-light"><span className="heading is-light">7.1. Claims of Copyright Infringement. </span>We disclaim any responsibility or liability for copyrighted materials posted on our System. If you believe that your work has been copied in a manner that constitutes copyright infringement, please follow the procedures set forth below. Echo Analytics Group respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act ("DMCA"), we will respond promptly to notices of alleged infringement that are reported to Echo Analytics Group’s Designated Copyright Agent, identified below. </p>
            <p className="text is-light"><span className="heading is-light">7.2. Notices of Alleged Infringement for Content Made Available on the System. </span>If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through our Systems by sending us a notice ("Notice") complying with the following requirements. </p>
            <p className="text is-light">1. Identify the copyrighted works that you claim have been infringed. </p>
            <p className="text is-light">2. Identify the material or link you claim is infringing (or the subject of infringing activity) and that access to which is to be disabled, including at a minimum, if applicable, the URL of the link shown on the System where such material may be found. </p>
            <p className="text is-light">3. Provide your mailing address, telephone number, and, if available, email address. </p>
            <p className="text is-light">4. Include both of the following statements in the body of the Notice: </p>
            <p className="text is-light">"I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)." </p>
            <p className="text is-light">"I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed." </p>
            <p className="text is-light">5. Provide your full legal name and your electronic or physical signature. Deliver this Notice, with all items completed, to our Copyright Agent: </p>
            <p className="text is-light">Echo Analytics Group <br/>2701 North Rocky Point Drive Suite 185 <br/>Tampa, Florida 33624 <br/>platformsupport@echoanalyticsgroup.com </p>
            <p className="heading is-light">8. INTELLECTUAL PROPERTY & FEEDBACK. </p>
            <p className="text is-light"><span className="heading is-light">8.1. Intellectual Property Rights to the System. </span>Vendor retains all right, title, and interest in and to the System, including without limitation all software used to provide the System and all graphics, user interfaces, logos, and trademarks reproduced through the System. This Agreement does not grant Customer any intellectual property license or rights in or to the System or any of its components. Customer recognizes that the System and its components are protected by copyright and other laws. </p>
            <p className="text is-light"><span className="heading is-light">8.2. Feedback. </span>Vendor has not agreed to and does not agree to treat as confidential any Feedback (as defined below) Customer or Users provide to Vendor, and nothing in this Agreement or in the parties’ dealings arising out of or related to this Agreement will restrict Vendor’s right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback, without compensating or crediting Customer or the User in question. Notwithstanding the provisions of Article 7 below, Feedback will not be considered Confidential Information. (“Feedback” refers to any suggestion or idea for improving or otherwise modifying any of Vendor’s products or services.) </p>
            <p className="text is-light"><span className="heading is-light">9. CONFIDENTIAL INFORMATION. </span>“Confidential Information” refers to the following items Vendor discloses to Customer: </p>
            <p className="text is-light">(a) any document Vendor marks “Confidential”; </p>
            <p className="text is-light">(b) any information Vendor orally designates as “Confidential” at the time of disclosure, provided Vendor confirms such designation in writing within two (2) business days. </p>
            <p className="text is-light">(c) the Documentation and Order, whether or not marked or designated confidential; and </p>
            <p className="text is-light">(d) any other non-public, sensitive information Customer should reasonably consider a trade secret or otherwise confidential. Notwithstanding the foregoing, Confidential Information does not include information that: </p>
            <p className="text is-light">(i) is in Customer’s possession at the time of disclosure; </p>
            <p className="text is-light">(ii) is independently developed by Customer without use of or reference to Confidential Information; </p>
            <p className="text is-light">(iii) becomes known publicly, before or after disclosure, other than as a result of Customer’s improper action or inaction; or </p>
            <p className="text is-light">(iv) is approved for release in writing by Customer. Customer is on notice that the Confidential Information may include Vendor’s valuable trade secrets. </p>
            <p className="text is-light"><span className="heading is-light">9.1. Nondisclosure. </span>Customer shall not use Confidential Information for any purpose other than Customer’s internal business purposes or the purpose stated on the Order (the “Purpose”). Customer: </p>
            <p className="text is-light">(a) shall not disclose Confidential Information to any employee or contractor of Customer unless such person needs access in order to facilitate the Purpose and executes a nondisclosure agreement with Customer with terms no less restrictive than those of this Article 7; and </p>
            <p className="text is-light">(b) shall not disclose Confidential Information to any other third party without Vendor’s prior written consent. Without limiting the generality of the foregoing, Customer shall protect Confidential Information with the same degree of care it uses to protect its own confidential information of similar nature and importance, but with no less than reasonable care. Customer shall promptly notify Vendor of any misuse or misappropriation of Confidential Information that comes to Customer’s attention. Notwithstanding the foregoing, Customer may disclose Confidential Information as required by applicable law or by proper legal or governmental authority. Customer shall give Vendor prompt notice of any such legal or governmental demand and reasonably cooperate with Vendor in any effort to seek a protective order or otherwise to contest such required disclosure, at Vendor’s expense. </p>
            <p className="text is-light"><span className="heading is-light">9.2. Nondisclosure. </span>Customer shall not use Confidential Information for any purpose other than Customer’s internal business purposes or the purpose stated on the Order (the “Purpose”). Customer: </p>
            <p className="text is-light"><span className="heading is-light">9.3. Injunction. </span>Customer agrees that breach of this Article 7 would cause Vendor irreparable injury, for which monetary damages would not provide adequate compensation, and that in addition to any other remedy, Vendor will be entitled to injunctive relief against such breach or threatened breach, without proving actual damage or posting a bond or other security. </p>
            <p className="text is-light"><span className="heading is-light">9.4. Termination & Return. </span>With respect to each item of Confidential Information, the obligations of Section 7.1 above (Nondisclosure) will terminate five (5) years after the date of disclosure; provided that such obligations related to Confidential Information constituting Vendor’s trade secrets will continue so long as such information remains subject to trade secret protection pursuant to applicable law. Upon termination of this Agreement, Customer shall return all copies of Confidential Information to Vendor or certify, in writing, the destruction thereof. </p>
            <p className="text is-light"><span className="heading is-light">9.5. Retention of Rights. </span>This Agreement does not transfer ownership of Confidential Information or grant a license thereto. Vendor will retain all right, title, and interest in and to all Confidential Information. </p>
            <p className="text is-light"><span className="heading is-light">9.6. Exception & Immunity. </span>Pursuant to the Defend Trade Secrets Act of 2016, 18 USC Section 1833(b), Customer is on notice and acknowledges that, notwithstanding the foregoing or any other provision of this Agreement: </p>
            <p className="text is-light">(a) Immunity. An individual shall not be held criminally or civilly liable under any Federal or State trade secret law for the disclosure of a trade secret that- </p>
            <p className="text is-light">(A) is made- </p>
            <p className="text is-light">(i) in confidence to a Federal, State, or local government official, either directly or indirectly, or to an attorney; and </p>
            <p className="text is-light">(ii) solely for the purpose of reporting or investigating a suspected violation of law; or </p>
            <p className="text is-light">(B) is made in a complaint or other document filed in a lawsuit or other proceeding, if such filing is made under seal. </p>
            <p className="text is-light">(b) Use of Trade Secret Information in Anti-Retaliation Lawsuit. An individual who files a lawsuit for retaliation by an employer for reporting a suspected violation of law may disclose the trade secret to the attorney of the individual and use the trade secret information in the court proceeding, if the individual- </p>
            <p className="text is-light">(A) files any document containing the trade secret under seal; and </p>
            <p className="text is-light">(B) does not disclose the trade secret, except pursuant to court order. </p>
            <p className="heading is-light">10. REPRESENTATIONS & WARRANTIES. </p>
            <p className="text is-light"><span className="heading is-light">10.1. From Vendor. </span>Vendor represents and warrants that it is the owner of the System and of each and every component thereof, or the recipient of a valid license thereto, and that it has and will maintain the full power and authority to grant the rights to use the System set forth in this Agreement without the further consent of any third party. Vendor’s representations and warranties in the preceding sentence do not apply to the use of the System in combination with hardware or software not provided by Vendor. In the event of a breach of the warranty in this Section 8.1, Vendor, at its own expense and discretion, shall promptly take the following actions: </p>
            <p className="text is-light">(a) secure for Customer the right to continue using the System; </p>
            <p className="text is-light">(b) replace or modify the System to make it non-infringing; or </p>
            <p className="text is-light">(c) terminate the infringing features of the System and refund to Customer any prepaid fees for such features, in proportion to the portion of the Term left after such termination. In conjunction with Customer’s right to terminate for breach where applicable, the preceding sentence states Vendor’s sole obligation and liability, and Customer’s sole remedy, for breach of the warranty in this Section 8.1 and for potential or actual intellectual property infringement by the System. Vendor’s obligations hereunder for breach of warranty are conditioned upon Customer notifying Vendor of the material breach in writing, and providing Vendor with reasonable, available evidence of such non-conformity to enable Vendor to reproduce or verify the same. </p>
            <p className="text is-light"><span className="heading is-light">10.2. From Customer. </span>Customer represents and warrants that: </p>
            <p className="text is-light">(a) it has the full right and authority to enter into, execute, and perform its obligations under this Agreement and that no pending or threatened claim or litigation known to it would have a material adverse impact on its ability to perform as required by this Agreement; </p>
            <p className="text is-light">(b) it has accurately identified itself and it has not provided any inaccurate information about itself to or through the System; </p>
            <p className="text is-light">(c) it is a corporation, the sole proprietorship of an individual 18 years or older, or another entity authorized to do business pursuant to applicable law; </p>
            <p className="text is-light">(d) will not knowingly use, or allow the use of, the System in contravention of any federal, state, local, foreign, or other applicable law, or rules or regulations of regulatory or administrative organizations; </p>
            <p className="text is-light">(e) introduce into the System and virus or other code or routine intended to disrupt or damage the System, or alter, damage, delete, retrieve, or record information about the System or its users; </p>
            <p className="text is-light">(f) excessively overload the Vendor systems used to provide the System; </p>
            <p className="text is-light">(g) perform any security integrity review, penetration test, load test, denial of service simulation or vulnerability scan specific to the System; </p>
            <p className="text is-light">(h) use any tool in connection with use or access to System that is designed to automatically emulate the actions of a human user; or </p>
            <p className="text is-light">(i) otherwise act in a fraudulent, malicious or, negligent manner when using the System. </p>
            <p className="text is-light"><span className="heading is-light">10.3. Warranty Disclaimers. </span>Except to the extent set forth in the SLA and in Section 8.1 above, CUSTOMER ACCEPTS THE SYSTEM “AS IS” AND AS AVAILABLE, WITH NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE, COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING: </p>
            <p className="text is-light">(a) VENDOR HAS NO OBLIGATION TO INDEMNIFY OR DEFEND CUSTOMER OR USERS AGAINST CLAIMS RELATED TO INFRINGEMENT OF INTELLECTUAL PROPERTY; </p>
            <p className="text is-light">(b) VENDOR DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM WILL PERFORM WITHOUT INTERRUPTION OR ERROR; AND </p>
            <p className="text is-light">(c) VENDOR DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM IS SECURE FROM HACKING OR OTHER UNAUTHORIZED INTRUSION OR THAT CUSTOMER DATA WILL REMAIN PRIVATE OR SECURE. </p>
            <p className="text is-light"><span className="heading is-light">11. INDEMNIFICATION. </span>Customer shall defend, indemnify, and hold harmless Vendor and the Vendor Associates (as defined below) against any “Indemnified Claim,” meaning any third party claim, action, proceeding, liability, loss, damage, cost, expense, suit, or proceeding, including, without limitation, attorneys’ fees, experts’ fees, and court costs arising out of or related to Customer’s alleged or actual use of, misuse of, or failure to use the System, including without limitation: </p>
            <p className="text is-light">(a) claims by Users or by Customer’s employees, as well as by Customer’s own customers; </p>
            <p className="text is-light">(b) claims related to unauthorized disclosure or exposure of personally identifiable information or other private information, including Customer Data; </p>
            <p className="text is-light">(c) claims related to infringement or violation of a copyright, trademark, trade secret, or privacy or confidentiality right by written material, images, logos or other content uploaded to the System through Customer’s account, including without limitation by Customer Data; and </p>
            <p className="text is-light">(d) claims that use of the System through Customer’s account harasses, defames, or defrauds a third party or violates the CAN-Spam Act of 2003 or any other law or restriction on electronic advertising. Indemnified Claims include, without limitation, claims arising out of or related to Vendor’s negligence. Customer’s obligations set forth in this Article 9 include retention and payment of attorneys and payment of court costs, as well as settlement at Customer’s expense and payment of judgments. Vendor will have the right not to be exercised unreasonably, to reject any settlement or compromise that requires that it admit wrongdoing or liability or subjects it to any ongoing affirmative obligations. (The “Vendor Associates” are Vendor’s officers, directors, shareholders, parents, subsidiaries, agents, successors, independent contractors, and assigns). </p>
            <p className="heading is-light">12. LIMITATION OF LIABILITY. </p>
            <p className="text is-light"><span className="heading is-light">12.1. Dollar Cap. </span>VENDOR’S CUMULATIVE LIABILITY FOR ALL CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (NEGLIGENCE), OR OTHERWISE, WILL NOT EXCEED THE SUBSCRIPTION FEE PAID OR PAYABLE TO VENDOR PURSUANT TO THE APPLICABLE ORDER AND TERM IN WHICH THE EVENT GIVING RISE TO THE CLAIM OCCURRED. </p>
            <p className="text is-light"><span className="heading is-light">12.2. Excluded Damages. </span>Except with regard to breaches of Article 7 (Confidential Information), IN NO EVENT WILL VENDOR BE LIABLE TO CUSTOMER OR TO ANY THIRD PARTY FOR ANY LOSS OF USE, PROFITS, REVENUE OR LOSS OF BUSINESS OR FOR ANY CONSEQUENTIAL, EXEMPLARY, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT.</p>
            <p className="text is-light"><span className="heading is-light">12.3. Clarifications & Disclaimers. </span>THE LIABILITIES LIMITED BY THIS ARTICLE 10 APPLY: </p>
            <p className="text is-light">(a) TO LIABILITY FOR NEGLIGENCE; </p>
            <p className="text is-light">(b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE; </p>
            <p className="text is-light">(c) EVEN IF VENDOR IS ADVISED IN ADVANCE OF THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES WERE FORESEEABLE; AND </p>
            <p className="text is-light">(d) EVEN IF CUSTOMER’S REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. If applicable law limits the application of the provisions of this Article 10, Vendor’s liability will be limited to the maximum extent permissible. For the avoidance of doubt, Vendor’s liability limits and other rights set forth in this Article 10 apply likewise to Vendor’s affiliates, licensors, suppliers, advertisers, agents, sponsors, directors, officers, employees, consultants, and other representatives. </p>
            <p className="heading is-light">13. TERM & TERMINATION. </p>
            <p className="text is-light"><span className="heading is-light">13.1. Term. </span>The term of this Agreement (the “Term”) will commence on the Effective Date and continue for the period set forth in the Order or, if none, for 12 MONTHS. Thereafter, the Term will renew for successive 12 MONTH periods, unless either party refuses such renewal by written notice 30 or more days before the renewal date. </p>
            <p className="text is-light"><span className="heading is-light">13.2. Termination for Cause. </span>Either party may terminate this Agreement for the other’s material breach by written notice specifying in detail the nature of the breach, effective in 30 days unless the other party first cures such breach, or effective immediately if the breach is not subject to cure. </p>
            <p className="text is-light"><span className="heading is-light">13.3. Effects of Termination. </span>Upon termination of this Agreement, Customer shall cease all use of the System and delete, destroy, or return all copies of the Documentation in its possession or control. The following provisions will survive termination or expiration of this Agreement: </p>
            <p className="text is-light">(a) any obligation of Customer to pay fees incurred before termination; </p>
            <p className="text is-light">(b) Articles and Sections 6 (IP & Feedback), 7 (Confidential Information), 8.3 (Warranty Disclaimers), 9 (Indemnification), and 10 (Limitation of Liability); and </p>
            <p className="text is-light">(c) any other provision of this Agreement that must survive to fulfill its essential purpose. </p>
            <p className="heading is-light">14. MISCELLANEOUS. </p>
            <p className="text is-light"><span className="heading is-light">14.1. Independent Contractors. </span>The parties are independent contractors and shall so represent themselves in all regards. Neither party is the agent of the other, and neither party may make commitments on the other’s behalf. </p>
            <p className="text is-light"><span className="heading is-light">14.2. Notices. </span>Vendor may send notices pursuant to this Agreement to Customer’s email contact points provided by Customer, and such notices will be deemed received 24 hours after they are sent. Customer may send notices pursuant to this Agreement to platformsupport@echoanalyticsgroup.com (Please put “Attention – Torch Project Management Subscription”), and such notices will be deemed received 72 hours after they are sent. In addition, Customer is on notice and agrees that: </p>
            <p className="text is-light">(a) for claims of copyright infringement, the complaining party may contact (See Section 7.2); and </p>
            <p className="text is-light">(b) Vendor will terminate the accounts of subscribers who are repeat copyright infringers. </p>
            <p className="text is-light"><span className="heading is-light">14.3. Force Majeure. </span>No delay, failure, or default, other than a failure to pay fees when due, will constitute a breach of this Agreement to the extent caused by acts of war, terrorism, hurricanes, earthquakes, other acts of God or of nature, national emergency, epidemic, strikes or other labor disputes, riots or other acts of civil disorder, embargoes, or other causes beyond the performing party’s reasonable control. </p>
            <p className="text is-light"><span className="heading is-light">14.4. Assignment & Successors. </span>Customer may not assign this Agreement or any of its rights or obligations hereunder without Vendor’s express written consent. Except to the extent forbidden in this Section 12.4, this Agreement will be binding upon and inure to the benefit of the parties’ respective successors and assigns. </p>
            <p className="text is-light"><span className="heading is-light">14.5. Severability. </span>To the extent permitted by applicable law, the parties hereby waive any provision of law that would render any clause of this Agreement invalid or otherwise unenforceable in any respect. In the event that a provision of this Agreement is held to be invalid or otherwise unenforceable, such provision will be interpreted to fulfill its intended purpose to the maximum extent permitted by applicable law, and the remaining provisions of this Agreement will continue in full force and effect. </p>
            <p className="text is-light"><span className="heading is-light">14.6. No Waiver. </span>Neither party will be deemed to have waived any of its rights under this Agreement by lapse of time or by any statement or representation other than by an authorized representative in an explicit written waiver. No waiver of a breach of this Agreement will constitute a waiver of any other breach of this Agreement. </p>
            <p className="text is-light"><span className="heading is-light">14.7. Choice of Law & Jurisdiction. </span>This Agreement and all claims arising out of or related to this Agreement will be governed solely by the internal laws of the State of Florida, including without limitation applicable federal law, without reference to: </p>
            <p className="text is-light">(a) any conflicts of law principle that would apply the substantive laws of another jurisdiction to the parties’ rights or duties; </p>
            <p className="text is-light">(b) the 1980 United Nations Convention on Contracts for the International Sale of Goods; or </p>
            <p className="text is-light">(c) other international laws. The parties consent to the personal and exclusive jurisdiction of the federal and state courts of Hillsborough County, Florida. Section 12.7 governs all claims arising out of or related to this Agreement, including without limitation tort claims. </p>
            <p className="text is-light"><span className="heading is-light">14.8. Conflicts. </span>In the event of any conflict between this Agreement and any Vendor policy posted online, including without limitation the AUP or Privacy Policy, the terms of this Agreement will govern. </p>
            <p className="text is-light"><span className="heading is-light">14.9. Construction. </span>The parties agree that the terms of this Agreement result from negotiations between them. This Agreement will not be construed in favor of or against either party by reason of authorship. </p>
            <p className="text is-light"><span className="heading is-light">14.10. Technology Export. </span>Customer shall not: </p>
            <p className="text is-light">(a) permit any third party to access or use the System in violation of any U.S. law or regulation; or </p>
            <p className="text is-light">(b) export any software provided by Vendor or otherwise remove it from the United States except in compliance with all applicable U.S. laws and regulations. Without limiting the generality of the foregoing, Customer shall not permit any third party to access or use the System in, or export such software to, a country subject to a United States embargo (as of the Effective Date, Cuba, Iran, North Korea, Sudan, and Syria). </p>
            <p className="text is-light"><span className="heading is-light">14.11. Entire Agreement. </span>This Agreement sets forth the entire agreement of the parties and supersedes all prior or contemporaneous writings, negotiations, and discussions with respect to its subject matter. Neither party has relied upon any such prior or contemporaneous communications. </p>
            <p className="text is-light"><span className="heading is-light">14.12. Amendment. </span>Vendor may amend this Agreement from time to time by posting an amended version at its website and sending Customer written notice thereof. Such amendment will be deemed accepted and become effective 30 days after such notice (the “Proposed Amendment Date”) unless Customer first gives Vendor written notice of rejection of the amendment. In the event of such rejection, this Agreement will continue under its original provisions, and the amendment will become effective at the start of Customer’s next Term following the Proposed Amendment Date (unless Customer first terminates this Agreement pursuant to Article 11, Term & Termination). Customer’s continued use of the System following the effective date of an amendment will confirm Customer’s consent thereto. This Agreement may not be amended in any other way except through a written agreement by authorized representatives of each party. Vendor may revise the Privacy Policy and Acceptable Use Policy at any time by posting a new version of either at the website, and such new version will become effective on the date it is posted; provided if such amendment materially reduces Customer’s rights or protections, notice and consent will be subject to the requirements above in this Section 12.12. </p>
            
        </React.Fragment>
    )
}

export default TermsOfServiceContent