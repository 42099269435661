import React, {useState} from 'react'

// Components
import SearchBar from '../Elements/SearchBar'
import Thumbnail from '../Elements/Thumbnail'
import GetOrgId from '../../components/Elements/GetOrgId'

//API
import {userService, chatService} from '../../api'

function UserSearch() {
    const [loading, setLoading] = useState(false)
    const [usersList, setUsersList] = useState([])

    const orgId = GetOrgId()

    function handleSearchChange(e){
        if(e.target.value.length >= 2){
            setLoading(true)
            userService.search(e.target.value, orgId
            )
            .then(response => {
                setUsersList(response)
            })
            .then(()=>{
                setLoading(false)
            })
            .catch(error => {
                console.error("It was not possible to obtain a list of useres", error)
            })
        }
        else{
            setUsersList([])
            setLoading(false)
        }
    }

    function handleClick(user_id, public_name) {
        const body = {
            "name": public_name,
            "type": "direct",
            "app": "torch",
            "user": user_id
        }

        chatService.new_conversation(body, orgId)
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            console.error("It was not possible to create the conversation", error)
        })
    }

    var users = []
    for(const user of usersList){
        users.push(
            <div className="flexer flexer-vcenter simple-profile__sections" onClick={()=>handleClick(user.cognito_id, user.public_name)} key={`user_${user.id}`}>
                <Thumbnail user={user} />
                <p className="heading is-dark line-margin-0 ml-20 cursor">{user.public_name}</p>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="simple-profile__sections left">
                <p className="heading is-dark line-margin-0">User browser</p>
            </div>
            <div className="simple-profile__sections">
                <SearchBar onChange={handleSearchChange} placeholder="Find a user by its name..." />
                {loading ? null : <p className="text is-light left mb-0 mt-20">{users.length} users</p>}
            </div>
            <div className="simple-profile__sections max-screen__bottom scroll mt-0">
                {loading ? "Loading users..." : users}
            </div>
        </React.Fragment>
    )
}

export default UserSearch