import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers';

import { authenticationService } from './'

export const organizationService = {
  update,
  retrieve,
  listByUser,
  create,
}

async function listByUser() {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/`, requestOptions)
    .then(handleResponse)
    .then(organizations => {
      const organizationsObj = {}
      for (const org of organizations) {
        organizationsObj[org.id] = org;
      }
      authenticationService.updateOrgMember(organizationsObj)
      return organizations
    })
}

async function update(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', body }

  return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
    .then(handleResponse)
    .then(org => {
      return org
    })
}

async function retrieve(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/organization/${org_id}/`, requestOptions)
    .then(handleResponse)
    .then(org => {
      return org
    })
}

async function create(body) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body }

  return fetch(`${config.apiUrl}/organization/`, requestOptions)
    .then(handleResponse)
    .then(org => {
      return org
    })
}
