import React, {useState} from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'
import Tooltip from '../Elements/Tooltip'

// API
import { organizationService } from '../../api'

// Assets
import OrganizationLogo from '../../assets/images/globe_international_icon.png'


function CreateOrganizationForm(props) {
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const profilePic = OrganizationLogo

    function handleSubmit(e){
        e.preventDefault()
        setSubmitting(true)

        var form_element = document.getElementById('create-org-form')
        var form_data = new FormData(form_element)

        organizationService.create(form_data) 
        .then((response) => {
            props.onClose(true)
            setSubmitting(false)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
            setSubmitting(false)
        })
    }

    function handleFileLoader() {
        var element = document.getElementById("file-loader")
        element.click()
    }
    
    return(    
        <form id="create-org-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Create an Organization</h1> 
            <div className="flexer flexer-vcenter">
                <img className="edit-org-profile__picture" src={profilePic} alt="profile" />
                <input id="file-loader" name="profile_pic" type="file" className="file-input" />
                <Button type="button" text="Upload photo" class=" orange-btn mx-15 subheading is-white" onClick={handleFileLoader} />
            </div>
            <InputField name="name"  label='Organization Name' type="text" required={true} error={errors.name}/>
            <InputField name="description"  label='Description' type="text" required={true} error={errors.description}/>
            <Tooltip content='example@example.com' direction='bottom' class='full-width'>
                <InputField name="email"  label='Administrator' type="email" required={true} error={errors.email}/>
            </Tooltip>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text="Create" class="orange-btn mt-30 mx-15 subheading is-white" type="submit" disabled={submitting}/>
            </div>
        </form>
    )
}

export default CreateOrganizationForm