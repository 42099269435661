import React from 'react'
import { Button } from 'components/Elements'

const ColumnsSelector = (props) => {
  const { data, setData, handleSubmit, columns } = props

  const handleChange = (e) => {
    if (e.target.checked) {
      setData(prev => ({ ...prev, columnList: [...prev.columnList, e.target.value] }))
    } else {
      setData(prev => ({ ...prev, columnList: prev.columnList.filter((value) => value !== e.target.value) }))
    }
  }

  return (
    <div className={`columns-selector ${props.className}`}>
      <div className='my-20 mx-10' >
        <div className='flexer flexer-spaced mt-10'>
          <p className='title-4 left'>Select columns which display on the Analyst Dashboard</p>
          {props.handleClose && <i className="fa fa-close cursor is-light mt-10" onClick={props.handleClose}></i>}
        </div>

        <form id='columns-selector' onSubmit={(e) => handleSubmit(e)}>
          <div className="">
            {
              columns.map((item, idx) => (
                <div className='left' key={idx}>
                  <label htmlFor={`config-${idx}`}>
                    <input
                      name="config"
                      type="checkbox"
                      id={`config-${idx}`}
                      value={item}
                      onChange={handleChange}
                      defaultChecked={data.includes(item)}
                    />
                    {item}
                  </label>
                </div>
              ))
            }
          </div>
          <Button text="Save changes" class="orange-btn my-10 subheading is-white full-width" type="submit" />
        </form>
      </div>
    </div>
  )
}

export default ColumnsSelector
