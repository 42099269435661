import React, { useState, useEffect } from 'react'

// Assets
import OrganizationLogo from '../../assets/images/globe_international_icon.png'

// Components
import Modal from '../Elements/Modal'
import EditOrganizationProfileForm from './EditOrganizationProfileForm'
import GetOrgId from '../Elements/GetOrgId'

// API
import { projectTypesService } from '../../api'

function SimpleLeft(props) {
  const [isOpenModal, setIsModalOpen] = useState(false)
  const [projectTypes, setProjectTypes] = useState()
  const [update, setUpdate] = useState(1)
  const orgId = GetOrgId()

  var publicName = props.org.name ? props.org.name : 'Organization Name'
  var org_id = props.org.id ? props.org.id : 'organization_id'
  var description = props.org.description ? props.org.description : 'Organization description'
  var profilePic = props.org.profile_pic ? props.org.profile_pic : OrganizationLogo


  useEffect(() => {
    projectTypesService.list(orgId)
      .then((response) => {
        setProjectTypes(response)
      })
      .catch((error) => {
        console.error("Unable to get project types", error)
      })
  }, [orgId, update])


  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal(refresh = false) {
    if (refresh) {
      props.onUpdate()
      setUpdate(update + 1)
    }
    setIsModalOpen(false)
  }


  let project_types = projectTypes && projectTypes.map(type =>
    <tr className='table-rows flexer'>
      <td className="text is-light flexer-10 left side-margin-0">{type.name}</td>
      <td className="text is-light flexer-2 left side-margin-0">{type.code}</td>
    </tr>
  )

  return (
    <React.Fragment>
      <div className="simple-profile__sections center">
        <img className="simple-org__picture" src={profilePic} alt="profile" />
        <div className="simple_profile__upper-sections">
          <p className="subtitle is-dark line-margin-s">{publicName} {props.allow_edit ? <span className="fa fa-pencil cursor is-dark" onClick={openModal} /> : null}</p>
        </div>
        <div className="simple_profile__upper-sections">
          <p className="text is-light line-margin-s">{description}</p>
          <p className="text is-light line-margin-s">{org_id}</p>
        </div>
      </div>
      <div className="simple-profile__sections center">
        <div>
          <p className="text is-light is-bold left line-margin-s">Project Types</p>
          <table className="table" cellSpacing="0">
            <tbody>
              {project_types}
            </tbody>
          </table>
        </div>
      </div>
      <Modal isOpen={isOpenModal} handleClose={closeModal} >
        <EditOrganizationProfileForm onClose={closeModal} profilePic={profilePic} org={props.org} projectTypes={projectTypes} allow_edit={props.allow_edit} />
      </Modal>
    </React.Fragment>
  )
}

export default SimpleLeft