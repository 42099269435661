import React, {useState} from 'react'

// Components
import InputField from '../../Elements/InputField'
import Button from '../../Elements/Button'
import RadioButton from '../../Elements/RadioButton'
import Tooltip from '../../Elements/Tooltip'

// API
import {profileService, userService } from '../../../api'


function EditProfileForm(props) {
    const [errors, setErrors] = useState({})
    const [profileImage, setProfileImage] = useState(null)

    if (!profileImage && props.profilePic){
        setProfileImage(props.profilePic)
    }

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('edit-profile-form')
        var form_data = new FormData(form_element)
        var user_body ={}

        var toDelete = []
        for (const [key, value] of form_data.entries()) {
            if(!value) {
                toDelete.push(key)
            }
            else if (["private", "first_name", "middle_name", "last_name", "alias", "email", "phone_number"].includes(key)) {
                toDelete.push(key)
                user_body[key] = value
            }
        }

        for (const key of toDelete) {
            form_data.delete(key)
        }

        profileService.update(form_data, props.user.cognito_id)
        .then((response) => {   
            if(user_body){
                userService.update(user_body, props.user.cognito_id)
                .then(response => {
                    props.onNewVerification(
                        'phone_number' in user_body,
                        'email' in user_body
                    )
                })
                .then(() => {
                    props.onClose(true)
                })
                .catch(error => {
                    if(Array.isArray(error)){
                        error = {non_field_errors: error[0]}
                    }
                    setErrors({...error})
                })
            }
            else{
                props.onClose(true)
            }
        })
        .catch(error => {
            console.log("Error updating profile", error)
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        })

    }

    function handleFileLoader() {
        var element = document.getElementById("file-loader")
        element.click()
    }

    function handleChangeImage(e){
        e.preventDefault()
        var form_element = document.getElementById('image-profile-form')
        var form_data = new FormData(form_element)

        profileService.update(form_data, props.user.cognito_id)
        .then((response) => {
            console.log(response)
            setProfileImage(response.profile_pic)
        })
        .catch(error => {
            console.log("Error updating profile picture", error)
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        })

    }

    var radioButtons
    if(props.user.private) {
        radioButtons = 
            <React.Fragment>
                <RadioButton label="Public" class="flexer-3" name="private" required={true} value="false" checked={true}/>
                <RadioButton label="Private" class="flexer-3" name="private" required={true} value="true" checked={true}/>
            </React.Fragment>
    }
    else{
        radioButtons = 
            <React.Fragment>
                <RadioButton label="Private" class="flexer-3" name="private" required={true} value="true" checked={true}/>
                <RadioButton label="Public" class="flexer-3" name="private" required={true} value="false" checked={true}/>
            </React.Fragment>
    }
    
    return (
        <React.Fragment>
        <form id="image-profile-form">
            <input id="file-loader" name="profile_pic" type="file" className="file-input" onChange={handleChangeImage}/>
        </form>
        <form id="edit-profile-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Edit Profile</h1>
            <div className="flexer flexer-vcenter">
                <img className="edit-profile__picture" src={profileImage} alt="profile" />
                <Button type="button" text="Upload photo" class="mx-15 orange-btn subheading is-white" onClick={handleFileLoader} />
            </div>
            <div className="flexer flexer-vcenter mt-30">
                <p className="subheading is-dark flexer-3">Type of profile: </p>
                {radioButtons}
            </div>
            <InputField name="first_name" label="First name" type="text"  error={errors.first_name} placeholder={props.user?.first_name}/>
            <InputField name="middle_name" label="Middle name" type="text"  error={errors.middle_name} placeholder={props.user?.middle_name}/>
            <InputField name="last_name" label="Last name" type="text"  error={errors.last_name} placeholder={props.user?.last_name}/>
            <InputField name="alias" label="Alias" type="text"  error={errors.alias} placeholder={props.user?.alias}/>
            <Tooltip content='example@example.com' direction='bottom' class='full-width'>
                <InputField name="email" label="Email" type="email"  error={errors.username || errors.email} placeholder={props.user?.email}/>
            </Tooltip>
            <Tooltip content='Phone number should start with a + and the country code. For example +19041234567' direction='bottom' class='full-width'>
                <InputField name="phone_number" label="Phone" type="tel"  error={errors.phone_number} placeholder={props.user?.phone_number}/>
            </Tooltip>
            <InputField name="occupation" label="Occupation"  error={errors.occupation} placeholder={props.user?.profile?.occupation}/>
            <Tooltip content='http://www.facebook.com/example' direction='bottom' class='full-width'>
                <InputField name="facebook_link" label="Facebook link" type="text" error={errors.facebook_link} placeholder={props.user?.profile?.facebook_link}/>
            </Tooltip>
            <Tooltip content='http://www.instagram.com/example' direction='bottom' class='full-width'>
                <InputField name="instagram_link" label="Instagram link" type="text" error={errors.instagram_link} placeholder={props.user?.profile?.instagram_link}/>
            </Tooltip>
            <Tooltip content='http://www.youtube.com/example' direction='bottom' class='full-width'>
                <InputField name="youtube_link" label="Youtube link" type="text" error={errors.youtube_link} placeholder={props.user?.profile?.youtube_link}/>
            </Tooltip>
            <Tooltip content='http://www.linkedin.com/example' direction='bottom' class='full-width'>
                <InputField name="linkedin_link" label="LinkedIn link" type="text" error={errors.linkedin_link || errors.non_field_errors} placeholder={props.user?.profile?.linkedin_link}/>
            </Tooltip>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit"/>
            </div>
        </form>
        </React.Fragment>
    )
}

export default EditProfileForm