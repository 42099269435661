import React, { useState, useEffect, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Multiselect } from 'multiselect-react-dropdown'


// API
import { projectService, projectTypesService, memberService } from '../../api'

// Components
import GetOrgId from '../../components/Elements/GetOrgId'
import SearchBar from '../../components/Elements/SearchBar'


function DashboardContent() {

  let { project_type } = useParams()

  const [projectsList, setProjects] = useState([])
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)

  const [loading, setLoading] = useState(false)
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [showResultAnalyst, setShowResultAnalyst] = useState(false)
  const [showResultProject, setShowResultProject] = useState(false)
  const [refresh, setRefresh] = useState(1)

  const [projectTypes, setProjectTypes] = useState(null)
  const [projectSearch, setProjectSearch] = useState([])
  const [membersSearch, setMembersSearch] = useState([])

  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedProjects, setSelectedProjects] = useState({})
  const [selectedAnalysts, setSelectedAnalysts] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [isFilterActive, setIsFilterActive] = useState(false)


  const orgId = GetOrgId()
  const currentBody = useRef({})

  const multiselectRef = useRef(null)

  if (multiselectRef.current && !isFilterActive) {
    multiselectRef.current.resetSelectedValues()
  }

  var status = null

  if (project_type === 'available') {
    status = ['available']
  }

  if (project_type === 'discover_or_develop') {
    status = ['discover', 'develop']
  }

  if (project_type === 'awaiting') {
    status = ['awaiting']
  }

  if (project_type === 'monitor') {
    status = ['monitoring']
  }

  if (project_type === 'closed') {
    status = ['close']
  }

  if (project_type === 'unpublished') {
    status = ['unpublished']
  }

  // Load project types
  useEffect(() => {

    projectTypesService.list(orgId)
      .then((response) => {
        setProjectTypes(response)
      })
      .catch((error) => {
        console.error("Unable to get project types", error)
      })

  }, [orgId])

  //Load project
  useEffect(() => {

    const analysts = Object.keys(selectedAnalysts);
    const id_project = Object.keys(selectedProjects);

    let filters = {
      id: id_project.length ? id_project : null,
      analyst: analysts.length ? analysts : null,
      case_type: selectedTypes,
      from_date: startDate,
      to_date: endDate
    }

    if (filters.case_type.length === 0) {
      delete filters['case_type']
    }
    if (filters.from_date === null || filters.from_date === '') {
      delete filters['from_date']
    }

    if (filters.to_date === null || filters.to_date === '') {
      delete filters['to_date']
    }

    filters = { ...filters, type: status }

    currentBody.current = filters

    projectService.list(filters, orgId)
      .then((response) => {
        setProjects(response['results'])
        setNext(response.next)
        setPrev(response.prev)
      })
      .catch(error => {
        console.log("Error loading projects", error)
      })
  }, [selectedProjects, selectedAnalysts, selectedTypes, startDate, endDate, refresh, status, orgId])

  function handlePagination(url) {
    projectService.listByUrl(url, currentBody.current)
      .then((response) => {
        setProjects(response['results'])
        setNext(response.next)
        setPrev(response.previous)
      })
      .catch(error => {
        console.log("Error loading projects", error)
      })
  }

  function handleDelete(project_id) {
    document.getElementById(`project-${project_id}`).remove()

    projectService.remove(orgId, project_id)
      .catch(error => {
        console.log("Error deleting project", error)
      })
  }

  var projects = []
  for (const project_ of projectsList) {
    projects.push(
      <tr className="table-rows">
        <td className="text is-dark left">{project_.case_id}</td>
        <td className="text is-dark left">{project_.proper_start_date}</td>
        <td className="center"><Link to={`/organization/${orgId}/project/${project_.id}`}><i className="fa fa-pencil is-light mx-5 cursor" /></Link></td>
        <td className="center"><i className="fa fa-trash-o is-light mx-5 cursor" onClick={() => { handleDelete(project_.id) }} /></td>
      </tr>
    )
  }

  var type = project_type.replaceAll("_", " ").toUpperCase()

  // filters

  // Multiselect function
  function handleSelect(selected) {

    let type = selected.map(e => e.id)
    setSelectedTypes(type)
    setRefresh(refresh + 1)
    setIsFilterActive(true)

    if (selectedTypes === []) {
      setSelectedTypes(null)
      setRefresh(refresh + 1)
    }

  }

  // By project
  function handleProjectSearch(e) {
    if (e.target.value.length >= 2) {
      projectService.search(e.target.value, orgId)
        .then(response => {
          setProjectSearch(response)
        })
        .then(() => {
          setLoadingProjects(false)
          setShowResultProject(true)
          setIsFilterActive(true)
        })
        .catch(error => {
          console.error(error)
        })
    }
    else {
      setProjectSearch([])
      setLoadingProjects(false)
      setShowResultProject(false)
    }
  }

  function handleClickProject(project_id, project_caseId, subject_name) {
    setSelectedProjects({
      ...selectedProjects,
      [project_id]: [project_caseId, subject_name]
    });
    setRefresh(refresh + 1)
    setShowResultProject(false)

    var show_project = document.getElementById('project')
    show_project.classList.toggle("hidden")
    show_project.classList.toggle("shown")
  }

  function handleDeleteProject(project) {
    let new_project = selectedProjects
    delete new_project[project]
    setSelectedProjects({ ...new_project })
    setRefresh(refresh + 1)
  }



  // Search by Analyst
  function handleAnalystSearch(e) {
    if (e.target.value.length >= 2) {
      setLoading(true)
      memberService.search(e.target.value, orgId)
        .then(response => {
          setMembersSearch(response)
        })
        .then(() => {
          setLoading(false)
          setShowResultAnalyst(true)
          setIsFilterActive(true)
        })
        .catch(error => {
          console.error("Analyst not found", error)
        })
    }
    else {
      setMembersSearch([])
      setLoading(false)
      setShowResultAnalyst(false)
    }
  }

  function handleClick(analystEmail, analystName) {
    setSelectedAnalysts({
      ...selectedAnalysts,
      [analystEmail]: analystName
    });
    setRefresh(refresh + 1)
    setShowResultAnalyst(false)

    var show_analyst = document.getElementById('analyst')
    show_analyst.classList.toggle("hidden")
    show_analyst.classList.toggle("shown")
  }

  function handleDeleteAnalyst(analyst) {
    let new_analysts = selectedAnalysts
    delete new_analysts[analyst]
    setSelectedAnalysts({ ...new_analysts })
    setRefresh(refresh + 1)
  }

  // By date

  function handleChangeStart(e) {
    setStartDate(e.target.value)
    setRefresh(refresh + 1)
    setIsFilterActive(true)
  }

  function handleChangeEnd(e) {
    setEndDate(e.target.value)
    setRefresh(refresh + 1)
    setIsFilterActive(true)
  }

  // reset filters

  function handleReset() {
    setSelectedProjects({})
    setSelectedAnalysts({})
    setSelectedTypes([])
    setStartDate('')
    setEndDate('')
    setRefresh(refresh + 1)
    setIsFilterActive(false)
  }

  var analysts = []
  for (const analyst of membersSearch) {
    analysts.push(
      <div className="flexer flexer-vcenter simple-profile__sections" onClick={() => handleClick(analyst.user.email, analyst.user.public_name)} key={`analyst_${analyst.id}`}>
        <p className="heading is-light line-margin-0 ml-20 cursor">{analyst.user.public_name} <span className='text is-light line-margin-0'>{analyst.user.email} </span> </p>
      </div>
    )
  }

  var selected_analyst = selectedAnalysts && Object.entries(selectedAnalysts).map((e) => (
    <div className='simple-profile__sections'>
      <p className="text is-light is-bold" key={e[0]}> {e[1]}  <i className='is-light fa fa-close cursor' onClick={() => handleDeleteAnalyst(e[0])} /></p>
      <p className='text is-light mr-10 line-margin-0'>{e[0]} </p>
    </div>
  ))

  const options = projectTypes ? projectTypes : ['No options available']


  var projects_ = []
  for (const project_ of projectSearch) {
    projects_.push(
      <div className="flexer flexer-vcenter simple-profile__sections" onClick={() => handleClickProject(project_.id, project_.case_id, project_.subject.display_name)} key={`project_${project_.id}`}>
        <p className="heading is-light line-margin-0 ml-20 cursor">{`${project_.case_id}`} <span className='text is-light line-margin-0'>{project_.subject.display_name} </span></p>
      </div>
    )
  }

  var selected_project = selectedProjects && Object.entries(selectedProjects).map((e) => (
    <div className='simple-profile__sections'>
      <p className="text is-light is-bold" key={e[0]}> {e[1][0]} <i className='is-light fa fa-close cursor' onClick={() => handleDeleteProject(e[0])} /></p>
      <p className='text is-light mr-10 line-margin-0'>{e[1][1]} </p>

    </div>
  ))

  var show_reset = false
  if (Object.keys(selectedAnalysts).length || Object.keys(selectedProjects).length || selectedTypes.length !== 0 || startDate || endDate) {
    show_reset = true
  }


  const filters = <React.Fragment>
    <div className='flexer flexer-spaced'>
      <p className='title-3 is-light ml-20 mt-30'>Filters</p>
      {show_reset ?
        <div className='cursor' onClick={(e) => handleReset(e)}><p className="text is-light mt-30 mr-20"> Reset Filters<i className='is-light fa fa-trash cursor mx-10' /></p></div>
        : null}
    </div>
    <div className='simple-profile__sections'>
      <p className='text is-light left'>Project Types</p>
      <div className='filters-multi'>
        <Multiselect
          options={options}
          displayValue="name"
          showCheckbox={true}
          avoidHighlightFirstOption={true}
          placeholder="Select projects types"
          hidePlaceholder={true}
          name="selected_types"
          onSelect={handleSelect}
          onRemove={handleSelect}
          ref={multiselectRef}
          isFilterActive={isFilterActive}
        />
      </div>
    </div>
    <div className='simple-profile__sections'>
      <p className='text is-light left'>By Analyst</p>
      <SearchBar onChange={handleAnalystSearch} placeholder='Enter a name...' />
      {showResultAnalyst ?
        <div className="max-screen__bottom shown" id='analyst' >
          {loading ? "Loading analysts..." : analysts}
        </div>
        : null}
      {selected_analyst.length ?
        <div className="simple-profile__sections my-10">
          <p className='text is-light is-bold left'>Analysts selected</p>
          {selected_analyst}
        </div>
        : null}
      <p className='text  is-light left'>By Project</p>
      <SearchBar onChange={handleProjectSearch} placeholder='Enter a Project ID...' />
      {showResultProject ?
        <div className="max-screen__bottom" id='project'>
          {loadingProjects ? "Loading projects..." : projects_}
        </div>
        : null}
      {selected_project.length ?
        <div className="simple-profile__sections">
          <p className='text is-light is-bold left'>Projects selected</p>
          {selected_project}
        </div>
        : null}
    </div>
    <div className='simple-profile__sections'>
      <p className='text  is-light left'>By Date</p>
      <div className='input-field'>
        <label className="input-field__label" >From:</label>
        <input className="input-field__input" value={startDate} type='date' name='start' label='From' onChange={(e) => handleChangeStart(e)} />
      </div>
      <div className='input-field'>
        <label className="input-field__label mt-10" >To:</label>
        <input className="input-field__input" value={endDate} type='date' name='end' label='To' onChange={(e) => handleChangeEnd(e)} />
      </div>
    </div>
  </React.Fragment>

  return (
    <React.Fragment>
      <div className='flexer max-screen__bottom  flexer-movile'>
        <div className="flexer-2 project-sides ">
          {filters}
        </div>
        <div className='flexer-10'>
          <div className="dashboard-section">
            <h1 className="title-3 is-dark">{type}</h1>
            <table className="table" cellSpacing="0">
              <thead>
                <tr className="table-header">
                  <th className="text is-light left">Project ID</th>
                  <th className="text is-light left" style={{ width: "70%" }}>Date</th>
                  <th className="text is-light" style={{ width: "5%" }}></th>
                  <th className="text is-light" style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {projects}
              </tbody>
            </table>
            <div className="flexer center">
              {prev ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(prev)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
              <p className="subheading is-light mx-10"></p>
              {next ? <p className="subheading is-dark button orange-btn cursor" onClick={() => handlePagination(next)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DashboardContent