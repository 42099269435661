import React, { useState } from 'react'

// Components
import Button from '../Elements/Button'
import SearchBar from '../Elements/SearchBar'
import Thumbnail from '../Elements/Thumbnail'
import Modal from '../Elements/Modal'

// API
import { userService } from '../../api'

function CreateSuperadmin() {

    const [loading, setLoading] = useState(false)
    const [usersList, setUsersList] = useState([])
    const [superAdmin, setSuperAdmin] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    function handleSearchChange(e){
        if(e.target.value.length >= 2){
            setLoading(true)
            userService.search_super(e.target.value)
            .then(response => {
                setUsersList(response)
            })
            .then(()=>{
                setLoading(false)
            })
            .catch(error => {
                console.error("It was not possible to obtain a list of users", error)
            })
        }
        else{
            setUsersList([])
            setLoading(false)
        }
    }

    function handleAccept(user_id) {
        const body = {
            role: "SUPER_ADMIN"
        }
        const superAdmin_id = user_id

        userService.update(body, superAdmin_id)
        .then(response => {
            setIsOpen(false)
        })
        .catch(error => {
            console.error("It was not possible to create a superadmin", error)
        })
    }

    function handleClick(userId, userName) {
        setSuperAdmin({id: userId, name: userName})
        setIsOpen(true)
    }

    var users = []
    for(const user of usersList){
        users.push(
            <div className="flexer flexer-vcenter simple-profile__sections" onClick={()=>handleClick(user.cognito_id, user.public_name)} key={`user_${user.id}`}>
                <Thumbnail user={user} />
                <p className="heading is-dark line-margin-0 ml-20 cursor">{user.public_name}</p>
            </div>
        )
    }

    return(    
		<div className="dashboard-user-section">
            <h1 className="title is-dark mb-30">Create a Superadmin</h1> 
            <React.Fragment>
                <div className="simple-profile__sections left">
                    <p className="heading is-dark line-margin-0">User browser</p>
                </div>
                <div className="simple-profile__sections">
                    <SearchBar onChange={handleSearchChange} placeholder="Find a user by its name..." />
                    {loading ? null : <p className="text is-light left mb-0 mt-20">{users.length} users</p>}
                </div>
                <div className="simple-profile__sections max-screen__bottom scroll mt-0">
                    {loading ? "Loading users..." : users}
                </div>
            </React.Fragment>
            <Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
			<div className="center">
				<h1 className="title is-dark mb-30">Confirm Superadmin</h1>
				<p className="text is-light mx-30">Are you sure that you want to give Superadmin role to <br></br> <span className="is-dark is-bold">{superAdmin.name}</span>?</p>
				<Button text="Confirm" class="mt-30 one-third-width orange-btn subheading-light" type="button" onClick={()=> handleAccept(superAdmin.id)} />
			</div>
		</Modal>
		</div>

    )
}

export default CreateSuperadmin