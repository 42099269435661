import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'

// Assets
import TorchLogo from '../../assets/images/torch-logo.png'
import TorchLogoWhite from '../../assets/images/torch-logo-white.png'

// Components
import ProfileMenu from '../Header/ProfileMenu'
import MenuTabItem from '../Header/MenuTabItem'
import ThemeToggle from '../Elements/ThemeToggle'
import CidTorchMenu from '../Elements/CidTorchMenu'
import { toggleService } from '../Elements/ThemeToggle'

// API
import { authenticationService } from '../../api'
import config from '../../api/config'

// Hooks
import { UserContext } from '../../hooks/UserContext'

function GeneralHeader() {
  const [Logo, setLogo] = useState('')
  const [theme, setTheme] = useState(toggleService.currentModeValue)

  const closedCode = useRef(0)
  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { currentUser, currentOrganizations } = useContext(UserContext)
  const orgMemberInfo = currentOrganizations

  useEffect(() => {
    if (theme === "light") {
      setLogo(TorchLogo)
    } else {
      setLogo(TorchLogoWhite)
    }
  }, [theme])

  var path = window.location.pathname


  /* WEBSOCKET SECTION */
  useEffect(() => {
    if (waitingToReconnect) {
      return
    }

    // Only set up the websocket once
    if (!clientRef.current) {
      if (closedCode.current === 4000) {
        // Refresh token
        //authenticationService.refreshToken()
        closedCode.current = 0
      }

      const client = new WebSocket(`${config.WebSocketURL}`)
      clientRef.current = client

      client.onerror = (e) => console.error(e)

      client.onopen = () => {
        setIsOpen(true)
        console.log('ws opened')
        client.send(JSON.stringify({
          'token': currentUser.IdToken,
          'type': 'header'
        }))
      }

      client.onclose = (e) => {
        closedCode.current = e.code

        if (clientRef.current) {
          // Connection failed
          console.log('ws closed by server', e.code)
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log('ws closed by app component unmount')
          return
        }

        if (waitingToReconnect) {
          return
        }

        // Parse event code and log
        setIsOpen(false)
        console.log('ws closed')

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true)

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(null), 2000)
      }

      return () => {

        console.log('Cleanup');
        // Dereference, so it will set up next time
        clientRef.current = null;

        client.close();
      }
    }
  }, [currentUser.IdToken, waitingToReconnect])

  if (isOpen) {
    clientRef.current.onmessage = e => {
      const data = JSON.parse(e.data);
      console.log("se activo", data)
      if (data.type === "NEW_ORGANIZATION") {
        let newOrgId = data.payload.id
        orgMemberInfo[newOrgId] = data.payload
        authenticationService.updateOrgMember(orgMemberInfo)
      }
      else if (data.type === "DELETED_ORGANIZATION") {
        delete orgMemberInfo[data.payload.id]
        authenticationService.updateOrgMember(orgMemberInfo)
      }
    }
  }

  /* END WEBSOCKET SECTION */

  function handleExpandable() {
    var element = document.getElementById('expandable-menu')
    element.classList.toggle('mobile-hidden')
    element.classList.toggle('mobile-shown')
  }


  return (
    <React.Fragment>
      <div className="header">
        <div className="row flexer flexer-vcenter">
         {/*Commented out until both have subscriptions 2/18/2022 TD
          <div className='ml-10'>
            <CidTorchMenu />
          </div>*/}
          <div className="col-3 center-mobile">
            <img src={Logo} className="logo" alt="torch-logo" />
          </div>
          <div className="col-6 relative">
          </div>
          <div className="col-3 flexer flexer-spaced flexer-vcenter">
            <ThemeToggle onChange={setTheme} />
            <div className="hidden mobile-shown center-mobile" onClick={handleExpandable}>
              <p className="mx-10">Menu<i className="fa fa-bars mx-10"></i></p>
            </div>
            <ProfileMenu />
          </div>
        </div>
        <div className="flexer flexer-spaced header-tabs flexer-mobile">
          <div id="expandable-menu" className="wcc-menu flexer flexer-mobile mobile-hidden">
            <Link to={`/organizations`} className="line-margin-0">
              <MenuTabItem text="Organizations" icon="fas fa-grip-horizontal" active={path === `/organizations` ? true : false} />
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GeneralHeader