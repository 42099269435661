import React from 'react'

// Components
import SingleStep from './SingleStep'

function MultiStep(props) {
    return (
        <div className="flexer">
            <SingleStep step="1" text="Additional information" current={props.current} last={false} />
            <SingleStep step="2" text="Customer" current={props.current} last={false} />
            <SingleStep step="3" text="Priority of project" current={props.current} last={true} />
        </div>
    )
}

export default MultiStep