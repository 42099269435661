import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Api
import { activityService } from '../../api'

// Components
import RecentActivityDetail from './RecentActivityDetail'
import GetOrgId from '../Elements/GetOrgId'

function RecentActivitySection() {
  const { project_id } = useParams()
  const orgId = GetOrgId()
  const [recentActivity, setRecentActivity] = useState([])

  useEffect(() => {
    activityService.retrieve(project_id, orgId)
      .then(response => {
        setRecentActivity(response.results)
      })
      .catch(error => {
        console.log("Error loading project", error)
      })
  }, [orgId, project_id])


  function ToggleProfileMenu(id, length) {
    if (length) {
      var arrow = document.getElementById(`recent-activity-icon-${id}`)
      arrow.classList.toggle("fa-angle-down")
      arrow.classList.toggle("fa-angle-up")

      var element = document.getElementById(`recent-activity-detail-${id}`)
      element.classList.toggle("shown")
      element.classList.toggle("hidden")
    }
  }


  if (recentActivity) {
    var activities = recentActivity.map((activity, idx) => (
      <div className="simple-profile__sections">
        <div className="flexer">
          <i className="fas fa-retweet is-light mr-20" />
          <div className="full-width">
            <p className="heading is-dark line-margin-0">{activity.activity}</p>
            <div className="flexer flexer-spaced cursor" onClick={() => { ToggleProfileMenu(idx, activity.changes.length) }}>
              <p className="text is-light line-margin-xs">{activity.timestamp_proper} <span className="text is-orange line-margin-xs"> by {activity.user.public_name}</span></p>
              {activity.changes.length > 0 ? <i id={`recent-activity-icon-${idx}`} className="fas fa-angle-down right text-icon cursor"></i> : null}
            </div>
            <div className="hidden" id={`recent-activity-detail-${idx}`}>
              <RecentActivityDetail details={activity.changes} />
            </div>

          </div>
        </div>
      </div>
    ))
  }


  return (
    <React.Fragment>
      {activities}
    </React.Fragment>
  )
}

export default RecentActivitySection

