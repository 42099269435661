import React from 'react'

function MenuTabItem(props) {
    var ClassName = "menu-tab cursor"
    if (props.active) {
        ClassName ="menu-tab menu-tab__active cursor"
    }

    var projectClass = props.project ? "menu-tab__project" : ""

    if (props.icon) {
        return (
            <div className={`${ClassName} ${projectClass} flexer flexer-vcenter`} onClick={props.onClick}>
                <p className="subheading is-light"><i className={`${props.icon} text-icon`} />{props.text}</p>
                {props.alert > 0 ? <p className="chats__unread is-white line-margin-0 ml-10">{props.alert}</p> : null}
            </div>
        )
    }

    return (
        <div className={`${ClassName} ${projectClass}`} onClick={props.onClick}>
            <p className="subheading is-light">{props.text}</p>
        </div>
    )
}

export default MenuTabItem