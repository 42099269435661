import React from 'react'

// API
import {linkService} from '../../../api'

// Components
import Dropdown from '../../Elements/Dropdown'
import Button from '../../Elements/Button'
import GetOrgId from '../../Elements/GetOrgId'

function EditLinkForm(props) {

    const orgId = GetOrgId()

    function handleEditLink(e) {
        e.preventDefault()

        var form_element = document.getElementById('edit-link-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = {content:value}
                // body[key] = value
            }
        })

        // linkService.update(form_data, orgId, props.linkId)
        linkService.update(body, orgId, props.linkId)
        .then(response => {
            props.onComplete()
            props.onClose()
        })
        .catch(error => {
            console.log(error)
        })
    }

    return(
        <React.Fragment>
            <h1 className="title is-dark mb-30">Edit relationship</h1>
            <form id="edit-link-form" onSubmit={handleEditLink}>
                <Dropdown label="Relationship" name="relationship">
                    <option value="" disabled>Select an option</option>
                    <option value="mother">Mother</option>
                    <option value="father">Father</option>
                    <option value="relative">Relative</option>
                    <option value="family-friend">Family Friend</option>
                    <option value="associate">Associate</option>
                    <option value="person-of-interest">Person of Interest</option>
                </Dropdown>
                <div className="right center-mobile">
                    <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose}/>
                    <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit"/>
                </div>
            </form>
        </React.Fragment>
    )
}

export default EditLinkForm