import React from 'react'
import { Link } from 'react-router-dom'

// Components
import Thumbnail from '../Elements/Thumbnail'
import GetOrgId from '../Elements/GetOrgId'

// Utilities
import parse from 'html-react-parser'

function AssociatedMessagesSection(props) {
  const orgId = GetOrgId()
  var messages = []

  if (props.project.mentions) {
    for (const mention of props.project.mentions) {
      messages.push(
        <div className="content-wrapper mx-30">
          <div className="simple-profile__sections">
            <div className="flexer left">
              <Thumbnail user={mention.created_by} />
              <div className="ml-20 remaining-width">
                <div className="flexer flexer-spaced">
                  <p className="heading is-dark line-margin-0">{mention.created_by.public_name}</p>
                  <p className="text is-light line-margin-0">{mention.date}</p>
                </div>
                <p className="text is-light line-margin-xs">{parse(mention.message)}</p>
                {mention.thread ? <Link className="text is-orange cursor line-margin-0" to={{ pathname: `/organization/${orgId}/chats`, state: { id: mention.id, channel: mention.channel } }}>View thread</Link> : null}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      {messages}
    </React.Fragment>
  )
}

export default AssociatedMessagesSection