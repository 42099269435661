import React from 'react'

// Components
import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'
import GetOrgId from '../../Elements/GetOrgId'

// API
import { projectService } from '../../../api'

function EditLastKnownForm(props) {
    const orgId = GetOrgId()
    
    function handleSubmit(e) {
        e.preventDefault()
        
        var form_element = document.getElementById('last-known-form')
        var form_data = new FormData(form_element)
        console.log(form_data)

        projectService.update(form_data, orgId, props.projectID)
        .then(response => {
            console.log(response)
            props.onComplete()
            props.onClose()
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
        <form id="last-known-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Edit Last known</h1>
            <Textarea name="last_known" label="Last known" placeholder={props.placeholder} />
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button" onClick={props.onClose}/>
                <Button text="Save changes" class="orange-btn mt-30 subheading is-white" type="submit" />
            </div>
        </form>
    )
}

export default EditLastKnownForm