import React, { useEffect, useState, useContext } from 'react'

//API
import { organizationService } from '../../api'

// Components
import MenuTabItem from '../../components/Header/MenuTabItem'
import SimpleLeft from '../../components/Organization/SimpleLeft'
import OrganizationMembers from '../../components/Organization/OrganizationMembers'
import PendingVerification from '../../components/Organization/PendingVerification'
import PendingInvitation from '../../components/Organization/PendingInvitation'
import GetOrgId from '../../components/Elements/GetOrgId'

// Hooks
import { UserContext } from '../../hooks/UserContext'


function OrganizationProfileContent() {

  const { currentOrganizations } = useContext(UserContext) // global context

  const orgId = GetOrgId()
  const orgMemberInfo = currentOrganizations
  const currentRole = orgMemberInfo[orgId].role
  const [org, setOrg] = useState({})
  const [section, setSection] = useState("activeMembers")
  const [update_data, setUpdate] = useState(0)

  let allow_edit
  (currentRole === 'ADMIN') ? allow_edit = true : allow_edit = false

  function updateData() {
    setUpdate(update_data + 1)
  }

  useEffect(() => {
    organizationService.retrieve(orgId)
      .then(org => {
        setOrg(org)
      })
      .catch(error => {
        console.log("Error loading organization data", error)
      })
  }, [orgId, update_data])

  let membersSection
  section === 'activeMembers'
    ?
    (membersSection = <React.Fragment><OrganizationMembers memberStatus={'active'} onUpdate={updateData} /></React.Fragment>)
    :
    section === 'pendingVerification'
      ?
      (membersSection = <React.Fragment><PendingVerification /></React.Fragment>)
      :
      (membersSection = <React.Fragment><PendingInvitation onUpdate={updateData} /></React.Fragment>)


  return (
    <React.Fragment>
      <div className="flexer flexer-mobile">
        <div className="flexer-3 center">
          <div className="simple-profile">
            <SimpleLeft org={org} onUpdate={updateData} allow_edit={allow_edit} />
          </div>
        </div>
        <div className="flexer-9 center">
          <div className="advanced-profile flexer center-mobile">
            <MenuTabItem text="Active Members" active={section === "activeMembers"} onClick={() => { setSection("activeMembers") }} />
            <MenuTabItem text="Pending Verification" active={section === "pendingVerification"} onClick={() => { setSection("pendingVerification") }} />
            <MenuTabItem text="Pending Invitations" active={section === "pendingInvitation"} onClick={() => { setSection("pendingInvitation") }} />
          </div>
          {membersSection}
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrganizationProfileContent
