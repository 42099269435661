import React, { useState, useEffect } from 'react'

// Components
import Button from './Button'
import StarRatingComponent from 'react-star-rating-component'

// API
import { confidenceService } from '../../api'

function AddScore(props) {
  const { orgId, dataId, confidenceLevel, onClose} = props

    const [score, setScore] = useState(0) 
    const [scoring, setScoring] = useState(0) 
    const [confidence, setConfidence] = useState('') 
    const [color, setColor] = useState(null)
    const [iconClass, setIconClass] = useState(null)

    useEffect(() => {
      if (confidenceLevel && confidenceLevel === 'Unknown') {
        setIconClass('co-unknown')
      }
      if (confidenceLevel && confidenceLevel === 'Low Confidence') {
        setIconClass('co-low')
      }
      if (confidenceLevel && confidenceLevel === 'Medium Confidence') {
        setIconClass('co-medium')
      }
      if (confidenceLevel && confidenceLevel === 'High Confidence') {
        setIconClass('co-high')
      }
      if (confidenceLevel && confidenceLevel === 'Verified') {
        setIconClass('co-verified')
      }
    }, [confidenceLevel])

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('score-form')
        var form_data = new FormData(form_element)

        confidenceService.update(form_data, orgId, dataId)
        .then((response) => {
          onClose()
        })
        .catch(error => {
          console.log(error)
        })
    }

    function handleChange(new_scoring){
        setScore(new_scoring)
        if (new_scoring === 1) {
          setScoring('Unknown')
        }
        if (new_scoring === 2) {
          setScoring('Low')
        }
        if (new_scoring === 3) {
          setScoring('Medium')
        }
        if (new_scoring === 4) {
          setScoring('High')
        }
        if (new_scoring === 5) {
          setScoring('Verified')
        }
    }

    function handleHover(new_scoring){
      if (new_scoring === 1) {
        setConfidence('Unknown')
        setColor('#FF8D57')
        setIconClass('co-unknown')
      }
      if (new_scoring === 2) {
        setConfidence('Low Confidence')
        setColor('#FFB33A')
        setIconClass('co-low')

      }
      if (new_scoring === 3) {
        setConfidence('Medium Confidence')
        setColor('#FFD93B')
        setIconClass('co-medium')

      }
      if (new_scoring === 4) {
        setConfidence('High Confidence')
        setColor('#ADD884')
        setIconClass('co-high')

      }
      if (new_scoring === 5) {
        setConfidence('Verified')
        setColor('#76CAA0')
        setIconClass('co-verified')

      }
    }


    return (
        <form id="score-form" onSubmit={handleSubmit}>
          <div className="mx-20 my-20">
            <div>
              <p className="text is-light is-bold mx-10">CHOOSE YOUR SCORE</p>
              <StarRatingComponent
                // name="add-score"
                className={iconClass}
                editing={true}
                value={score}
                onStarClick={handleChange}
                onStarHover={handleHover}
                starColor={color}
                emptyStarColor='#DFDFDF'
                renderStarIcon={() => <i className={`fa fa-tachometer`} />} 
                />
              <input type="hidden" name="confidence" value={scoring}/>
              <p className="text is-light line-margin-s mx-10">{confidence ? confidence : confidenceLevel }</p>
            </div>
            <Button text="Add Score" class="orange-btn mt-30 subheading is-white line-margin-s" type="submit"/>
          </div>
        </form>
    )
}

export default AddScore
