import React from 'react'

// Components
import Textarea from '../../Elements/Textarea'
import Button from '../../Elements/Button'


function AdditionalInformationForm(props) {

    function handleClick(){
        var additional_content = document.getElementsByName('additional_content')[0].value
        props.onUpdate({additional_content})
        props.onNext()
    }

    return (
        <div className="half-width center">
            <div className="left mt-60">
                <div className="flexer">
                    <i className="form-icon far fa-list-alt"></i>
                    <Textarea name="additional_content" class="full-width line-margin-xs" label="Additional information"/>
                </div>
                <div className="right center-mobile">
                    <Button text="Next step &rarr;" class="mt-15 orange-btn subheading is-light" onClick={handleClick} />
                </div>
            </div>
        </div>
    )
}

export default AdditionalInformationForm