import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from 'hooks/UserContext'
import { Hyperlink, GetOrgId } from 'components/Elements'
import { projectService } from 'api'
import 'assets/styles/dashboard.scss'


const ProjectTable = (props) => {
  const { projects, tableView, columnsList, defaultColums } = props
  const orgId = GetOrgId()
  const { currentOrganizations } = useContext(UserContext)  // global context
  const orgMemberInfo = currentOrganizations
  const currentRole = orgMemberInfo[orgId].role


  const handleDelete = (project_id) => {
    document.getElementById(`project-${project_id}`).remove()

    projectService.remove(orgId, project_id)
      .catch(error => {
        console.log("Error deleting project", error)
      })
  }

  const showID = !columnsList.includes(defaultColums[0]) ? 'hidden' : ''
  const showName = !columnsList.includes(defaultColums[1]) ? 'hidden' : ''
  const showDate = !columnsList.includes(defaultColums[2]) ? 'hidden' : ''
  const showAnalyst = !columnsList.includes(defaultColums[3]) ? 'hidden' : ''

  return (
    <div className={tableView ? 'wcc-projects wcc-table' : 'wcc-projects wcc-cards'}>
      <div>
        <h1 className="title-3 is-dark">{props.title}</h1>
        {!props.custom && <Hyperlink text="View All" className="inline" link={`/organization/${orgId}/projects/${props.to}`} />}
      </div>

      <table className="table" cellSpacing="0">
        <thead>
          <tr className="table-header">
            {defaultColums.map((column, idx) => {
              const shown = !columnsList.includes(defaultColums[idx]) ? 'hidden' : ''
              return (<th key={column} className={`text is-light left ${shown}`}>{column}</th>)
            })}
            <th></th>
          </tr>
        </thead>

        <tbody>
          {
            projects.map((project) => {
              const { id, case_id, proper_start_date, subject: { display_name }, priority } = project;
              const isAdmin = (currentRole === "CASE_MANAGER" || currentRole === "ADMIN");

              return (
                <tr className="table-rows" id={`project-${id}`} key={id}>
                  <td className={`text is-dark left wcc-pid ${showID}`}>
                    {case_id}
                  </td>

                  <td className={`text is-dark left wcc-pname ${showName}`}>
                    {display_name}
                  </td>

                  <td className={`text is-dark left wcc-pdate ${showDate}`}>
                    {proper_start_date}
                  </td>

                  <td className={`text is-dark left wcc-panalyst ${showAnalyst}`}>
                    {/* Joahn Doe */}
                  </td>

                  <td className="wcc-pfooter" width="60">
                    {!tableView && (<div className="wcc-priority"> {priority} </div>)}

                    <div className="wcc-picons">
                      <Link to={`/organization/${orgId}/project/${id}`}>
                        <i className="fa fa-pencil mx-5 cursor is-light" />
                      </Link>
                      {isAdmin && <i className="fa fa-trash-o mx-5 cursor is-light" onClick={() => { handleDelete(id) }} />}
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default ProjectTable
