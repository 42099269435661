/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const subjectService = {
  list,
  retrieve,
  create,
  update,
  updateImage,
  updateAlias,
  updatePhone,
  createAlias,
  createPhone,
}


async function list(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/subjects/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function retrieve(org_id, subject_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/subjects/${subject_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function create(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/subjects/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function update(body, org_id, subject_id) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/subjects/${subject_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}


async function updateImage(body, org_id, subject_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', body }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/subjects/${subject_id}/profile_pic/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function updateAlias(body, org_id, alias_id) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/aliases/${alias_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function updatePhone(body, org_id, phone_id) {
  body = fixBody(body)
  const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/phones/${phone_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function createAlias(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/aliases/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function createPhone(body, org_id) {
  body = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

  return fetch(`${config.apiUrl}/torch/organization/${org_id}/phones/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
