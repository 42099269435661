/* eslint-disable max-len */
import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const chatService = {
  send_message,
  send_thread_message,
  send_broadcast_message,
  list_broadcasts,
  delete_broadcast,
  new_conversation,
  mark_messages_as_read,
  mark_broadcast_messages_as_read,
  list_channels,
  list_messages,
  list_thread_messages,
  get_message,
  get_channel,
  get_group_channels,
  message_pagination,
}

async function send_message(body, org_id) {

  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', body }
  return fetch(`${config.apiUrl}/chat/organization/${org_id}/chat-messages/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function send_thread_message(body, org_id) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', body }
  return fetch(`${config.apiUrl}/chat/organization/${org_id}/thread-messages/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function send_broadcast_message(body, org_id) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', body }
  return fetch(`${config.apiUrl}/chat/organization/${org_id}/broadcast-messages/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list_broadcasts(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/broadcast-messages/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function delete_broadcast(message_id, org_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/broadcast-messages/${message_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function new_conversation(body, org_id) {
  body = fixBody(body)

  const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
  return fetch(`${config.apiUrl}/chat/organization/${org_id}/chat-channels/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function mark_messages_as_read(user_chat_id, org_id) {
  const requestOptions = { method: 'POST', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/user-chat/${user_chat_id}/mark_as_read/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function mark_broadcast_messages_as_read(user_chat_id, org_id) {
  const requestOptions = { method: 'POST', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/user-chat/${user_chat_id}/mark_as_read_broadcasts/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list_channels(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/user-chat/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list_messages(channel_id, org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/chat-messages/?channel=${channel_id}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function list_thread_messages(parent_message, org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/thread-messages/?parent_message=${parent_message}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function get_message(message_id, org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/chat-messages/${message_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function get_channel(channel_id, org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/chat-channels/${channel_id}/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function get_group_channels(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${config.apiUrl}/chat/organization/${org_id}/chat-channels/`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}

async function message_pagination(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
}
