import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const projectTypesService = {
  list,
  listByUrl,
  create,
  remove,
}

async function list(org_id) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function listByUrl(url) {
  const requestOptions = { method: 'GET', credentials: 'include' }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function create(org_id, body) {
  const fixedBody = fixBody(body)
  const requestOptions = { method: 'POST', credentials: 'include', fixedBody }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })
}

async function remove(org_id, type_id) {
  const requestOptions = { method: 'DELETE', credentials: 'include' }
  return fetch(`${config.apiUrl}/torch/organization/${org_id}/project_type/${type_id}/`, requestOptions)
    .then(handleResponse)
    .then(type => {
      return type
    })

}
