import React, { useState, useContext } from 'react'

// Components
import InputField from '../Elements/InputField'
import Button from '../Elements/Button'
import Dropdown from '../Elements/Dropdown'
import GetOrgId from '../Elements/GetOrgId'

// API
import  { invitationService } from '../../api'

// Hooks
import { UserContext } from '../../hooks/UserContext'

function InvitationForm (props) {
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const { currentOrganizations } = useContext(UserContext)  // global context


	const orgId = GetOrgId()
    const orgMemberInfo = currentOrganizations
    const currentRole = orgMemberInfo[orgId].role

	function handleSubmit(e){
        e.preventDefault()
        setSubmitting(true)

        var form_element = document.getElementById('signup-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })
        invitationService.create(body, orgId)
        .then((response) => {
            props.onClose(true)
            setSubmitting(false)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
            setSubmitting(false)
        })
    }

	return (
        <form id="signup-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Send Invitation</h1> 
            <InputField name="email"  label='Email' type="email" required={true} error={errors.email}/>
            <Dropdown label="Role" class="line-margin-xs" name="role">
                    <option value="" disabled>Select an option</option>
                    <option value="ANALYST">Analyst</option>
                    <option value="CASE_MANAGER">Project Manager</option>
                    {currentRole === 'ADMIN' ? <option value="ADMIN">Admin</option> : null}
            </Dropdown>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" onClick={props.onClose}/>
                <Button text="Send Invitation" class="orange-btn mt-30 subheading is-white" type="submit" disabled={submitting}/>
            </div>
		</form>
	)
}

export default InvitationForm